import Keycloak from 'keycloak-js';

let keycloakInstance = null;

const getKeycloak = () => {
  if (!keycloakInstance) {
    const config = {
      url: process.env.REACT_APP_KEYCLOAK_URL,
      realm: process.env.REACT_APP_REALM,
      clientId: process.env.REACT_APP_CLIENT_ID,
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      enableLogging: true,
      pkceMethod: 'S256',
      checkLoginIframe: false,
      flow: 'standard'
    };
    
    keycloakInstance = new Keycloak(config);
    
    keycloakInstance.init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
    }).then(authenticated => {
      console.log('Keycloak initialized, authenticated:', authenticated);
    }).catch(error => {
      console.error('Keycloak initialization error:', error);
    });
  }
  return keycloakInstance;
};

export default getKeycloak();