import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, Card, CardContent, Button, Grid, 
  Dialog, DialogTitle, DialogContent, DialogActions,
  List, ListItem, ListItemText, Chip, Divider, ListItemButton,
  TextField, IconButton, CircularProgress
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import StorageIcon from '@mui/icons-material/Storage';
import PublicIcon from '@mui/icons-material/Public';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { getUserInventory } from '../api/services/userService/Inventory';
import { useAuth } from '../authentication/AuthContext';
import { deployModel, modelQueries } from '../api/services/vcai/Vcai';


const availableModels = [
  { id: 1, name: "meta-llama/Llama-3.2-3B-Instruct", size: "3B parameters", type: "Instruction-tuned LLM" },
  { id: 2, name: "meta-llama/Llama-3.1-8B", size: "8B parameters", type: "Instruction-tuned LLM" },
  { id: 3, name: "nvidia/Llama3-ChatQA-2-8B", size: "8B parameters", type: "Chat QA Model" },
  { id: 4, name: "NousResearch/Llama-2-7b-chat-hf", size: "7B parameters", type: "Chat Model" },
  { id: 5, name: "codellama/CodeLlama-7b-hf", size: "7B parameters", type: "Code Generation Model" },
  { id: 6, name: "codellama/CodeLlama-7b-Python-hf", size: "7B parameters", type: "Python Code Generation Model" },
  { id: 7, name: "mistralai/Mistral-7B-Instruct-v0.3", size: "7B parameters", type: "Instruction-tuned LLM" },
  { id: 8, name: "mistralai/Mixtral-8x7B-Instruct-v0.1", size: "8x7B parameters", type: "Instruction-tuned LLM" },
  { id: 9, name: "FlagAlpha/Llama2-Chinese-13b-Chat", size: "13B parameters", type: "Chinese Chat Model" },
  { id: 10, name: "inceptionai/jais-13b", size: "13B parameters", type: "General Purpose Model" }
];

const ServerCard = ({ server, onDeploy, deployedModel, onChat }) => {
  const isOnline = server.status === "active";
  
  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">{server.hostname}</Typography>
          <Chip 
            label={isOnline ? "Online" : "Offline"}
            color={isOnline ? "success" : "error"}
            size="small"
          />
        </Box>
        
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            <StorageIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
            {server.gpuType} {server.gpu}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            <PublicIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
            Location: {server.location}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            <LocationOnIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
            IP: {server.ipAddress}:{server.port}
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {deployedModel ? (
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              Deployed Model: <Chip label={deployedModel.name} size="small" color="primary" />
            </Typography>
            <Button 
              variant="contained" 
              fullWidth 
              sx={{ mt: 1 }}
              onClick={() => onChat(server, deployedModel)}
              disabled={!isOnline}
            >
              Chat
            </Button>
          </Box>
        ) : (
          <Button 
            variant="outlined" 
            fullWidth
            onClick={() => onDeploy(server)}
            disabled={!isOnline}
          >
            Deploy Model
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

const ChatInterface = ({ model, server, onClose, disabled }) => {
  const [message, setMessage] = useState('');
  const [chat, setChat] = useState([]);
  const [apiKey, setApiKey] = useState('');
  const [showApiKey, setShowApiKey] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { keycloak } = useAuth();

  const handleSend = async () => {
    if (!message.trim() || disabled || isLoading) return;
    
    const userMessage = message;
    setMessage('');
    setChat(prev => [...prev, { role: 'user', content: userMessage }]);
    
    try {
      setIsLoading(true);
      await keycloak.updateToken(30);

      const response = await modelQueries({
        question: userMessage,
        port: String(server.port),
        ipAddress: server.ipAddress,
        hostname: server.hostname,
        token: keycloak.token
      });

      if (response.success) {
        setChat(prev => [...prev, { 
          role: 'assistant', 
          content: response.answer
        }]);
      } else {
        setChat(prev => [...prev, { 
          role: 'assistant', 
          content: 'Sorry, I encountered an error processing your request.'
        }]);
        console.error('Query failed:', response.error);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setChat(prev => [...prev, { 
        role: 'assistant', 
        content: 'Sorry, an error occurred while processing your message.'
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box 
      sx={{ 
        mt: 3, 
        p: 2, 
        bgcolor: 'background.paper', 
        borderRadius: 1,
        opacity: disabled ? 0.7 : 1,
        position: 'relative'
      }}
    >
      {disabled && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
          }}
        >
          <Typography variant="h6" color="text.secondary">
            Deploy a model to start chatting
          </Typography>
        </Box>
      )}
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'center' }}>
        <Typography variant="h6">
          Chat with {model ? model.name : 'AI Model'}
        </Typography>
        {model && (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button 
              size="small" 
              variant="outlined"
              onClick={() => setShowApiKey(!showApiKey)}
            >
              {showApiKey ? 'Hide API Key' : 'Show API Key'}
            </Button>
          </Box>
        )}
      </Box>

      {showApiKey && model && (
        <Box sx={{ mb: 2, p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
          <Typography variant="subtitle2" gutterBottom>API Key</Typography>
          <TextField
            fullWidth
            size="small"
            value={apiKey || 'sk-' + Math.random().toString(36).substr(2, 20)}
            InputProps={{
              readOnly: true,
              sx: { fontFamily: 'monospace' }
            }}
          />
        </Box>
      )}
      
      <Box 
        sx={{ 
          height: '300px', 
          overflow: 'auto', 
          mb: 2,
          filter: disabled ? 'blur(2px)' : 'none',
          pointerEvents: disabled ? 'none' : 'auto'
        }}
      >
        {chat.map((msg, i) => (
          <Box 
            key={i}
            sx={{ 
              mb: 1,
              textAlign: msg.role === 'user' ? 'right' : 'left',
              '& > div': {
                display: 'inline-block',
                maxWidth: '70%',
                p: 1,
                borderRadius: 1,
                bgcolor: msg.role === 'user' ? 'primary.main' : 'background.default'
              }
            }}
          >
            <div>
              <Typography variant="body2">{msg.content}</Typography>
            </div>
          </Box>
        ))}
      </Box>

      <Box sx={{ display: 'flex', gap: 1 }}>
        <TextField
          fullWidth
          size="small"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder={disabled ? "Deploy a model first" : "Type your message..."}
          disabled={disabled || isLoading}
          onKeyPress={(e) => e.key === 'Enter' && handleSend()}
        />
        <IconButton 
          onClick={handleSend} 
          color="primary"
          disabled={disabled || isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
        </IconButton>
      </Box>
    </Box>
  );
};


const Deployment = () => {
  const [servers, setServers] = useState([]);
  const [deployDialog, setDeployDialog] = useState(false);
  const [selectedServer, setSelectedServer] = useState(null);
  const [deployments, setDeployments] = useState({});
  const [activeChat, setActiveChat] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deploymentLoading, setDeploymentLoading] = useState({});
  const { keycloak } = useAuth();

  useEffect(() => {
    const fetchServers = async () => {
      try {
        await keycloak.updateToken(30);
        setLoading(true);
        const response = await getUserInventory(keycloak.subject, keycloak.token);
        console.log('Server fetch response:', response);
        
        if (response.success) {
          const transformedServers = response.data.map(server => ({
            id: server.id,
            hostname: server.hostname,
            status: server.status,
            type: server.type,
            ipAddress: server.ipAddress,
            port: server.port,
            location: server.location,
            gpuType: server.gpuType.toUpperCase(),
            gpu: server.gpu,
            numGpus: server.numGpus
          }));
          
          setServers(transformedServers);
        } else {
          console.error('Failed to fetch servers:', response.error);
        }
      } catch (error) {
        console.error('Error fetching servers:', error);
        if (error.message?.includes('token')) {
          await keycloak.updateToken(30);
        }
      } finally {
        setLoading(false);
      }
    };

    if (keycloak.token) {
      fetchServers();
    }
  }, [keycloak.token, keycloak.subject]);

  const handleDeploy = (server) => {
    setSelectedServer(server);
    setDeployDialog(true);
  };

  const handleModelSelect = async (model) => {
    try {
      await keycloak.updateToken(30);
      setDeploymentLoading(prev => ({ ...prev, [selectedServer.id]: true }));
      
      // Prepare deployment request based on updated API structure
      console.log('Selected server:', selectedServer);
      console.log('Selected model:', model);
      
      const deploymentRequest = {
        ip: selectedServer.ipAddress,
        port: String(selectedServer.port),
        host: selectedServer.hostname,
        isSlurmNode: false,
        isSingleNode: true,
        dockerId: "",
        repository: "huggingface", 
        name: model.name,
        tag: "latest",
        isAMD: selectedServer.gpuType.toUpperCase() === "AMD",
        isNVIDIA: selectedServer.gpuType.toUpperCase() === "NVIDIA",
        gpuName: selectedServer.gpu.toUpperCase(),
        gpuIndices: ["0"],
        model: model.name,
        token: keycloak.token
      };

      console.log('Deployment request:', deploymentRequest);

      const response = await deployModel(deploymentRequest);
      console.log('Deployment response:', response);
      if (response.success) {
        setDeployments({ ...deployments, [selectedServer.id]: model });
        setDeployDialog(false);
      } else {
        console.error('Deployment failed:', response.error);
      }
    } catch (error) {
      console.error('Error deploying model:', error);
    } finally {
      setDeploymentLoading(prev => ({ ...prev, [selectedServer.id]: false }));
    }
  };

  const handleChat = (server, model) => {
    setActiveChat({ server, model });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>Deployment</Typography>
      
      <Grid container spacing={3}>
        {servers.map((server) => (
          <Grid item xs={12} sm={6} md={4} key={server.id}>
            <ServerCard
              server={server}
              onDeploy={handleDeploy}
              deployedModel={deployments[server.id]}
              onChat={handleChat}
            />
          </Grid>
        ))}
      </Grid>

      <ChatInterface
        model={activeChat?.model}
        server={activeChat?.server}
        onClose={() => setActiveChat(null)}
        disabled={!activeChat}
      />

      <Dialog open={deployDialog} onClose={() => setDeployDialog(false)}>
        <DialogTitle>Select Model to Deploy</DialogTitle>
        <DialogContent>
          <List>
            {availableModels.map((model) => (
              <ListItem disablePadding key={model.id}>
                <ListItemButton 
                  onClick={() => handleModelSelect(model)}
                  disabled={deploymentLoading[selectedServer?.id]}
                >
                  <ListItemText 
                    primary={model.name}
                    secondary={`${model.type} • ${model.size}`}
                  />
                  {deploymentLoading[selectedServer?.id] && (
                    <CircularProgress size={20} sx={{ ml: 1 }} />
                  )}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeployDialog(false)}
            disabled={deploymentLoading[selectedServer?.id]}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Deployment;
