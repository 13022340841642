import React, { createContext, useState, useContext, useEffect } from 'react';
import keycloak from './KeycloakConfig';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const updateUserProfile = async () => {
      if (keycloak.authenticated) {
        try {
          const userProfile = await keycloak.loadUserProfile();
          setUser({
            id: userProfile.id,
            name: `${userProfile.firstName} ${userProfile.lastName}`,
            email: userProfile.email,
            roles: keycloak.realmAccess?.roles || []
          });
        } catch (error) {
          console.error('Failed to load user profile:', error);
        }
      } else {
        setUser(null);
      }
    };

    keycloak.onAuthSuccess = updateUserProfile;
    keycloak.onAuthRefreshSuccess = updateUserProfile;
    updateUserProfile();

    return () => {
      keycloak.onAuthSuccess = null;
      keycloak.onAuthRefreshSuccess = null;
    };
  }, []);

  const checkSecureContext = () => {
    if (window.isSecureContext) {
      return true;
    }
    console.warn('Application is not running in a secure context');
    return false;
  };

  const login = async () => {
    if (!checkSecureContext()) {
      console.error('Cannot perform login in an insecure context');
      return;
    }
    
    try {
      await keycloak.login({
        redirectUri: `${window.location.origin}/home`
      });
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const logout = () => {
    keycloak.logout({
      redirectUri: window.location.origin + '/'
    }).catch(error => {
      console.error('Logout failed:', error);
    });
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, keycloak }}>
      {children}
    </AuthContext.Provider>
  );
};