import React, { lazy, Suspense, useState } from 'react';
import { Typography, Grid, Card, CardHeader, CardContent, Avatar, Button, Box, CircularProgress } from '@mui/material';
import { menuItems, appHighlightItems } from '../MenuItems';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Terminal from '@mui/icons-material/Terminal';
import { useNavigate } from 'react-router-dom';

// First, add a keyframes animation for the lightning effect at the top of the file
const lightningKeyframes = `
@keyframes lightning {
  0% {
    filter: brightness(1);
    transform: scale(1);
  }
  25% {
    filter: brightness(1.5) contrast(1.5);
    transform: scale(1.1);
  }
  50% {
    filter: brightness(1.2);
    transform: scale(1);
  }
  75% {
    filter: brightness(1.4);
    transform: scale(1.05);
  }
  100% {
    filter: brightness(1);
    transform: scale(1);
  }
}`;

// Add the keyframes to the document
const style = document.createElement('style');
style.textContent = lightningKeyframes;
document.head.appendChild(style);

function getDescription(label) {
  switch (label) {
    case "Dashboard":
      return {
        main: "Monitor your AI platform activities and resource usage",
        sub: "Real-time metrics, usage statistics, and system health monitoring"
      };
    case "Models":
      return {
        main: "Manage and explore your AI models",
        sub: "Import, version control, and organize your ML models efficiently"
      };
    case "Training":
      return {
        main: "Monitor and control your model training jobs",
        sub: "Track metrics, manage resources, and optimize training pipelines"
      };
    case "Deployment":
      return {
        main: "Manage and monitor your deployed models and servers",
        sub: "Scale deployments, monitor performance, and manage endpoints"
      };
    case "Marketplace":
      return {
        main: "Discover and install AI-powered applications",
        sub: "Browse verified solutions and integrate them seamlessly"
      };
    case "Server Management":
      return {
        main: "Manage your AI infrastructure efficiently",
        sub: "Monitor server health, resource allocation, and optimize costs"
      };
    case "Cloud Management":
      return {
        main: "Manage your cloud resources and infrastructure",
        sub: "Optimize cloud costs, ensure security, and scale resources dynamically"
      };
    case "Test":
      return {
        main: "Test",
        sub: "Test"
      };
    default:
      return { main: "", sub: "" };
  }
}

export default function Home() {
  const navigate = useNavigate();
  const [showNoCodeAI, setShowNoCodeAI] = useState(false);

  const handleNoCodeAIClick = () => {
    setShowNoCodeAI(true);
  };

 
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Welcome to Piosphere
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Manage your AI models, training, deployment, and explore the marketplace all in one place.
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1}}>
        {menuItems.slice(1).map((item) => (
          <Grid item xs={12} md={6} lg={4} key={item.label}>
            <Card sx={{ 
              height: '100%', 
              display: 'flex', 
              flexDirection: 'column',
              minHeight: '220px',
              transition: 'box-shadow 0.3s ease-in-out, transform 0.2s ease-in-out',
              '&:hover': {
                boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                transform: 'translateY(-4px)'
              }
            }}>
              <CardHeader
                avatar={
                  <Avatar 
                    sx={{ 
                      bgcolor: 'primary.main',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        animation: 'lightning 0.5s ease-in-out',
                        bgcolor: 'primary.light',
                        '& .MuiSvgIcon-root': {
                          color: 'primary.main',
                          transform: 'scale(1.2)',
                        }
                      },
                      '& .MuiSvgIcon-root': {
                        transition: 'all 0.3s ease',
                      }
                    }}
                  >
                    <item.icon />
                  </Avatar>
                }
                title={item.label}
              />
              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box>
                  <Typography variant="body1" color="text.primary" sx={{ mb: 1 }}>
                    {getDescription(item.label).main}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    {getDescription(item.label).sub}
                  </Typography>
                </Box>
                <Button
                  variant="outlined"
                  endIcon={<ChevronRightIcon />}
                  fullWidth
                  onClick={() => navigate(item.path)}
                >
                  Go to {item.label}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
        {appHighlightItems.map((item, index) => (
          item.type === 'section' ? (
            <Grid item xs={12} key={index}>
              <Box sx={{ 
                position: 'relative', 
                my: 3,
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: '50%',
                  left: 0,
                  right: 0,
                  height: '1px',
                  background: 'linear-gradient(90deg, rgba(80,200,120,0) 0%, rgba(80,200,120,0.5) 50%, rgba(80,200,120,0) 100%)'
                }
              }}>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    position: 'relative',
                    bgcolor: 'background.default',
                    color: 'primary.main',
                    px: 2,
                    mx: 'auto',
                    width: 'fit-content'
                  }}
                >
                  {item.label}
                </Typography>
              </Box>
            </Grid>
          ) : (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                minHeight: '220px',
                transition: 'box-shadow 0.3s ease-in-out, transform 0.2s ease-in-out',
                '&:hover': {
                  boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                  transform: 'translateY(-4px)'
                },
                ...(item.label === "No-Code AI" && {
                  borderColor: 'primary.main',
                  borderWidth: 2,
                  borderStyle: 'solid'
                })
              }}>
                <CardHeader
                  avatar={
                    <Avatar 
                      sx={{ 
                        bgcolor: 'primary.main',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          animation: 'lightning 0.5s ease-in-out',
                          bgcolor: 'primary.light',
                          '& .MuiSvgIcon-root': {
                            color: 'primary.main',
                            transform: 'scale(1.2)',
                          }
                        },
                        '& .MuiSvgIcon-root': {
                          transition: 'all 0.3s ease',
                        }
                      }}
                    >
                      <item.icon />
                    </Avatar>
                  }
                  title={item.label}
                />
                <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <Box>
                    <Typography variant="body1" color="text.primary" sx={{ mb: 1 }}>
                      {getAppHighlightDescription(item.label).main}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                      {getAppHighlightDescription(item.label).sub}
                    </Typography>
                  </Box>
                  <Button
                    variant="outlined"
                    endIcon={<ChevronRightIcon />}
                    fullWidth
                    onClick={() => navigate(item.path)}
                  >
                    {`Explore ${item.label}`}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          )
        ))}
      </Grid>
    </Box>
  );
}

function getAppHighlightDescription(label) {
  switch (label) {
    case "No-Code AI":
      return {
        main: "Build AI applications without coding",
        sub: "Create LLM agents and chatbots with just a few clicks. Perfect for rapid prototyping."
      };
    case "IaaS":
      return {
        main: "Infrastructure as a Service",
        sub: "Scalable cloud infrastructure optimized for AI workloads and enterprise needs."
      };
    case "PaaS":
      return {
        main: "Enterprise-Grade AI Platform",
        sub: "Complete infrastructure with automatic scaling and monitoring."
      };
    case "PioChat":
      return {
        main: "Your AI assistant for platform guidance and general help",
        sub: "Get platform-specific support and general assistance through natural conversation"
      };
    default:
      return { main: "", sub: "" };
  }
}
