import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const testApi = async (request, token) => {
  console.log(`Attempting to call testApi with request: ${request} and token: ${token}`);
  console.log(BASE_URL);
  try {
    const response = await axios.get(`${BASE_URL}/vcai/v1/test-api`, {
      params: { request },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    });
    console.log(`testApi call successful: ${response.data.response}`);
    return {
      success: true,
      response: response.data.response
    };
  } catch (error) {
    console.error(`Error calling testApi: ${error}`);
    return handleError(error);
  }
};

export const deployModel = async ({
  ip,
  port,
  host,
  isSlurmNode,
  isSingleNode,
  dockerId,
  repository,
  name,
  tag,
  isAMD,
  isNVIDIA,
  gpuName,
  gpuIndices,
  model,
  token
}) => {
  try {
    const data = {
      server_address: {
        ip,
        port,
        host
      },
      nodeTypes: {
        is_slurm_node: isSlurmNode,
        is_single_node: isSingleNode
      },
      docker_image: {
        id: '',
        docker_id: dockerId,
        repository,
        name,
        tag
      },
      gpu_info: {
        is_AMD: isAMD,
        is_NVIDIA: isNVIDIA,
        gpu_name: gpuName,
        gpu_indices: gpuIndices
      },
      model
    };

    const response = await axios.post(`${BASE_URL}/vcai/v1/deploy-model`, data, {
      headers: { 'Authorization': 'Bearer ' + token }
    });
    
    return {
      success: true,
      status: response.data.status,
      containerId: response.data.container_id,
      pid: response.data.pid,
      serverAddress: response.data.server_address,
      port: response.data.Port
    };
  } catch (error) {
    return handleError(error);
  }
};

export const launchTraining = async ({
  model,
  inputModel,
  outputModel,
  dataPath,
  epochs,
  accumulationSteps,
  saveSteps,
  learningRate,
  dtype,
  maxSteps,
  groupByLength,
  batchSize,
  optim,
  loggingSteps,
  weightDecay,
  maxGradNorm,
  warmupRatio,
  schedulerType,
  taskType,
  pushToHub,
  repoId,
  instanceId,
  timeStart,
  timeEnd,
  numOfInstances,
  numOfGpusPerInstance,
  gpuInfo,
  cpuInfo,
  duration,
  token
}) => {
  try {
    const data = {
      training_data: {
        model,
        input_model: inputModel,
        output_model: outputModel,
        data_path: dataPath
      },
      training_config: {  
        epochs,
        accumulation_steps: accumulationSteps,
        save_steps: saveSteps,
        learning_rate: learningRate,
        dtype,
        max_steps: maxSteps,
        group_by_length: groupByLength,
        batch_size: batchSize,
        optim,
        logging_steps: loggingSteps,
        weight_decay: weightDecay,
        max_grad_norm: maxGradNorm,
        warmup_ratio: warmupRatio,
        scheduler_type: schedulerType,
        task_type: taskType,
        push_to_hub: pushToHub,
        repo_id: repoId
      },
      instance: {
        instance_id: instanceId,
        time_start: timeStart,
        time_end: timeEnd,
        num_of_instances: numOfInstances,
        num_of_gpus_per_instance: numOfGpusPerInstance,
        gpu_info: gpuInfo,
        cpu_info: cpuInfo
      },
      duration
    };

    const response = await axios.post(`${BASE_URL}/vcai/v1/launch-training`, data, {
      headers: { 'Authorization': 'Bearer ' + token }
    });
    
    return {
      success: true,
      PID: response.data.PID,
      containerId: response.data.container_id,
      status: response.data.status
    };
  } catch (error) {
    return handleError(error);
  }
};

export const checkTraining = async ({
  ip,
  port,
  host,
  dockerCredentials,
  dockerImage,
  dockerContainer,
  dockerStatus,
  dockerArguments,
  instanceId,
  timeStart,
  timeEnd,
  numOfInstances,
  numOfGpusPerInstance,
  gpuInfo,
  cpuInfo,
  jobId,
  userId,
  imageId,
  jobType,
  status,
  command,
  pid,
  createdAt,
  updatedAt,
  token
}) => {
  try {
    const params = {
      server_address: {
        ip,
        port,
        host
      },
      docker: {
        docker_credentials: dockerCredentials,
        docker_image: dockerImage,
        docker_container: dockerContainer,
        docker_status: dockerStatus,
        docker_arguments: dockerArguments
      },
      instance: {
        instance_id: instanceId,
        time_start: timeStart,
        time_end: timeEnd,
        num_of_instances: numOfInstances,
        num_of_gpus_per_instance: numOfGpusPerInstance,
        gpu_info: gpuInfo,
        cpu_info: cpuInfo
      },
      job: {
        id: jobId,
        user_id: userId,
        image_id: imageId,
        job_type: jobType,
        status,
        command,
        pid,
        created_at: createdAt,
        updated_at: updatedAt
      }
    };

    const response = await axios.get(`${BASE_URL}/vcai/v1/check-training`, {
      params,
      headers: { 'Authorization': 'Bearer ' + token }
    });
    
    return {
      success: true,
      status: response.data.status
    };
  } catch (error) {
    return handleError(error);
  }
};

export const launchLatencyBenchmarking = async ({
  model,
  inputLen,
  outputLen,
  batchSize,
  maxModelLength,
  dtype,
  instanceId,
  timeStart,
  timeEnd,
  numOfInstances,
  numOfGpusPerInstance,
  gpuInfo,
  cpuInfo,
  duration,
  token
}) => {
  try {
    const data = {
      benchmarking_data: {
        model,
        input_len: inputLen,
        output_len: outputLen,
        batch_size: batchSize,
        max_model_length: maxModelLength,
        dtype
      },
      instance: {
        instance_id: instanceId,
        time_start: timeStart,
        time_end: timeEnd,
        num_of_instances: numOfInstances,
        num_of_gpus_per_instance: numOfGpusPerInstance,
        gpu_info: gpuInfo,
        cpu_info: cpuInfo
      },
      duration
    };

    const response = await axios.post(`${BASE_URL}/vcai/v1/launch-latency-benchmarking`, data, {
      headers: { 'Authorization': 'Bearer ' + token }
    });
    
    return {
      success: true,
      PID: response.data.PID,
      containerId: response.data.container_id,
      status: response.data.status
    };
  } catch (error) {
    return handleError(error);
  }
};

export const launchThroughputBenchmarking = async ({
  model,
  inputLen,
  outputLen,
  batchSize,
  maxModelLength,
  dtype,
  instanceId,
  timeStart,
  timeEnd,
  numOfInstances,
  numOfGpusPerInstance,
  gpuInfo,
  cpuInfo,
  duration,
  token
}) => {
  try {
    const data = {
      benchmarking_data: {
        model,
        input_len: inputLen,
        output_len: outputLen,
        batch_size: batchSize,
        max_model_length: maxModelLength,
        dtype
      },
      instance: {
        instance_id: instanceId,
        time_start: timeStart,
        time_end: timeEnd,
        num_of_instances: numOfInstances,
        num_of_gpus_per_instance: numOfGpusPerInstance,
        gpu_info: gpuInfo,
        cpu_info: cpuInfo
      },
      duration
    };

    const response = await axios.post(`${BASE_URL}/vcai/v1/launch-throughput-benchmarking`, data, {
      headers: { 'Authorization': 'Bearer ' + token }
    });
    
    return {
      success: true,
      PID: response.data.PID,
      containerId: response.data.container_id,
      status: response.data.status
    };
  } catch (error) {
    return handleError(error);
  }
};

export const launchInferencing = async ({
  ip,
  port,
  host,
  isSlurmNode,
  isSingleNode,
  model,
  inputModel,
  outputModel,
  dataPath,
  epochs,
  accumulationSteps,
  saveSteps,
  learningRate,
  dtype,
  maxSteps,
  groupByLength,
  batchSize,
  optim,
  loggingSteps,
  weightDecay,
  maxGradNorm,
  warmupRatio,
  schedulerType,
  taskType,
  pushToHub,
  repoId,
  instanceId,
  timeStart,
  timeEnd,
  numOfInstances,
  numOfGpusPerInstance,
  gpuInfo,
  cpuInfo,
  duration,
  token
}) => {
  try {
    const data = {
      server_address: {
        ip,
        port,
        host
      },
      node_types: {
        is_slurm_node: isSlurmNode,
        is_single_node: isSingleNode
      },
      training_data: {
        model,
        input_model: inputModel,
        output_model: outputModel,
        data_path: dataPath
      },
      training_config: {
        epochs,
        accumulation_steps: accumulationSteps,
        save_steps: saveSteps,
        learning_rate: learningRate,
        dtype,
        max_steps: maxSteps,
        group_by_length: groupByLength,
        batch_size: batchSize,
        optim,
        logging_steps: loggingSteps,
        weight_decay: weightDecay,
        max_grad_norm: maxGradNorm,
        warmup_ratio: warmupRatio,
        scheduler_type: schedulerType,
        task_type: taskType,
        push_to_hub: pushToHub,
        repo_id: repoId
      },
      instance: {
        instance_id: instanceId,
        time_start: timeStart,
        time_end: timeEnd,
        num_of_instances: numOfInstances,
        num_of_gpus_per_instance: numOfGpusPerInstance,
        gpu_info: gpuInfo,
        cpu_info: cpuInfo
      },
      duration
    };

    const response = await axios.post(`${BASE_URL}/vcai/v1/launch-inferencing`, data, {
      headers: { 'Authorization': 'Bearer ' + token }
    });
    
    return {
      success: true,
      PID: response.data.PID,
      status: response.data.status
    };
  } catch (error) {
    return handleError(error);
  }
};

export const checkInferencing = async ({
  ip,
  port,
  host,
  dockerCredentials,
  dockerImage,
  dockerContainer,
  dockerStatus,
  dockerArguments,
  instanceId,
  timeStart,
  timeEnd,
  numOfInstances,
  numOfGpusPerInstance,
  gpuInfo,
  cpuInfo,
  jobId,
  userId,
  imageId,
  jobType,
  status,
  command,
  pid,
  createdAt,
  updatedAt,
  token
}) => {
  try {
    const params = {
      server_address: {
        ip,
        port,
        host
      },
      docker: {
        docker_credentials: dockerCredentials,
        docker_image: dockerImage,
        docker_container: dockerContainer,
        docker_status: dockerStatus,
        docker_arguments: dockerArguments
      },
      instance: {
        instance_id: instanceId,
        time_start: timeStart,
        time_end: timeEnd,
        num_of_instances: numOfInstances,
        num_of_gpus_per_instance: numOfGpusPerInstance,
        gpu_info: gpuInfo,
        cpu_info: cpuInfo
      },
      job: {
        id: jobId,
        user_id: userId,
        image_id: imageId,
        job_type: jobType,
        status,
        command,
        pid,
        created_at: createdAt,
        updated_at: updatedAt
      }
    };

    const response = await axios.get(`${BASE_URL}/vcai/v1/check-inferencing`, {
      params,
      headers: { 'Authorization': 'Bearer ' + token }
    });
    
    return {
      success: true,
      status: response.data.status
    };
  } catch (error) {
    return handleError(error);
  }
};

export const pioChatBot = async (question, token) => {
  try {
    const data = { question };
    
    const response = await axios.post(`${BASE_URL}/vcai/public/v1/pio-chat-bot`, data);
    
    return {
      success: true,
      status: response.data.status,
      answer: response.data.answer
    };
  } catch (error) {
    return handleError(error);
  }
};

export const modelQueries = async ({
  question,
  port,
  ipAddress,
  hostname,
  token
}) => {
  try {
    const data = {
      question,
      port,
      server_address: {
        ip: ipAddress,
        port,
        host: hostname
      }
    };

    const response = await axios.post(`${BASE_URL}/vcai/v1/model-queries`, data, {
      headers: { 'Authorization': 'Bearer ' + token }
    });
    
    return {
      success: true,
      status: response.data.status,
      answer: response.data.answer
    };
  } catch (error) {
    return handleError(error);
  }
};

const handleError = (error) => {
  console.error('VCAI API Error:', error);
  
  if (error.response?.status === 503) {
    return {
      success: false,
      error: 'Service temporarily unavailable'
    };
  }
  
  return {
    success: false,
    error: error.response?.data?.message || error.message || 'Operation failed'
  };
};