import React, { useState } from 'react';
import { 
  Typography, Grid, Paper, List, ListItem, ListItemText, 
  Chip, Box, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow 
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Mock evaluation history data
const evaluationHistory = [
  {
    id: 1,
    name: "LAMBADA Evaluation Run", 
    date: "2024-03-15",
    model: "meta-llama/Llama-3.2-3B-Instruct",
    dataset: "lambada_openai",
    metrics: [
      { task: 'lambada_openai', version: '1', filter: 'none', nShot: '0', metric: 'acc', value: '0.7993', stderr: '0.0056', better: '↑' },
      { task: 'lambada_openai', version: '1', filter: 'none', nShot: '0', metric: 'perplexity', value: '2.5794', stderr: '0.0423', better: '↓' }
    ]
  },
  {
    id: 2,
    name: "HellaSwag Performance Test",
    date: "2024-03-14", 
    model: "meta-llama/Llama-3.1-8B",
    dataset: "hellaswag",
    metrics: [
      { task: 'hellaswag', version: '1', filter: 'none', nShot: '0', metric: 'acc', value: '0.6523', stderr: '0.0078', better: '↑' },
      { task: 'hellaswag', version: '1', filter: 'none', nShot: '0', metric: 'perplexity', value: '3.1245', stderr: '0.0512', better: '↓' }
    ]
  },
  {
    id: 3,
    name: "Winogrande Evaluation",
    date: "2024-03-13",
    model: "mistralai/Mistral-7B-Instruct-v0.3", 
    dataset: "winogrande",
    metrics: [
      { task: 'winogrande', version: '1', filter: 'none', nShot: '0', metric: 'acc', value: '0.7134', stderr: '0.0092', better: '↑' },
      { task: 'winogrande', version: '1', filter: 'none', nShot: '0', metric: 'perplexity', value: '2.8976', stderr: '0.0467', better: '↓' }
    ]
  }
];

function MetricsTable({ metrics }) {
  const theme = useTheme();
  
  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Task</TableCell>
            <TableCell>Version</TableCell>
            <TableCell>Filter</TableCell>
            <TableCell>n-shot</TableCell>
            <TableCell>Metric</TableCell>
            <TableCell align="right">Value</TableCell>
            <TableCell align="right">Stderr</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {metrics.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.task}</TableCell>
              <TableCell>{row.version}</TableCell>
              <TableCell>{row.filter}</TableCell>
              <TableCell>{row.nShot}</TableCell>
              <TableCell>
                {row.metric} {' '}
                <Typography 
                  component="span" 
                  color={row.better === '↑' ? 'success.main' : 'error.main'}
                >
                  {row.better}
                </Typography>
              </TableCell>
              <TableCell align="right">{row.value}</TableCell>
              <TableCell align="right">±{row.stderr}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function AutoEvaluationHistory() {
  const [selectedEvaluation, setSelectedEvaluation] = useState(null);
  
  return (
    <Grid container spacing={3}>
      {/* Evaluation List */}
      <Grid item xs={4}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>Recent Evaluations</Typography>
          <List>
            {evaluationHistory.map(evaluation => (
              <ListItem 
                button 
                key={evaluation.id}
                selected={selectedEvaluation?.id === evaluation.id}
                onClick={() => setSelectedEvaluation(evaluation)}
              >
                <ListItemText 
                  primary={evaluation.name}
                  secondary={
                    <>
                      <Typography variant="caption" display="block">
                        {evaluation.date}
                      </Typography>
                      <Typography variant="caption" display="block">
                        Model: {evaluation.model.split('/').pop()}
                      </Typography>
                      <Typography variant="caption" display="block">
                        Dataset: {evaluation.dataset}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>

      {/* Evaluation Details */}
      <Grid item xs={8}>
        {selectedEvaluation && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {/* Evaluation Header */}
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6">{selectedEvaluation.name}</Typography>
              <Box sx={{ display: 'flex', gap: 2, mt: 1, flexWrap: 'wrap' }}>
                <Chip 
                  label={`Model: ${selectedEvaluation.model.split('/').pop()}`} 
                  color="primary"
                />
                <Chip 
                  label={`Dataset: ${selectedEvaluation.dataset}`} 
                  variant="outlined" 
                />
                <Chip 
                  label={selectedEvaluation.date} 
                  variant="outlined" 
                />
                <Chip 
                  label={`${selectedEvaluation.metrics.length} metrics`} 
                  variant="outlined" 
                />
              </Box>
            </Paper>

            {/* Metrics Table */}
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>Evaluation Results</Typography>
              <MetricsTable metrics={selectedEvaluation.metrics} />
            </Paper>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

export default AutoEvaluationHistory;
