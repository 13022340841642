import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Avatar, Paper, Grid, Alert } from '@mui/material';
import { useAuth } from '../authentication/AuthContext';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export default function Settings() {
  const { user, updateUser } = useAuth();
  const [profile, setProfile] = useState({
    name: user?.name || '',
    email: user?.email || '',
    profilePicture: user?.profilePicture || '',
    apiKey: '',
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    try {
      // Add your profile update API call here
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      updateUser({
        name: profile.name,
        email: profile.email,
        profilePicture: profile.profilePicture,
        apiKey: profile.apiKey
      });
      
      setSuccess('Settings updated successfully');
    } catch (err) {
      setError('Failed to update settings');
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfile({ ...profile, profilePicture: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', p: 3 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>Settings</Typography>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
        
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box sx={{ textAlign: 'center' }}>
                <Avatar
                  src={profile.profilePicture || 'path/to/generic/profile/image.png'}
                  alt={profile.name}
                  sx={{ width: 100, height: 100, mb: 2, mx: 'auto' }}
                />
              </Box>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Full Name"
                value={profile.name}
                onChange={(e) => setProfile({...profile, name: e.target.value})}
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                type="email"
                value={profile.email}
                onChange={(e) => setProfile({...profile, email: e.target.value})}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="API Key"
                value={profile.apiKey}
                onChange={(e) => setProfile({...profile, apiKey: e.target.value})}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                Change Password
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Please enter your current password and the new password you wish to set.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Current Password
              </Typography>
              <TextField
                fullWidth
                type="password"
                value={profile.currentPassword}
                onChange={(e) => setProfile({...profile, currentPassword: e.target.value})}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                New Password
              </Typography>
              <TextField
                fullWidth
                type="password"
                value={profile.newPassword}
                onChange={(e) => setProfile({...profile, newPassword: e.target.value})}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Confirm New Password
              </Typography>
              <TextField
                fullWidth
                type="password"
                value={profile.confirmNewPassword}
                onChange={(e) => setProfile({...profile, confirmNewPassword: e.target.value})}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                sx={{ mt: 2 }}
              >
                {loading ? 'Saving Changes...' : 'Save Changes'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
}
