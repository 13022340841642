import React, { useState, useCallback } from 'react';
import {
  Box,
  Card,
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Tabs,
  Tab,
  Alert,
  LinearProgress,
  IconButton,
} from '@mui/material';
import {
  LockOpen,
  Speed,
  Settings,
  PlayArrow,
  Stop,
  List,
  Warning,
  CloudUpload,
  Delete,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';

const HashCat = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [hashType, setHashType] = useState('');
  const [attackMode, setAttackMode] = useState('');
  const [inputHash, setInputHash] = useState('');
  const [wordlist, setWordlist] = useState('');
  const [isRunning, setIsRunning] = useState(false);
  const [progress, setProgress] = useState(0);
  const [result, setResult] = useState(null);
  const [selectedWordlists, setSelectedWordlists] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadError, setUploadError] = useState(null);

  const hashTypes = [
    { id: 0, name: 'MD5' },
    { id: 100, name: 'SHA1' },
    { id: 1400, name: 'SHA2-256' },
    { id: 1700, name: 'SHA2-512' },
    // Add more hash types as needed
  ];

  const attackModes = [
    { id: 0, name: 'Straight', description: 'Dictionary attack' },
    { id: 1, name: 'Combination', description: 'Multiple dictionary combination' },
    { id: 3, name: 'Brute-force', description: 'Pure brute force attack' },
    { id: 6, name: 'Hybrid dict + mask', description: 'Dictionary + Mask' },
    { id: 7, name: 'Hybrid mask + dict', description: 'Mask + Dictionary' },
  ];

  const commonWordlists = [
    { id: 'rockyou', name: 'rockyou.txt', size: '139MB' },
    { id: 'passwords', name: 'common-passwords.txt', size: '24MB' },
    { id: 'names', name: 'names.txt', size: '15MB' },
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleFileUpload = (event) => {
    setUploadError(null);
    const files = Array.from(event.target.files);
    
    // Validate files
    const invalidFiles = files.filter(file => {
      const isTooLarge = file.size > 500 * 1024 * 1024; // 500MB
      const isValidType = ['.txt', '.dict', '.lst'].some(ext => 
        file.name.toLowerCase().endsWith(ext)
      );
      return isTooLarge || !isValidType;
    });

    if (invalidFiles.length > 0) {
      setUploadError('Only text files up to 500MB are allowed');
      return;
    }

    // Add new files
    setUploadedFiles(prev => [
      ...prev,
      ...files.map(file => ({
        file,
        name: file.name,
        size: (file.size / (1024 * 1024)).toFixed(2), // Convert to MB
        id: Math.random().toString(36).substr(2, 9)
      }))
    ]);
  };

  const removeFile = (fileId) => {
    setUploadedFiles(prev => prev.filter(file => file.id !== fileId));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsRunning(true);
    setProgress(0);
    setResult(null);

    // Simulated progress
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 1;
      });
    }, 500);

    // Simulate hash cracking
    setTimeout(() => {
      setIsRunning(false);
      setResult({
        found: true,
        password: 'example123',
        timeElapsed: '2m 34s',
      });
    }, 5000);
  };

  const StyledCard = styled(Card)(({ theme }) => ({
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[8],
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'translateY(-2px)',
    },
  }));

  const uploadSection = (
    <Grid item xs={12}>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Upload Custom Wordlists
      </Typography>
      
      <Button
        component="label"
        variant="outlined"
        startIcon={<CloudUpload />}
        sx={{ mb: 2 }}
      >
        Upload Wordlist
        <input
          type="file"
          hidden
          multiple
          accept=".txt,.dict,.lst"
          onChange={handleFileUpload}
        />
      </Button>

      {uploadError && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {uploadError}
        </Alert>
      )}

      {uploadedFiles.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Uploaded Files:
          </Typography>
          {uploadedFiles.map((file) => (
            <Card
              key={file.id}
              sx={{
                mb: 1,
                p: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Box>
                <Typography variant="body2">{file.name}</Typography>
                <Typography variant="caption" color="text.secondary">
                  {file.size} MB
                </Typography>
              </Box>
              <IconButton
                size="small"
                color="error"
                onClick={() => removeFile(file.id)}
                title="Remove file"
              >
                <Delete />
              </IconButton>
            </Card>
          ))}
        </Box>
      )}
    </Grid>
  );

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        HashCat
      </Typography>

      <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 3 }}>
        <Tab icon={<PlayArrow />} label="Basic" />
        <Tab icon={<Settings />} label="Advanced" />
        <Tab icon={<Speed />} label="Benchmarks" />
      </Tabs>

      <StyledCard sx={{ p: 3 }}>
        {activeTab === 0 && (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Hash Type</InputLabel>
                  <Select
                    value={hashType}
                    onChange={(e) => setHashType(e.target.value)}
                    label="Hash Type"
                  >
                    {hashTypes.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Attack Mode</InputLabel>
                  <Select
                    value={attackMode}
                    onChange={(e) => setAttackMode(e.target.value)}
                    label="Attack Mode"
                  >
                    {attackModes.map((mode) => (
                      <MenuItem key={mode.id} value={mode.id}>
                        {mode.name} - {mode.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Input Hash"
                  value={inputHash}
                  onChange={(e) => setInputHash(e.target.value)}
                  placeholder="Enter hash value(s) here..."
                  helperText="One hash per line. Supports multiple formats."
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                  <List sx={{ mr: 1 }} /> Select Wordlist(s)
                </Typography>
                <Grid container spacing={2}>
                  {commonWordlists.map((list) => (
                    <Grid item xs={12} md={4} key={list.id}>
                      <Card
                        sx={{
                          p: 2,
                          cursor: 'pointer',
                          border: selectedWordlists.includes(list.id) 
                            ? '2px solid #1976d2'
                            : 'none',
                        }}
                        onClick={() => {
                          setSelectedWordlists((prev) =>
                            prev.includes(list.id)
                              ? prev.filter((id) => id !== list.id)
                              : [...prev, list.id]
                          );
                        }}
                      >
                        <Typography variant="subtitle2">{list.name}</Typography>
                        <Typography variant="caption" color="text.secondary">
                          Size: {list.size}
                        </Typography>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
                <TextField
                  fullWidth
                  sx={{ mt: 2 }}
                  label="Custom Wordlist Path"
                  value={wordlist}
                  onChange={(e) => setWordlist(e.target.value)}
                  placeholder="/path/to/wordlist.txt"
                />
              </Grid>

              {uploadSection}

              {result && (
                <Grid item xs={12}>
                  <Alert
                    severity={result.found ? "success" : "error"}
                    sx={{ mb: 2 }}
                  >
                    {result.found
                      ? `Password found: ${result.password} (Time: ${result.timeElapsed})`
                      : "Password not found"}
                  </Alert>
                </Grid>
              )}

              <Grid item xs={12}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <LoadingButton
                    loading={isRunning}
                    loadingPosition="start"
                    startIcon={isRunning ? <Stop /> : <PlayArrow />}
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={!hashType || !attackMode || !inputHash}
                  >
                    {isRunning ? 'Running Attack...' : 'Start Attack'}
                  </LoadingButton>
                  {isRunning && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      startIcon={<Stop />}
                      onClick={() => setIsRunning(false)}
                    >
                      Stop
                    </Button>
                  )}
                </Box>
                {isRunning && (
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{ mt: 2 }}
                  />
                )}
              </Grid>
            </Grid>
          </form>
        )}

        {activeTab === 1 && (
          <Typography>Advanced options coming soon...</Typography>
        )}

        {activeTab === 2 && (
          <Typography>Benchmark tools coming soon...</Typography>
        )}
      </StyledCard>
    </Box>
  );
};

export default HashCat;
