import React from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  Avatar,
  Box,
  Link as MuiLink,
  CssBaseline,
} from '@mui/material';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import {
  MessageCircle,
  Clock,
  BarChart2,
  BrainCircuit,
  Rocket,
  ShoppingBag,
  Code,
  FileText,
  Users,
  Server,
  Shield,
  Lock,
  Zap,
} from 'lucide-react';
import { useAuth } from '../authentication/AuthContext';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#50C878', // Green color
    },
    background: {
      default: '#000000',
      paper: '#1C1C1C',
    },
  },
  typography: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    h1: {
      fontSize: '3.5rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          textTransform: 'none',
          fontWeight: 600,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
        },
      },
    },

  },
});

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.black,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const FeatureCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],
  border: `1px solid ${theme.palette.grey[800]}`,
  borderRadius: '20px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const GreenShape = styled('svg')({
  position: 'absolute',
  zIndex: 0,
  opacity: 0.1,
  width: '100%',
  height: '60%',
  objectFit: 'cover',
  pointerEvents: 'none',
});


export default function PiosphereLandingPage() {
  const { login } = useAuth();

  const features = [
    { icon: Zap, title: "No-Code AI Builder", description: "Create sophisticated AI models without writing a single line of code", highlight: true },
    { icon: BrainCircuit, title: "Local Models", description: "Manage and explore your AI models without relying on external APIs" },
    { icon: Code, title: "In-House Training", description: "Train your models on your own hardware, maintaining full control over your data", highlight: true },
    { icon: Rocket, title: "Private Deployment", description: "Deploy models securely within your own datacenter" },
    { icon: Clock, title: "PioScheduler", description: "Maximize GPU utilization in your datacenter with our advanced scheduling system", highlight: true },
    { icon: ShoppingBag, title: "Internal Marketplace", description: "Discover and install AI-powered applications that run entirely on your infrastructure" },
  ];

  const advantages = [
    { icon: Server, title: "Full Control", description: "Run all AI functionalities on your own hardware, maintaining complete control over your infrastructure and data" },
    { icon: Shield, title: "Enhanced Security", description: "Keep sensitive data and AI models within your own network, eliminating reliance on external cloud providers" },
    { icon: Lock, title: "Data Privacy", description: "Ensure compliance with data protection regulations by keeping all processes in-house" },
  ];

  const marketplaceApps = [
    { icon: MessageCircle, title: "Local Piochat", description: "AI-powered chatbot running entirely on your infrastructure" },
    { icon: FileText, title: "In-House Meeting Summarizer", description: "Automatically generate meeting summaries without sending data externally" },
    { icon: Code, title: "Private Code Assistant", description: "AI-powered code completion and suggestions, keeping your code secure" },
  ];

  const testimonials = [
    { name: "Sarah Johnson", company: "TechCorp", avatar: "/placeholder.svg?height=100&width=150", quote: "Piosphere's no-code AI solution has revolutionized our workflow. We're building complex AI tools without any coding expertise." },
    { name: "Michael Chen", company: "AI Innovations", avatar: "/placeholder.svg?height=100&width=150", quote: "The No-Code AI feature running on our own hardware is a game-changer. We've built sophisticated AI tools without external dependencies." },
    { name: "Emily Rodriguez", company: "DataDriven", avatar: "/placeholder.svg?height=100&width=150", quote: "Piosphere's on-premise marketplace and no-code tools have given us access to cutting-edge AI applications that run entirely within our secure environment." },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ 
        width: '100vw',
        margin: 0,
        padding: 0,
        position: 'relative',
        left: '50%',
        right: '50%',
        marginLeft: '-50vw',
        marginRight: '-50vw',
        overflowX: 'hidden'
      }}>
        <Box sx={{ 
          minHeight: '100vh', 
          bgcolor: 'background.default', 
          color: 'text.primary'
        }}>
          <GreenShape viewBox="0 0 100 100" preserveAspectRatio="none">
            <path d="M0,0 C30,40 70,40 100,0 L100,100 L0,100 Z" fill="#50C878" />
          </GreenShape>
          <AppBar position="sticky" color="transparent" elevation={0} sx={{ backdropFilter: 'blur(8px)' }}>
            <Container maxWidth="xl">
              <Toolbar sx={{ justifyContent: 'space-between', px: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src="../../images/pv_white.png" alt="Piosphere Logo" style={{ height: '40px' }} />
                  <Typography variant="h6" component="div" sx={{ ml: 1 }}>
                    Piosphere
                  </Typography>
                </Box>
                <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', flexGrow: 1 }}>
                  <Box sx={{ display: 'flex', gap: 3 }}>
                    <MuiLink href="#features" color="inherit" sx={{ textDecoration: 'none' }}>Features</MuiLink>
                    <MuiLink href="#no-code-ai" color="inherit" sx={{ textDecoration: 'none' }}>No-Code AI</MuiLink>
                    <MuiLink href="#on-premise" color="inherit" sx={{ textDecoration: 'none' }}>On-Premise</MuiLink>
                    <MuiLink href="#deployment" color="inherit" sx={{ textDecoration: 'none' }}>Deployment</MuiLink>
                    <MuiLink href="#scheduler" color="inherit" sx={{ textDecoration: 'none' }}>Scheduler</MuiLink>
                    <MuiLink href="#marketplace" color="inherit" sx={{ textDecoration: 'none' }}>Marketplace</MuiLink>
                  </Box>
                </Box>
                <Box>
                <Button 
                  variant="outlined" 
                  onClick={login}
                  sx={{ 
                    mr: 2, 
                    color: 'common.white',
                    borderColor: 'common.white'
                  }}
                >
                    Log In
                  </Button>
                  <StyledButton 
                    variant="contained"
                    component={Link} 
                    to="/register"
                  >
                    Sign Up
                  </StyledButton>
                </Box>
              </Toolbar>
            </Container>
          </AppBar>

          <Box sx={{ position: 'relative', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', width: '100vw' }}>
            <Container maxWidth="md" sx={{ textAlign: 'center', position: 'relative', zIndex: 1 }}>

              <Typography variant="h1" component="h1" sx={{ mb: 3 }}>
                Your On-Premise <Box component="span" sx={{ color: 'primary.main' }}>No-Code AI</Box> Platform
              </Typography>
              <Typography variant="h5" sx={{ mb: 4 }}>
                Build, manage, and deploy AI models on your own hardware - No coding required
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, justifyContent: 'center' }}>
                <StyledButton 
                  variant="contained" 
                  size="large"
                  href="https://meetings-eu1.hubspot.com/mazda?uuid=36465083-6274-4cc0-a076-e62c90c5450c"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Schedule a demo
                </StyledButton>
              </Box>
            </Container>
          </Box>

          <Box sx={{ position: 'relative', overflow: 'hidden', width: '100vw' }}>
            <Container maxWidth="lg" sx={{ py: 10, position: 'relative', zIndex: 1 }} id="features">
              <Typography variant="h2" align="center" sx={{ mb: 6 }}>
                Key Features
              </Typography>
              <Grid container spacing={4}>
                {features.map((feature, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <FeatureCard sx={feature.highlight ? { border: '1px solid #50C878' } : {}}>
                      <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', p: 3, height: '100%' }}>
                        <feature.icon style={{ width: 48, height: 48, marginBottom: 16, color: '#50C878' }} />
                        <Typography variant="h6" sx={{ mb: 2, color: feature.highlight ? '#50C878' : 'inherit' }}>{feature.title}</Typography>
                        <Typography variant="body2" color="text.secondary">{feature.description}</Typography>
                      </CardContent>
                    </FeatureCard>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>

          
          <Box sx={{ position: 'relative', overflow: 'hidden', width: '100vw' }}>
            <Box sx={{ bgcolor: 'background.paper', py: 10 }} id="no-code-ai">
              <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
                <Grid container spacing={6} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <Typography variant="h2" sx={{ mb: 3 }}>
                      No-Code AI: Revolutionize Your Workflow
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 4 }}>
                      Build sophisticated AI tools without writing code, all running on your own hardware. Empower your team to create custom AI solutions with our intuitive drag-and-drop interface.
                    </Typography>
                    <Box component="ul" sx={{ mb: 4, pl: 0, listStyle: 'none' }}>
                      {['Intuitive drag-and-drop interface', 'Pre-built AI components', 'Customizable workflows', 'Seamless integration with existing systems'].map((item, index) => (
                        <Box component="li" key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <Zap style={{ width: 20, height: 20, marginRight: 8, color: '#50C878' }} />
                          <Typography variant="body1">{item}</Typography>
                        </Box>
                      ))}
                    </Box>
                    <StyledButton 
                      variant="contained" 
                      size="large"
                      href="https://meetings-eu1.hubspot.com/mazda?uuid=36465083-6274-4cc0-a076-e62c90c5450c"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Schedule a demo now
                    </StyledButton>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box component="img" src="../../images/nocode_ai.png" alt="No-Code AI Interface" sx={{ width: '130%', height: 'auto', borderRadius: 2, boxShadow: 3 }} />
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Box>

          <Box sx={{ position: 'relative', overflow: 'hidden', width: '100vw' }}>
            <Container maxWidth="lg" sx={{ py: 10, position: 'relative', zIndex: 1 }} id="on-premise">
              <Typography variant="h2" align="center" sx={{ mb: 6 }}>
                The On-Premise Advantage
              </Typography>
              <Grid container spacing={4}>
                {advantages.map((advantage, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <FeatureCard>
                      <CardContent sx={{ p: 3, height: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                          <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>
                            <advantage.icon style={{ color: theme.palette.common.black }} />
                          </Avatar>
                          <Typography variant="h6">{advantage.title}</Typography>
                        </Box>
                        <Typography variant="body2" color="text.secondary">{advantage.description}</Typography>
                      </CardContent>
                    </FeatureCard>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>

          <Box sx={{ position: 'relative', overflow: 'hidden', width: '100vw' }}>
            <Box sx={{ bgcolor: 'background.paper', py: 10 }} id="deployment">
              
              <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
                <Typography variant="h2" align="center" sx={{ mb: 6 }}>
                  On-Premise Deployment & Monitoring
                </Typography>
                <Grid container spacing={6} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <Typography variant="h3" sx={{ mb: 3 }}>
                      Effortless Local Deployment
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 4 }}>
                      Deploy AI models on your own infrastructure with just a few clicks. Our intuitive interface makes it easy to manage and scale your on-premise AI solutions.
                    </Typography>
                    <StyledButton variant="outlined">
                      Explore On-Premise Deployment
                    </StyledButton>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box component="img" src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/deployment-UYfvmek6J3M0DlSxwvbbdm47DElDIS.png" alt="On-Premise Deployment Dashboard" sx={{ width: '100%', height: 'auto', borderRadius: 2, boxShadow: 3 }} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box component="img" src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/monitoring-WqOd0GJValZatr7WED44vkSDSNQViy.png" alt="Local Monitoring Dashboard" sx={{ width: '100%', height: 'auto', borderRadius: 2, boxShadow: 3 }} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h3" sx={{ mb: 3 }}>
                      Real-time Local Monitoring
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 4 }}>
                      Keep track of your AI models' performance with our comprehensive monitoring tools, all running on your local infrastructure. Gain insights and optimize your models in real-time without external dependencies.
                    </Typography>
                    <StyledButton variant="outlined">
                      View On-Premise Monitoring
                    </StyledButton>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Box>


          <Box sx={{ position: 'relative', overflow: 'hidden', width: '100vw' }}>
            <Box sx={{ py: 10 }} id="scheduler">
              <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
                <Grid container spacing={6} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <Typography variant="h2" sx={{ mb: 3 }}>
                      PioScheduler: Revolutionizing GPU Utilization
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 4 }}>
                      Transform your data center's efficiency with our advanced GPU scheduling system. PioScheduler dynamically allocates and redistributes GPU resources to maximize efficiency and reduce costs.
                    </Typography>
                    <Box component="ul" sx={{ mb: 4, pl: 0, listStyle: 'none' }}>
                      {[
                        'Dynamic resource allocation',
                        'Non-blocking GPU reservations',
                        'Real-time utilization monitoring',
                        'Automated workload distribution'
                      ].map((item, index) => (
                        <Box component="li" key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <Zap style={{ width: 20, height: 20, marginRight: 8, color: '#50C878' }} />
                          <Typography variant="body1">{item}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ height: 400 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          data={[
                            { name: 'Traditional', utilization: 8 },
                            { name: 'PioScheduler', utilization: 74 }
                          ]}
                          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" style={{ fontSize: 16, fill: '#FFFFFF' }} />
                          <YAxis label={{ value: 'GPU Utilization %',
                            angle: -90,
                            position: 'insideLeftRight',
                            offset: 20
                          }} style={{
                            textAnchor: 'middle',
                            fill: '#FFFFFF',
                            fontSize: 14
                          }} />
                          <Tooltip />
                          <Bar dataKey="utilization" fill="#50C878" />
                        </BarChart>
                      </ResponsiveContainer>
                      <Typography variant="body2" align="center" sx={{ mt: 2, color: 'text.secondary' }}>
                        Average GPU Utilization in Datacenters Comparison 
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Box>


          <Box sx={{ position: 'relative', overflow: 'hidden', width: '100vw', bgcolor: 'background.paper' }}>
            <Container maxWidth="lg" sx={{ py: 10, position: 'relative', zIndex: 1 }} id="marketplace">
              <Typography variant="h2" align="center" sx={{ mb: 6 }}>
                On-Premise AI Marketplace Highlights
              </Typography>
              <Grid container spacing={4}>
                {marketplaceApps.map((app, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <FeatureCard>
                      <CardContent sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                          <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>
                            <app.icon style={{ color: theme.palette.common.black }} />
                          </Avatar>
                          <Typography variant="h6">{app.title}</Typography>
                        </Box>
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 2, flexGrow: 1 }}>{app.description}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                          <Server size={16} />
                          <Typography variant="caption" sx={{ fontWeight: 500 }}>
                            Runs 100% on your infrastructure
                          </Typography>
                        </Box>
                      </CardContent>
                    </FeatureCard>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>

          <Box sx={{ position: 'relative', overflow: 'hidden', width: '100vw' }}>
            <Box sx={{ py: 10 }}>
              <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
                <Typography variant="h2" align="center" sx={{ mb: 6 }}>
                  What Our On-Premise Customers Say
                </Typography>
                <Grid container spacing={4}>
                  {testimonials.map((testimonial, index) => (
                    <Grid item xs={12} md={4} key={index}>
                      <FeatureCard sx={{ height: '100%' }}>
                        <CardContent sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <Avatar src={testimonial.avatar} sx={{ mr: 2, width: 60, height: 60 }} variant="rounded" />
                            <Box>
                              <Typography variant="subtitle1">{testimonial.name}</Typography>
                              <Typography variant="body2" color="text.secondary">{testimonial.company}</Typography>
                            </Box>
                          </Box>
                          <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic', flexGrow: 1 }}>"{testimonial.quote}"</Typography>
                        </CardContent>
                      </FeatureCard>
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </Box>
          </Box>

          <Box sx={{ position: 'relative', overflow: 'hidden', width: '100vw' }}>
            <Box sx={{  bgcolor: 'background.paper', py: 10 }}>
              <Container maxWidth="md" sx={{ textAlign: 'center', position: 'relative', zIndex: 1 }}>
                <Typography variant="h2" sx={{ mb: 3 }}>
                  Ready to Revolutionize Your AI Workflow with No-Code Solutions?
                </Typography>
                <Typography variant="body1" sx={{ mb: 4 }}>
                  Join companies leveraging Piosphere's No-Code AI platform to power their AI initiatives entirely on their own infrastructure.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}>
                  <Button 
                    variant="outlined" 
                    size="large" 
                    sx={{ color: 'common.white', borderColor: 'common.white' }}
                    href="https://meetings-eu1.hubspot.com/mazda?uuid=36465083-6274-4cc0-a076-e62c90c5450c"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Schedule a Demo
                  </Button>
                </Box>
              </Container>
            </Box>
          </Box>

          <Box component="footer" sx={{ py: 6 }}>
            <Container maxWidth="lg">
              <Grid container spacing={4}>
                {[
                  { title: 'Product', links: ['No-Code AI Features', 'On-Premise Solutions', 'Pricing', 'Local Marketplace'] },
                  { title: 'Company', links: ['About Us', 'Careers', 'Blog'] },
                  { title: 'Resources', links: ['Documentation', 'No-Code AI Tutorials', 'Support'] },
                  { title: 'Connect', links: ['Twitter', 'LinkedIn', 'GitHub'] },
                ].map((section) => (
                  <Grid item xs={6} md={3} key={section.title}>
                    <Typography variant="h6" sx={{ mb: 2, color: 'common.white' }}>{section.title}</Typography>
                    <nav>
                      {section.links.map((link) => (
                        <MuiLink 
                          key={link} 
                          href="#" 
                          sx={{ 
                            mb: 1, 
                            textDecoration: 'none',
                            color: 'common.white',
                            display: 'block',
                            '&:hover': { color: 'primary.main' }
                          }}
                        >
                          {link}
                        </MuiLink>
                      ))}
                    </nav>
                  </Grid>
                ))}
              </Grid>
              <Box sx={{ mt: 4, textAlign: 'center' }}>
                <Typography variant="body2" color="common.white">
                  © {new Date().getFullYear()} Piosphere. All rights reserved.
                </Typography>
              </Box>
            </Container>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}