import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const registerUser = async ({
  firstName,
  lastName,
  businessEmail,
  phoneNumber,
  company,
  position,
  department,
  password
}) => {
  try {
    const response = await axios.post(`${BASE_URL}/user/public/v1/user`, {
      first_name: firstName,
      last_name: lastName,
      email: businessEmail,
      phone: phoneNumber,
      password: password, 
      company,
      position: position || "",
      department: department || "",
      team: "",
      attributes: null
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const userData = response.data.data;
    
    if (!userData) {
      throw new Error('No user data received');
    }

    return {
      success: response.data.status === 0,
      user: {
        id: userData.id,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        phone: userData.phone,
        company: userData.company,
        position: userData.position,
        department: userData.department
      }
    };
  } catch (error) {
    console.error('API Error:', error);
    
    if (error.response?.status === 503) { // Service Unavailable
      return {
        success: false,
        error: 'Service temporarily unavailable'
      };
    }
    
    return {
      success: false,
      error: error.response?.data?.message || error.message || 'Registration failed'
    };
  }
};

export const getCurrentUser = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/user/v1/current/user`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    });

    const userData = response.data.data;
    console.log('Current user data:', userData);
    
    if (!userData) {
      throw new Error('No user data received');
    }

    return {
      success: response.data.status === 0,
      user: {
        id: userData.id,
        firstName: userData.first_name,
        lastName: userData.last_name,
        email: userData.email,
        phone: userData.phone,
        company: userData.company,
        position: userData.position,
        department: userData.department,
        team: userData.team,
        attributes: userData.attributes
      }
    };
  } catch (error) {
    console.error('API Error:', error);
    
    if (error.response?.status === 503) {
      return {
        success: false,
        error: 'Service temporarily unavailable'
      };
    }
    
    return {
      success: false,
      error: error.response?.data?.message || error.message || 'Failed to fetch current user'
    };
  }
};
