import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';

export default function MeetingSummarizer() {
  const [meetingName, setMeetingName] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [audioStream, setAudioStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [recordings, setRecordings] = useState([
    {
      name: "Weekly Team Sync",
      date: "3/20/2024, 10:00:00 AM",
      blob: new Blob([], { type: 'audio/wav' })
    },
    {
      name: "Product Planning",
      date: "3/19/2024, 2:30:00 PM",
      blob: new Blob([], { type: 'audio/wav' })
    },
    {
      name: "Client Meeting - ABC Corp",
      date: "3/18/2024, 11:15:00 AM",
      blob: new Blob([], { type: 'audio/wav' })
    }
  ]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setAudioStream(stream);
      
      const recorder = new MediaRecorder(stream);
      const chunks = [];
      
      recorder.ondataavailable = (e) => chunks.push(e.data);
      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/wav' });
        setAudioBlob(blob);
        const newRecording = {
          name: meetingName,
          date: new Date().toLocaleString(),
          blob: blob,
        };
        setRecordings([...recordings, newRecording]);
      };
      
      recorder.start();
      setMediaRecorder(recorder);
      setIsRecording(true);
    } catch (err) {
      console.error('Error accessing microphone:', err);
    }
  };

  const stopRecording = () => {
    mediaRecorder.stop();
    audioStream.getTracks().forEach(track => track.stop());
    setIsRecording(false);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Meeting Summariser
      </Typography>
      <Box component={Paper} sx={{ p: 3, mb: 4 }}>
        <TextField
          fullWidth
          label="Enter Meeting Name"
          value={meetingName}
          onChange={(e) => setMeetingName(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
          <Button
            variant="contained"
            color={isRecording ? "error" : "primary"}
            startIcon={isRecording ? <StopIcon /> : <MicIcon />}
            onClick={isRecording ? stopRecording : startRecording}
          >
            {isRecording ? 'Stop Recording' : 'Start Recording'}
          </Button>
          {audioBlob && (
            <audio controls src={URL.createObjectURL(audioBlob)} />
          )}
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button 
            variant="contained" 
            color="primary"
            disabled={!audioBlob}
          >
            Process Audio
          </Button>
          <Button 
            variant="outlined" 
            color="secondary"
            onClick={() => setAudioBlob(null)}
            disabled={!audioBlob}
          >
            Clear
          </Button>
        </Box>
      </Box>
      <Box component={Paper} sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Recorded Meetings
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Meeting Name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Audio</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recordings.map((recording, index) => (
                <TableRow key={index}>
                  <TableCell>{recording.name}</TableCell>
                  <TableCell>{recording.date}</TableCell>
                  <TableCell>
                    <audio controls src={URL.createObjectURL(recording.blob)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
