import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppShell from './components/Appshell';
import { AuthProvider } from './components/authentication/AuthContext';

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppShell />
      </Router>
    </AuthProvider>
  );
}

export default App;
