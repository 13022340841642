import React, { useState, useEffect } from 'react';
import {
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Box,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Alert,
  Chip,
} from '@mui/material';
import {
  CloudUpload as CloudUploadIcon,
  Search as SearchIcon,
} from '@mui/icons-material';

const DataPreparation = ({ datasets, selectedModel, onDatasetSelect }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const ALLOWED_DATASET_TYPES = ['text', 'instruction', 'chat', 'dialogue', 'qa'];

  const isAllowedDataset = (dataset) => {
    if (!dataset.tags || !Array.isArray(dataset.tags)) return false;
    return dataset.tags.some(tag => 
      ALLOWED_DATASET_TYPES.some(allowedType => 
        tag.toLowerCase().includes(allowedType)
      )
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (searchQuery.trim().length >= 3) {
        setLoading(true);
        setError(null);
        try {
          const response = await fetch(
            `https://huggingface.co/api/datasets?search=${encodeURIComponent(searchQuery)}&limit=5`
          );
          if (!response.ok) throw new Error('Failed to fetch datasets');
          
          const data = await response.json();
          const transformedResults = data.map(dataset => ({
            id: dataset.id,
            name: dataset.id,
            description: dataset.description || "No description available",
            creator: dataset.author || "Unknown",
            downloads: dataset.downloads || 0,
            likes: dataset.likes || 0,
            tags: dataset.tags || [],
            isAllowed: false // Will be set below
          }));

          // Set isAllowed flag for each dataset
          transformedResults.forEach(dataset => {
            dataset.isAllowed = isAllowedDataset(dataset);
          });

          setSearchResults(transformedResults);
        } catch (error) {
          setError('Failed to fetch dataset suggestions.');
        } finally {
          setLoading(false);
        }
      } else {
        setSearchResults([]);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  const handleDatasetSelect = (dataset) => {
    if (!dataset.isAllowed) {
      setError(
        `Only text-based datasets are currently supported. This dataset appears to be of a different type.`
      );
      return;
    }
    setError(null);
    onDatasetSelect(dataset);
  };

  // Mock my datasets - in real app this would come from props or API
  const myDatasets = [
    { 
      id: 1, 
      name: "Customer Support Data", 
      size: "1.2M", 
      samples: "50k", 
      updated: "2 days ago",
      description: "Custom dataset for customer support training"
    },
    { 
      id: 2, 
      name: "Product Reviews", 
      size: "800K", 
      samples: "35k", 
      updated: "5 days ago",
      description: "Curated product review dataset"
    }
  ];

  const handleFileUpload = (event) => {
    // Handle file upload logic here
    console.log('File uploaded:', event.target.files[0]);
  };

  return (
    <>
{/* Hugging Face Search Section */}
<Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>Browse Hugging Face Datasets</Typography>
        <TextField
          fullWidth
          label="Search datasets (e.g., 'alpaca', 'squad', 'wiki')"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ mb: 2 }}
        />

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
            <CircularProgress size={24} />
          </Box>
        )}
        
        {error && (
          <Alert 
            severity="error" 
            sx={{ mb: 2 }}
            onClose={() => setError(null)}
          >
            {error}
          </Alert>
        )}

        {searchResults.length > 0 && (
          <Card sx={{ mb: 4 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Search Results</Typography>
              <Grid container spacing={2}>
                {searchResults.map((dataset) => (
                  <Grid item xs={12} key={dataset.id}>
                    <Card 
                      variant="outlined"
                      sx={!dataset.isAllowed ? { 
                        opacity: 0.7,
                        backgroundColor: 'action.disabledBackground' 
                      } : {}}
                    >
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                          <Box>
                            <Typography variant="subtitle1" component="div">
                              {dataset.name}
                              {!dataset.isAllowed && (
                                <Chip
                                  label="Unsupported Type"
                                  size="small"
                                  color="default"
                                  sx={{ ml: 1 }}
                                />
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              By {dataset.creator}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                              {dataset.description}
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 1 }}>
                              {dataset.tags.slice(0, 3).map((tag, index) => (
                                <Chip
                                  key={index}
                                  label={tag}
                                  size="small"
                                  variant="outlined"
                                />
                              ))}
                            </Box>
                            <Typography variant="caption" color="text.secondary">
                              {dataset.downloads.toLocaleString()} downloads
                            </Typography>
                          </Box>
                          <Button 
                            variant="contained"
                            onClick={() => handleDatasetSelect(dataset)}
                            sx={{ ml: 2 }}
                          >
                            Select Dataset
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        )}
      </Box>
      
      {/* Upload Section */}
      <Box sx={{ mb: 6 }}>
        <Typography variant="h6" gutterBottom>Upload Dataset</Typography>
        <Card>
          <CardContent>
            <Box sx={{ 
              border: '2px dashed grey', 
              borderRadius: 1, 
              p: 3, 
              textAlign: 'center',
              backgroundColor: 'action.hover'
            }}>
              <input
                accept=".csv,.json,.jsonl,.txt"
                style={{ display: 'none' }}
                id="dataset-upload"
                type="file"
                onChange={handleFileUpload}
                disabled
              />
              <label htmlFor="dataset-upload">
                <Button
                  variant="contained"
                  component="span"
                  startIcon={<CloudUploadIcon />}
                  disabled
                >
                  Upload Dataset
                </Button>
              </label>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Dataset upload functionality is currently disabled
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>

      {/* My Datasets Section */}
      {/* <Box sx={{ mb: 6 }}>
        <Typography variant="h6" gutterBottom>My Datasets</Typography>
        <Grid container spacing={2}>
          {myDatasets.map((dataset) => (
            <Grid item xs={12} sm={6} md={4} key={dataset.id}>
              <Card>
                <CardContent>
                  <Typography variant="subtitle1">{dataset.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {dataset.description}
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <Chip size="small" label={`${dataset.size} size`} sx={{ mr: 1 }} />
                    <Chip size="small" label={`${dataset.samples} samples`} sx={{ mr: 1 }} />
                    <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                      Updated {dataset.updated}
                    </Typography>
                  </Box>
                  <Button 
                    variant="contained"
                    onClick={() => onDatasetSelect(dataset)}
                    sx={{ mt: 2 }}
                  >
                    Select Dataset
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box> */}

      {/* Public Datasets Section */}
      <Box sx={{ mb: 6 }}>
        <Typography variant="h6" gutterBottom>Public Datasets</Typography>
        <Grid container spacing={2}>
          {datasets.map((dataset) => (
            <Grid item xs={12} sm={6} md={4} key={dataset.id}>
              <Card>
                <CardContent>
                  <Typography variant="subtitle1">{dataset.name}</Typography>
                  <Box sx={{ mt: 1 }}>
                    <Chip size="small" label={`${dataset.size} size`} sx={{ mr: 1 }} />
                    <Chip size="small" label={`${dataset.samples} samples`} sx={{ mr: 1 }} />
                    <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                      Updated {dataset.updated}
                    </Typography>
                  </Box>
                  <Button 
                    variant="contained"
                    onClick={() => onDatasetSelect(dataset)}
                    sx={{ mt: 2 }}
                  >
                    Select Dataset
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default DataPreparation;
