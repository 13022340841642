import React from 'react';
import {
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Box,
  LinearProgress,
  Chip,
} from '@mui/material';
import {
  Memory as MemoryIcon,
  Speed as SpeedIcon,
  Timeline as TimelineIcon,
  Storage as StorageIcon,
  Build as BuildIcon,
  RocketLaunch as RocketLaunchIcon,
  MonitorHeart as MonitorIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { theme } from '../theme';

const Overview = ({ onLaunchTraining, activeJobs, completedJobs, myModels, onCancelJob, onDeleteJob }) => {
  const navigate = useNavigate();
  
  const handleMonitorJob = (jobId) => {
    navigate(`/training/monitor/${jobId}`);
  };

  return (
    <Grid container spacing={3}>
      {/* Launch Training Button */}
      <Grid item xs={12}>
        <Card 
          sx={{ 
            mb: 3, 
            textAlign: 'center', 
            py: 6,
            background: theme.palette.primary.main,
            transition: 'transform 0.2s',
          }}
        >
          <Typography variant="h5" sx={{ color: 'black', mb: 2 }}>
            Ready to start training your model?
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={onLaunchTraining}
            startIcon={<RocketLaunchIcon />}
            sx={{ 
              px: 6, 
              py: 2,
              fontSize: '1.2rem',
              background: 'white',
              boxShadow: '0 3px 5px 2px rgba(80, 200, 120, .3)',
              '&:hover': {
                background: 'linear-gradient(45deg, #3DA75B 30%, #50C878 90%)',
              }
            }}
          >
            Launch Training
          </Button>
        </Card>
      </Grid>

      {/* Active Training Jobs Section */}
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mb: 2 }}>Active Training Jobs</Typography>
        <Grid container spacing={2} sx={{ maxHeight: '400px', overflowY: 'auto' }}>
          {activeJobs.map(job => (
            <Grid item xs={12} md={6} key={job.id}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography variant="h6">{job.name}</Typography>
                      <Chip 
                        label={job.status} 
                        color="primary" 
                        size="small" 
                      />
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleMonitorJob(job.id)}
                        startIcon={<MonitorIcon />}
                        sx={{ 
                          background: 'linear-gradient(45deg, #50C878 30%, #3DA75B 90%)',
                          boxShadow: '0 3px 5px 2px rgba(80, 200, 120, .3)',
                          '&:hover': {
                            background: 'linear-gradient(45deg, #3DA75B 30%, #50C878 90%)',
                            transform: 'translateY(-2px)'
                          },
                          transition: 'transform 0.2s',
                          mr: 1
                        }}
                      >
                        Monitor Job
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => onCancelJob(job.id)}
                        sx={{ 
                          color: 'red',
                          borderColor: 'red',
                          '&:hover': {
                            borderColor: 'darkred',
                            color: 'darkred'
                          }
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    Dataset: {job.dataset}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                      Progress: {job.progress}% (ETA: {job.eta})
                    </Typography>
                    <LinearProgress 
                      variant="determinate" 
                      value={job.progress} 
                      sx={{ mt: 1 }} 
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                      Current Metrics:
                    </Typography>
                    <Grid container spacing={1} sx={{ mt: 1 }}>
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          Loss: {job.metrics.epochs[job.metrics.currentEpoch - 1].loss.toFixed(3)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          Accuracy: {job.metrics.epochs[job.metrics.currentEpoch - 1].accuracy.toFixed(3)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {/* Completed Jobs Section */}
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mb: 2 }}>Completed Jobs</Typography>
        <Grid container spacing={2} sx={{ maxHeight: '400px', overflowY: 'auto' }}>
          {completedJobs.map(job => (
            <Grid item xs={12} md={6} key={job.id}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography variant="h6">{job.name}</Typography>
                      <Chip 
                        label={`${job.completedAt}`}  
                        color="primary"   
                        size="small" 
                      />
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleMonitorJob(job.id)}
                        startIcon={<MonitorIcon />}
                        sx={{ 
                          background: 'linear-gradient(45deg, #50C878 30%, #3DA75B 90%)',
                          boxShadow: '0 3px 5px 2px rgba(80, 200, 120, .3)',
                          '&:hover': {
                            background: 'linear-gradient(45deg, #3DA75B 30%, #50C878 90%)',
                            transform: 'translateY(-2px)'
                          },
                          transition: 'transform 0.2s',
                          mr: 1
                        }}
                      >
                        Monitor Job
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => onDeleteJob(job.id)}
                        sx={{ 
                          color: 'red',
                          borderColor: 'red',
                          '&:hover': {
                            borderColor: 'darkred',
                            color: 'darkred'
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Box>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    Dataset: {job.dataset}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                      Final Metrics:
                    </Typography>
                    <Grid container spacing={1} sx={{ mt: 1 }}>
                      <Grid item xs={4}>
                        <Typography variant="body2">
                          Accuracy: {job.metrics.finalMetrics.accuracy.toFixed(3)}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2">
                          F1 Score: {job.metrics.finalMetrics.f1Score.toFixed(3)}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2">
                          Loss: {job.metrics.finalMetrics.loss.toFixed(3)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Overview;
