import React, { useState } from 'react';
import { Typography, Grid, Paper, List, ListItem, ListItemText, Chip, Box } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar, ResponsiveContainer } from 'recharts';
import { useTheme } from '@mui/material/styles';

const benchmarkHistory = [
    {
      id: 4,
      name: "Throughput Performance - AMD MI250, AMD MI250, NVIDIA GH200",
      type: "Hardware Performance",
      date: "2024-02-01",
      models: [
        "meta-llama/Llama-3.2-3B-Instruct",
        "meta-llama/Llama-3.1-8B",
        "inceptionai/jais-adapted-7b-chat"  
      ],
      hardware: ["AMD MI250", "AMD MI210", "NVIDIA GH200"],
      metrics: {
        latency: {
          "AMD MI250": {
            "meta-llama/Llama-3.2-3B-Instruct": 32,
            "meta-llama/Llama-3.1-8B": 48,
            "inceptionai/jais-adapted-7b-chat": 45
          },
          "AMD MI210": {
            "meta-llama/Llama-3.2-3B-Instruct": 38,
            "meta-llama/Llama-3.1-8B": 55,
            "inceptionai/jais-adapted-7b-chat": 52
          },
          "NVIDIA GH200": {
            "meta-llama/Llama-3.2-3B-Instruct": 28,
            "meta-llama/Llama-3.1-8B": 42,
            "inceptionai/jais-adapted-7b-chat": 40
          }
        },
        throughput: {
          "AMD MI250": {
            "meta-llama/Llama-3.2-3B-Instruct": 220,
            "meta-llama/Llama-3.1-8B": 180,
            "inceptionai/jais-adapted-7b-chat": 190
          },
          "AMD MI210": {
            "meta-llama/Llama-3.2-3B-Instruct": 190,
            "meta-llama/Llama-3.1-8B": 160,
            "inceptionai/jais-adapted-7b-chat": 170
          },
          "NVIDIA GH200": {
            "meta-llama/Llama-3.2-3B-Instruct": 250,
            "meta-llama/Llama-3.1-8B": 210,
            "inceptionai/jais-adapted-7b-chat": 220
          }
        }
      }
    }];

function BenchmarkHistory() {
  const [selectedBenchmark, setSelectedBenchmark] = useState(null);
  
  return (
    <Grid container spacing={3}>
      {/* Benchmark List */}
      <Grid item xs={4}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>Recent Benchmarks</Typography>
          <List>
            {benchmarkHistory.map(benchmark => (
              <ListItem 
                button 
                key={benchmark.id}
                selected={selectedBenchmark?.id === benchmark.id}
                onClick={() => setSelectedBenchmark(benchmark)}
              >
                <ListItemText 
                  primary={benchmark.name}
                  secondary={
                    <>
                      <Typography variant="caption" display="block">
                        {benchmark.date}
                      </Typography>
                      <Typography variant="caption" display="block">
                        {benchmark.models.length} models • {benchmark.type}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>

      {/* Benchmark Details */}
      <Grid item xs={8}>
        {selectedBenchmark && (
          <BenchmarkResults benchmark={selectedBenchmark} />
        )}
      </Grid>
    </Grid>
  );
}

function BenchmarkResults({ benchmark }) {
  const theme = useTheme();

  // Keep only the getHardwareChartData function
  const getHardwareChartData = (metricType) => {
    if (benchmark.type !== "Hardware Performance" || !benchmark.hardware) {
      return [];
    }

    return benchmark.models.map(model => ({
      name: model.split('/').pop(),
      ...benchmark.hardware.reduce((acc, hw) => ({
        ...acc,
        [hw]: benchmark.metrics[metricType][hw][model]
      }), {})
    }));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      {/* Benchmark Header */}
      <Paper sx={{ p: 2 }}>
        <Typography variant="h6">{benchmark.name}</Typography>
        <Box sx={{ display: 'flex', gap: 2, mt: 1, flexWrap: 'wrap' }}>
          <Chip label={benchmark.type} />
          <Chip label={benchmark.date} variant="outlined" />
          <Chip label={`${benchmark.models.length} models`} variant="outlined" />
          {benchmark.hardware && benchmark.hardware.map(hw => (
            <Chip key={hw} label={hw} variant="outlined" />
          ))}
        </Box>
      </Paper>

      {/* Only Throughput Chart */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="subtitle1" gutterBottom>Throughput Comparison (tokens/second)</Typography>
            <Box sx={{ width: '100%', height: 400 }}>
              <BarChart
                width={800}
                height={350}
                data={getHardwareChartData('throughput')}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                {benchmark.hardware.map((hw, index) => (
                  <Bar 
                    key={hw}
                    dataKey={hw}
                    fill={`hsl(${index * 120}, 70%, 50%)`}
                  />
                ))}
              </BarChart>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default BenchmarkHistory;
