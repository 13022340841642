import React, { useState, useEffect } from 'react';
import { Typography, Grid, Card, CardHeader, CardContent, Avatar, Select, MenuItem, List, ListItem, ListItemText, ListItemAvatar, Box, Button, Chip } from '@mui/material';
import { Memory as MemoryIcon, Psychology as PsychologyIcon, Storage as StorageIcon, Thermostat as ThermostatIcon, Notifications as NotificationsIcon, Computer as ComputerIcon, Api as ApiIcon, Add as AddIcon, PlayArrow as PlayArrowIcon, CloudUpload as CloudUploadIcon, Speed as SpeedIcon, AccessTime as AccessTimeIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import StyledLinearProgress from '../StyledLinearProgress';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useMediaQuery } from '@mui/material';
import { getUserInventory } from '../api/services/userService/Inventory';
import { useAuth } from '../authentication/AuthContext';

const transformServerData = (apiServer) => ({
  id: apiServer.id,
  name: apiServer.hostname,
  status: apiServer.status === "active" ? "Online" : "Offline",
  type: apiServer.type === 0 ? "Storage" : "Compute",
  cpu: apiServer.num_of_cpus ? Math.min(apiServer.num_of_cpus * 1.5, 100) : 0, // Scale CPU usage
  gpu: apiServer.num_of_gpus ? Math.min(apiServer.num_of_gpus * 12.5, 100) : 0, // Scale GPU usage
  memory: apiServer.mem ? Math.min((apiServer.mem / 1024) * 10, 100) : 0, // Convert MB to percentage
  temperature: Math.round(45 + Math.random() * 30), // Random temperature between 45-75°C
  avgCPU: apiServer.num_of_cpus ? Math.min(apiServer.num_of_cpus * 1.2, 100) : 0,
  avgTemp: 40 + Math.random() * 35, // Random average temperature between 40-75°C
  gpuVendor: apiServer.gpu_type === 0 ? "AMD" : "NVIDIA",
  gpuType: apiServer.gpu || "N/A",
  location: apiServer.location || "Unknown"
});

const resourceData = [
  { time: '00:00', cpu: 65, gpu: 80, memory: 50, temperature: 70 },
  { time: '01:00', cpu: 70, gpu: 75, memory: 55, temperature: 72 },
  // ... add more data points
];

const notifications = [
  { id: 1, message: 'High CPU usage detected on Production Server 1', severity: 'warning' },
  { id: 2, message: 'GPU temperature exceeding threshold on Staging Server', severity: 'error' },
  // ... add more notifications
];

const trainingJobs = [
  { id: 1, name: "Mistral-7B Fine-tuning", status: "In Progress", progress: 33 },
  { id: 2, name: "CodeLlama Python Fine-tuning", status: "In Progress", progress: 67 },
  { id: 3, name: "Llama-3.2-3B Fine-tuning", status: "Completed", progress: 100 }
];

const deployedModels = [
  { id: 1, name: "Sentiment Analysis", apiCalls: 1250, version: "1.2.3", lastUpdated: "2023-04-15", avgResponseTime: 120 },
  { id: 2, name: "Named Entity Recognition", apiCalls: 980, version: "2.0.1", lastUpdated: "2023-04-10", avgResponseTime: 150 },
  // ... add more deployed models
];

export default function Dashboard() {
  const [servers, setServers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { keycloak } = useAuth();
  const [selectedServer, setSelectedServer] = useState(null);
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchServers = async () => {
      try {
        await keycloak.updateToken(30);
        setLoading(true);
        const response = await getUserInventory(keycloak.subject, keycloak.token);
        
        if (response.success) {
          const transformedServers = response.data.map(transformServerData);
          setServers(transformedServers);
          setSelectedServer(transformedServers[0]); // Set first server as selected
        } else {
          console.error('Failed to fetch servers:', response.error);
        }
      } catch (error) {
        console.error('Error fetching servers:', error);
        if (error.message?.includes('token')) {
          await keycloak.updateToken(30);
        }
      } finally {
        setLoading(false);
      }
    };

    if (keycloak.token) {
      fetchServers();
    }
  }, [keycloak.token, keycloak.subject]);

  const getResourceData = (server) => {
    if (!server) return [];
    
    return [
      { 
        time: 'Current', 
        cpu: Math.round(server.cpu || 0), 
        gpu: Math.round(server.gpu || 0), 
        memory: Math.round(server.memory || 0), 
        temperature: Math.round(server.temperature || 0) 
      },
      { 
        time: 'Average', 
        cpu: Math.round(server.avgCPU || 0), 
        gpu: Math.round(server.gpu || 0), 
        memory: Math.round(server.memory || 0), 
        temperature: Math.round(server.avgTemp || 0) 
      }
    ];
  };

  return (
    <Box sx={{ p: isMobile ? 2 : 3 }}>
      <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant={isMobile ? "h5" : "h4"}>Dashboard</Typography>
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate('/training')}
            size={isMobile ? "large" : "medium"}
          >
            New Training Job
          </Button>
          <Button
            variant="contained"
            startIcon={<ComputerIcon />}
            onClick={() => navigate('/discover-instances')}
            size={isMobile ? "large" : "medium"}
          >
            Add Server
          </Button>
          <Button
            variant="contained"
            startIcon={<CloudUploadIcon />}
            onClick={() => navigate('/deployment')}
            size={isMobile ? "large" : "medium"}
          >
            Deploy Model
          </Button>
        </Box>
      </Box>
      <Grid container spacing={3}>
        {/* Resource Usage Graph */}
        <Grid item xs={12} lg={8}>
          <Card>
            <CardHeader
              title="Resource Usage"
              action={
                <Select
                  value={selectedServer?.id}
                  onChange={(e) => setSelectedServer(servers.find(s => s.id === e.target.value))}
                  size="small"
                >
                  {servers.map((server) => (
                    <MenuItem key={server.id} value={server.id}>{server.name}</MenuItem>
                  ))}
                </Select>
              }
            />
            <CardContent>
              <ResponsiveContainer width="100%" height={isMobile ? 200 : 300}>
                <LineChart data={getResourceData(selectedServer)}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="time" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="cpu" stroke="#8884d8" name="CPU" />
                  <Line type="monotone" dataKey="gpu" stroke="#82ca9d" name="GPU" />
                  <Line type="monotone" dataKey="memory" stroke="#ffc658" name="Memory" />
                  <Line type="monotone" dataKey="temperature" stroke="#ff7300" name="Temperature" />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Notifications */}
        <Grid item xs={12} lg={4}>
          <Card sx={{ height: '100%' }}>
            <CardHeader
              title="Notifications"
              avatar={<Avatar sx={{ bgcolor: 'secondary.main' }}><NotificationsIcon /></Avatar>}
            />
            <CardContent>
              <List>
                {notifications.map((notification) => (
                  <ListItem key={notification.id}>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: notification.severity === 'error' ? 'error.main' : 'warning.main' }}>
                        <NotificationsIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                      primary={notification.message}
                      secondary={
                        <Typography component="div">
                          <Chip 
                            label={notification.severity} 
                            size="small" 
                            color={notification.severity === 'error' ? 'error' : 'warning'} 
                          />
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Active Training Jobs */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader 
              title="Active Training Jobs"
              avatar={<Avatar sx={{ bgcolor: 'primary.main' }}><PsychologyIcon /></Avatar>}
            />
            <CardContent sx={{ maxHeight: 300, overflow: 'auto', p: 1 }}>
              <List dense>
                {trainingJobs.map((job) => (
                  <ListItem 
                    key={job.id} 
                    sx={{ 
                      bgcolor: 'background.paper',
                      mb: 1,
                      borderRadius: 1,
                      border: '1px solid',
                      borderColor: 'divider'
                    }}
                  >
                    <Box sx={{ width: '100%', p: 1 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
                        <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                          <PsychologyIcon sx={{ fontSize: 16, mr: 0.5 }} />
                          {job.name}
                        </Typography>
                        <Chip 
                          label={job.status} 
                          size="small" 
                          color={job.status === 'Running' ? 'success' : job.status === 'Queued' ? 'warning' : 'default'}
                        />
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <StyledLinearProgress 
                          variant="determinate" 
                          value={job.progress} 
                          sx={{ flexGrow: 1, height: 6 }}
                        />
                        <Typography variant="caption" sx={{ minWidth: '45px' }}>
                          {job.progress}%
                        </Typography>
                      </Box>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Available Servers */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              title="Available Servers"
              avatar={<Avatar sx={{ bgcolor: 'info.main' }}><ComputerIcon /></Avatar>}
            />
            <CardContent sx={{ maxHeight: 300, overflow: 'auto', p: 1 }}>
              <List dense>
                {servers.map((server) => (
                  <ListItem 
                    key={server.id} 
                    sx={{ 
                      bgcolor: 'background.paper',
                      mb: 1,
                      borderRadius: 1,
                      border: '1px solid',
                      borderColor: 'divider'
                    }}
                  >
                    <Box sx={{ width: '100%', p: 1 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                          <ComputerIcon sx={{ fontSize: 16, mr: 0.5 }} />
                          {server.name}
                        </Typography>
                        <Chip 
                          label={server.status} 
                          size="small" 
                          color={server.status === 'Online' ? 'success' : 'error'}
                        />
                      </Box>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <MemoryIcon sx={{ fontSize: 14, mr: 0.5 }} />
                            <Typography variant="caption">CPU: {server.cpu}%</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <StorageIcon sx={{ fontSize: 14, mr: 0.5 }} />
                            <Typography variant="caption">Memory: {server.memory}%</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ThermostatIcon sx={{ fontSize: 14, mr: 0.5 }} />
                            <Typography variant="caption">Temp: {server.temperature}°C</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                            Type: {server.type}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
