import React, { useState } from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Button,
  ThemeProvider,
  CssBaseline,
  Input,
  ButtonGroup,
} from '@mui/material';
import { theme } from '../theme';

export default function AddInstance({ onAddPlatform, onClose }) {
  const [provisioningType, setProvisioningType] = useState('');
  const [selectedApp, setSelectedApp] = useState('');
  const [serverType, setServerType] = useState('');
  const [dockerUsername, setDockerUsername] = useState('');
  const [dockerToken, setDockerToken] = useState('');
  const [repositoryName, setRepositoryName] = useState('');
  const [tag, setTag] = useState('latest');

  const uiOptions = ['SSH', 'JupyterLab', 'StreamLit'];
  const provisioningOptions = ['BareMetal', 'Virtual BareMetal', 'Container'];
  const containerApps = ['Pytorch', 'Tensorflow', 'Ubuntu', 'Gromac', 'WTF', 'Upload my Container'];
  const gpuNumbers = ['1/16', '1/8', '1/4', '1/2', '1', '2', '4', '8', '16', '32'];
  const cpuCores = ['1', '2', '4', '8', '16', '32'];
  const ramOptions = ['8', '16', '24', '32', '64', '128'];
  const gpuTypes = ['MI210', 'MI250', 'MI300X', 'H100', 'H200'];

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    // Handle the container image file here
    console.log('Uploaded file:', file);
  };

  const handleSubmit = () => {
    // Assuming you have logic to create a platform name
    const newPlatform = `${selectedApp} - ${serverType}`; // Example platform name
    onAddPlatform(newPlatform);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ 
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Box sx={{ 
          p: 3,
          borderBottom: '1px solid #e0e0e0',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Typography variant="h5">
            Allocate Instance
          </Typography>
          <Button onClick={onClose}>
            ✕
          </Button>
        </Box>
        
        <Box sx={{ 
          flex: 1,
          overflowY: 'auto',
          p: 3
        }}>
          <ButtonGroup variant="outlined" sx={{ mb: 3 }}>
            <Button 
              onClick={() => setServerType('Static')}
              variant={serverType === 'Static' ? 'contained' : 'outlined'}
            >
              Static
            </Button>
            <Button
              onClick={() => setServerType('Dynamic')}
              variant={serverType === 'Dynamic' ? 'contained' : 'outlined'}
            >
              Dynamic
            </Button>
          </ButtonGroup>

          <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
            <Select fullWidth defaultValue="" displayEmpty>
              <MenuItem disabled value="">
                Select UI
              </MenuItem>
              {uiOptions.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <Select 
              fullWidth 
              value={provisioningType}
              onChange={(e) => setProvisioningType(e.target.value)}
              displayEmpty
            >
              <MenuItem disabled value="">
                Select Provisioning
              </MenuItem>
              {provisioningOptions.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Box>

          {provisioningType === 'Container' && (
            <Box sx={{ mb: 3 }}>
              <Select 
                fullWidth 
                value={selectedApp}
                onChange={(e) => setSelectedApp(e.target.value)}
                displayEmpty
              >
                <MenuItem disabled value="">Select App</MenuItem>
                {containerApps.map((item) => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
              </Select>
              
              {selectedApp === 'Upload my Container' && (
                <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <Input
                    placeholder="Docker Hub Username"
                    fullWidth
                    value={dockerUsername}
                    onChange={(e) => setDockerUsername(e.target.value)}
                  />
                  <Input
                    type="password"
                    placeholder="Docker Hub Access Token"
                    fullWidth
                    value={dockerToken}
                    onChange={(e) => setDockerToken(e.target.value)}
                  />
                  <Input
                    placeholder="Repository Name (e.g., username/repo-name)"
                    fullWidth
                    value={repositoryName}
                    onChange={(e) => setRepositoryName(e.target.value)}
                  />
                  <Input
                    placeholder="Tag (default: latest)"
                    fullWidth
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                  />
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    Or upload container image file:
                  </Typography>
                  <Input
                    type="file"
                    fullWidth
                    onChange={handleFileUpload}
                    sx={{
                      '&::file-selector-button': {
                        marginRight: '1rem',
                        padding: '0.5rem 1rem',
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                        backgroundColor: '#f8f9fa',
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: '#e9ecef',
                        },
                      },
                    }}
                  />
                  <Typography variant="caption" display="block" sx={{ color: 'text.secondary' }}>
                    Supported formats: .tar, .tar.gz, .docker
                  </Typography>
                </Box>
              )}
            </Box>
          )}

          <Typography variant="h6" gutterBottom>
            Config Setup
          </Typography>

          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
            <Select fullWidth defaultValue="" displayEmpty>
              <MenuItem disabled value="">
                Number of CPU Cores
              </MenuItem>
              {cpuCores.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <Select fullWidth defaultValue="" displayEmpty>
              <MenuItem disabled value="">
                RAM (GB)
              </MenuItem>
              {ramOptions.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <Select fullWidth defaultValue="" displayEmpty>
              <MenuItem disabled value="">
                GPU Type
              </MenuItem>
              {gpuTypes.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <Select fullWidth defaultValue="" displayEmpty>
              <MenuItem disabled value="">
                Number of GPUs
              </MenuItem>
              {gpuNumbers.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>

        <Box sx={{ 
          p: 3,
          borderTop: '1px solid #e0e0e0',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2
        }}>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            Create Instance
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
