import {
  Home as HomeIcon,
  Dashboard as DashboardIcon,
  Psychology as PsychologyIcon,
  Storage as StorageIcon,
  Settings as SettingsIcon,
  Logout as LogoutIcon,
  ModelTraining as ModelTrainingIcon,
  Storefront as StorefrontIcon,
  Help as HelpIcon,
  AutoFixHigh as AutoFixHighIcon,
  Terminal as TerminalIcon,
  Science as ScienceIcon,
  Rocket as RocketIcon,
  Chat as ChatIcon,
} from '@mui/icons-material';

export const menuItems = [
  { label: 'Home', icon: HomeIcon, path: '/home' },
  { label: 'Dashboard', icon: DashboardIcon, path: '/dashboard' },
  { label: 'Models', icon: ModelTrainingIcon, path: '/models' },
  { label: 'Training', icon: ScienceIcon, path: '/training' },
  { label: 'Deployment', icon: RocketIcon, path: '/deployment' },
  { label: 'Cloud Management', icon: StorageIcon, path: '/cloud-management' },
  { label: 'Marketplace', icon: StorefrontIcon, path: '/marketplace' },
];

export const appHighlightItems = [
  { 
    type: 'section',
    label: 'App Highlights'
  },
  { 
    label: 'No-Code AI', 
    icon: AutoFixHighIcon, 
    action: () => window.open('http://no-code-ai.piosphere.ai', '_blank')
  },
  { 
    label: 'PaaS', 
    icon: TerminalIcon, 
    path: '/paas' 
  },
  { 
    label: 'PioChat', 
    icon: ChatIcon, 
    path: '/piochat' 
  },
  { 
    label: 'API Test', 
    icon: TerminalIcon, 
    path: '/test' 
  },    
];

export const bottomMenuItems = [
  { label: 'Settings', icon: SettingsIcon, path: '/settings' },
  // { label: 'Help', icon: HelpIcon, path: '/help' },
  { 
    label: 'Logout', 
    icon: LogoutIcon, 
    action: 'logout'
  },
];
