import React, { useState } from 'react';
import {
  Box, Typography, Stepper, Step, StepLabel, Button,
  Card, CardContent, List, ListItem, ListItemButton, ListItemText,
  Dialog, TextField, IconButton, CircularProgress, Chip, Grid, Checkbox
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

const myModels = [
    { 
      id: 1, 
      name: "Mistral-7B-Instruct Custom", 
      description: "Fine-tuned instruction model", 
      version: "v1.0.1", 
      accuracy: "92%", 
      trainingData: "1M+ samples", 
      updatedAt: "2 days ago", 
      dataset: "Customer Support Conversations",
      parameters: "7B",
      modelSize: "14GB",
      tokenSpeed: "180 tokens/s",
      maxContext: "8K tokens",
      baseModel: "mistralai/Mistral-7B-Instruct-v0.3",
      type: "Instruction-tuned LLM"
    },
    { 
      id: 2, 
      name: "CodeLlama Python Custom", 
      description: "Fine-tuned Python code generation model", 
      version: "v1.1.0",  
      accuracy: "93%", 
      trainingData: "800K samples", 
      updatedAt: "3 days ago", 
      dataset: "Python Code Examples",
      parameters: "7B",
      modelSize: "13GB", 
      tokenSpeed: "200 tokens/s",
      maxContext: "4K tokens",
      baseModel: "codellama/CodeLlama-7b-Python-hf",
      type: "Python Code Generation Model"
    },
    { 
      id: 3, 
      name: "Llama-3.2 Chat Custom", 
      description: "Fine-tuned chat model", 
      version: "v1.0.2", 
      accuracy: "94%", 
      trainingData: "2M+ samples", 
      updatedAt: "1 day ago", 
      dataset: "Conversation Dialogs",
      parameters: "3B",
      modelSize: "6GB",
      tokenSpeed: "250 tokens/s", 
      maxContext: "4K tokens",
      baseModel: "meta-llama/Llama-3.2-3B-Instruct",
      type: "Chat Model"
    }
  ];
  
  const publicModels = [
    { id: 1, name: "meta-llama/Llama-3.2-3B-Instruct", size: "3B parameters", type: "Instruction-tuned LLM" },
    { id: 2, name: "meta-llama/Llama-3.1-8B", size: "8B parameters", type: "Instruction-tuned LLM" },
    { id: 3, name: "nvidia/Llama3-ChatQA-2-8B", size: "8B parameters", type: "Chat QA Model" },
    { id: 4, name: "NousResearch/Llama-2-7b-chat-hf", size: "7B parameters", type: "Chat Model" },
    { id: 5, name: "codellama/CodeLlama-7b-hf", size: "7B parameters", type: "Code Generation Model" },
    { id: 6, name: "codellama/CodeLlama-7b-Python-hf", size: "7B parameters", type: "Python Code Generation Model" },
    { id: 7, name: "mistralai/Mistral-7B-Instruct-v0.3", size: "7B parameters", type: "Instruction-tuned LLM" },
    { id: 8, name: "mistralai/Mixtral-8x7B-Instruct-v0.1", size: "8x7B parameters", type: "Instruction-tuned LLM" },
    { id: 9, name: "FlagAlpha/Llama2-Chinese-13b-Chat", size: "13B parameters", type: "Chinese Chat Model" },
    { id: 10, name: "inceptionai/jais-13b", size: "13B parameters", type: "General Purpose Model" }
  ];
  
const steps = ['Select Model', 'Review Model', 'Evaluate'];
const ChatInterface = ({ model, onClose }) => {
  const [message, setMessage] = useState('');
  const [chat, setChat] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getMockResponse = (message) => {
    const responses = [
      "That's an interesting question! Let me think about it...",
      "Based on my training, I would say...",
      "I understand your query. Here's what I think...",
      "That's a great point! Here's my perspective...",
    ];
    return responses[Math.floor(Math.random() * responses.length)] + 
           " [Mock response to: " + message + "]";
  };

  const handleSend = async () => {
    if (!message.trim() || isLoading) return;
    
    const userMessage = message;
    setMessage('');
    setChat(prev => [...prev, { role: 'user', content: userMessage }]);
    
    setIsLoading(true);
    setTimeout(() => {
      setChat(prev => [...prev, { 
        role: 'assistant', 
        content: getMockResponse(userMessage)
      }]);
      setIsLoading(false);
    }, 1000);
  };

  return (
    <Box sx={{ mt: 3, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Human Evaluation - {model?.name}
      </Typography>
      
      <Box sx={{ height: '300px', overflow: 'auto', mb: 2 }}>
        {chat.map((msg, i) => (
          <Box 
            key={i}
            sx={{ 
              mb: 1,
              textAlign: msg.role === 'user' ? 'right' : 'left',
              '& > div': {
                display: 'inline-block',
                maxWidth: '70%',
                p: 1,
                borderRadius: 1,
                bgcolor: msg.role === 'user' ? 'primary.main' : 'background.default'
              }
            }}
          >
            <div>
              <Typography variant="body2">{msg.content}</Typography>
            </div>
          </Box>
        ))}
      </Box>

      <Box sx={{ display: 'flex', gap: 1 }}>
        <TextField
          fullWidth
          size="small"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message..."
          disabled={isLoading}
          onKeyPress={(e) => e.key === 'Enter' && handleSend()}
        />
        <IconButton 
          onClick={handleSend} 
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
        </IconButton>
      </Box>
    </Box>
  );
};

const HumanEvaluation = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedModel, setSelectedModel] = useState(null);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleModelSelect = (model, source) => {
    setSelectedModel({ ...model, source });
    handleNext();
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Box sx={{ 
              maxHeight: '50vh',
              overflowY: 'auto',
              pr: 2,
            }}>
              <Grid container spacing={3}>
                {/* My Models Section */}
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>My Models</Typography>
                  <Grid container spacing={2}>
                    {myModels.map(model => (
                      <Grid item xs={4} key={`my-${model.id}`}>
                        <ModelCard
                          model={model}
                          selected={selectedModel?.id === model.id && selectedModel?.source === 'my'}
                          onSelect={(model) => handleModelSelect(model, 'my')}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>

                {/* Public Models Section */}
                <Grid item xs={12} sx={{ mt: 4 }}>
                  <Typography variant="subtitle1" gutterBottom>Public Models</Typography>
                  <Grid container spacing={2}>
                    {publicModels.map(model => (
                      <Grid item xs={4} key={`public-${model.id}`}>
                        <ModelCard
                          model={model}
                          selected={selectedModel?.id === model.id && selectedModel?.source === 'public'}
                          onSelect={(model) => handleModelSelect(model, 'public')}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        );
      case 1:
        return (
          <Card>
            <CardContent>
              <Typography variant="h6">Selected Model</Typography>
              <Typography variant="body1">{selectedModel?.name}</Typography>
              <Typography variant="body2" color="text.secondary">
                {selectedModel?.type} • {selectedModel?.size}
              </Typography>
              <Button 
                variant="contained" 
                onClick={handleNext}
                sx={{ mt: 2 }}
              >
                Start Human Evaluation
              </Button>
            </CardContent>
          </Card>
        );
      case 2:
        return (
          <ChatInterface 
            model={selectedModel}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Box sx={{ }}>      
      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box sx={{ mt: 2 }}>
        {getStepContent(activeStep)}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
      </Box>
    </Box>
  );
};

function ModelCard({ model, selected, onSelect }) {
  return (
    <Card 
      sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        border: selected ? 2 : 1,
        borderColor: selected ? 'primary.main' : 'divider',
        cursor: 'pointer'
      }}
      onClick={() => onSelect(model)}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h6" component="div" gutterBottom>
          {model.name}
        </Typography>
        
        <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          <Chip 
            size="small"
            label={model.size || `${model.parameters} parameters`}
            color="primary"
          />
          {model.type && (
            <Chip 
              size="small"
              label={model.type}
              variant="outlined"
            />
          )}
        </Box>

        {model.description && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            {model.description}
          </Typography>
        )}
      </CardContent>
      
      <Box sx={{ p: 2, pt: 0 }}>
        <Checkbox 
          checked={selected}
          onChange={(e) => e.stopPropagation()}
          onClick={(e) => {
            e.stopPropagation();
            onSelect(model);
          }}
        />
      </Box>
    </Card>
  );
}

export default HumanEvaluation;
