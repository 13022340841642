import React from 'react';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import countriesData from './countries.json';

const geoUrl = require('./world-countries.json');

export default function MapChart({ servers }) {
  // Create a lookup map for country coordinates
  const countryCoordinates = Object.fromEntries(
    countriesData.map(country => [
      country.key,
      [country.longitude, country.latitude]
    ])
  );

  // Map servers with coordinates from the countries data
  const serversWithCoordinates = servers.map(server => ({
    ...server,
    coordinates: countryCoordinates[server.location] || [0, 0] // Fallback to [0,0] if country not found
  }));

  return (
    <ComposableMap
      projection="geoMercator"
      projectionConfig={{
        scale: 400,
        center: [-25, 50]
      }}
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#1a1a1a"
      }}
    >
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map((geo) => {
            // Check if there's a server in this country
            const hasServer = servers.some(
              server => server.location === geo.properties.ISO_A2
            );
            
            return (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill={hasServer ? "#2C5282" : "#2C2C2C"}
                stroke="#404040"
                strokeWidth={0.5}
                style={{
                  default: { outline: 'none' },
                  hover: { 
                    outline: 'none', 
                    fill: hasServer ? "#3182CE" : "#3C3C3C" 
                  },
                  pressed: { outline: 'none' }
                }}
              />
            );
          })
        }
      </Geographies>
      
      {serversWithCoordinates.map((server) => (
        <Marker key={server.id} coordinates={server.coordinates}>
          <g
            fill={server.status === "Online" ? "#4CAF50" : "#F44336"}
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-12, -24)"
          >
            <circle cx="12" cy="10" r="3" />
            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
          </g>
          <text
            textAnchor="middle"
            y={-30}
            style={{
              fontFamily: "system-ui",
              fill: "#FFFFFF",
              fontSize: "20px", // Increased font size for server name
              fontWeight: "bold",
              textShadow: "0 0 5px rgba(0,0,0,0.5)"
            }}
          >
            {server.hostname}
          </text>
        </Marker>
      ))}
    </ComposableMap>
  );
}
