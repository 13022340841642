import React, { useState } from 'react';
import {
  Box, Typography, Stepper, Step, StepLabel, Button,
  Card, CardContent, Grid, Chip, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox
} from '@mui/material';

const steps = ['Select Model', 'Select Dataset', 'Review Selections', 'Results'];


// Mock evaluation results
const mockResults = [
  { task: 'lambada_openai', version: '1', filter: 'none', nShot: '0', metric: 'acc', value: '0.7993', stderr: '0.0056', better: '↑' },
  { task: 'lambada_openai', version: '1', filter: 'none', nShot: '0', metric: 'perplexity', value: '2.5794', stderr: '0.0423', better: '↓' },
];

const myModels = [
    { 
      id: 1, 
      name: "Mistral-7B-Instruct Custom", 
      description: "Fine-tuned instruction model", 
      version: "v1.0.1", 
      accuracy: "92%", 
      trainingData: "1M+ samples", 
      updatedAt: "2 days ago", 
      dataset: "Customer Support Conversations",
      parameters: "7B",
      modelSize: "14GB",
      tokenSpeed: "180 tokens/s",
      maxContext: "8K tokens",
      baseModel: "mistralai/Mistral-7B-Instruct-v0.3",
      type: "Instruction-tuned LLM"
    },
    { 
      id: 2, 
      name: "CodeLlama Python Custom", 
      description: "Fine-tuned Python code generation model", 
      version: "v1.1.0",  
      accuracy: "93%", 
      trainingData: "800K samples", 
      updatedAt: "3 days ago", 
      dataset: "Python Code Examples",
      parameters: "7B",
      modelSize: "13GB", 
      tokenSpeed: "200 tokens/s",
      maxContext: "4K tokens",
      baseModel: "codellama/CodeLlama-7b-Python-hf",
      type: "Python Code Generation Model"
    },
    { 
      id: 3, 
      name: "Llama-3.2 Chat Custom", 
      description: "Fine-tuned chat model", 
      version: "v1.0.2", 
      accuracy: "94%", 
      trainingData: "2M+ samples", 
      updatedAt: "1 day ago", 
      dataset: "Conversation Dialogs",
      parameters: "3B",
      modelSize: "6GB",
      tokenSpeed: "250 tokens/s", 
      maxContext: "4K tokens",
      baseModel: "meta-llama/Llama-3.2-3B-Instruct",
      type: "Chat Model"
    }
  ];
  
  const publicModels = [
    { id: 1, name: "meta-llama/Llama-3.2-3B-Instruct", size: "3B parameters", type: "Instruction-tuned LLM" },
    { id: 2, name: "meta-llama/Llama-3.1-8B", size: "8B parameters", type: "Instruction-tuned LLM" },
    { id: 3, name: "nvidia/Llama3-ChatQA-2-8B", size: "8B parameters", type: "Chat QA Model" },
    { id: 4, name: "NousResearch/Llama-2-7b-chat-hf", size: "7B parameters", type: "Chat Model" },
    { id: 5, name: "codellama/CodeLlama-7b-hf", size: "7B parameters", type: "Code Generation Model" },
    { id: 6, name: "codellama/CodeLlama-7b-Python-hf", size: "7B parameters", type: "Python Code Generation Model" },
    { id: 7, name: "mistralai/Mistral-7B-Instruct-v0.3", size: "7B parameters", type: "Instruction-tuned LLM" },
    { id: 8, name: "mistralai/Mixtral-8x7B-Instruct-v0.1", size: "8x7B parameters", type: "Instruction-tuned LLM" },
    { id: 9, name: "FlagAlpha/Llama2-Chinese-13b-Chat", size: "13B parameters", type: "Chinese Chat Model" },
    { id: 10, name: "inceptionai/jais-13b", size: "13B parameters", type: "General Purpose Model" }
  ];

function ModelCard({ model, selected, onSelect }) {
  return (
    <Card 
      sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        border: selected ? 2 : 1,
        borderColor: selected ? 'primary.main' : 'divider',
        cursor: 'pointer'
      }}
      onClick={() => onSelect(model)}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h6" component="div" gutterBottom>
          {model.name}
        </Typography>
        
        <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          <Chip 
            size="small"
            label={model.size || `${model.parameters} parameters`}
            color="primary"
          />
          {model.type && (
            <Chip 
              size="small"
              label={model.type}
              variant="outlined"
            />
          )}
        </Box>

        {model.description && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            {model.description}
          </Typography>
        )}
      </CardContent>
      
      <Box sx={{ p: 2, pt: 0 }}>
        <Checkbox 
          checked={selected}
          onChange={(e) => e.stopPropagation()}
          onClick={(e) => {
            e.stopPropagation();
            onSelect(model);
          }}
        />
      </Box>
    </Card>
  );
}

function DatasetCard({ dataset, selected, onSelect }) {
  return (
    <Card 
      sx={{ 
        height: '100%',
        border: selected ? 2 : 1,
        borderColor: selected ? 'primary.main' : 'divider',
        cursor: 'pointer'
      }}
      onClick={() => onSelect(dataset)}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom>{dataset.name}</Typography>
        <Box sx={{ mt: 1, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          <Chip size="small" label={`${dataset.size} samples`} color="primary" />
          <Chip size="small" label={dataset.type} variant="outlined" />
        </Box>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
          {dataset.description}
        </Typography>
      </CardContent>
    </Card>
  );
}

function ResultsTable({ results }) {
  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Task</TableCell>
            <TableCell>Version</TableCell>
            <TableCell>Filter</TableCell>
            <TableCell>n-shot</TableCell>
            <TableCell>Metric</TableCell>
            <TableCell align="right">Value</TableCell>
            <TableCell align="right">Stderr</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.task}</TableCell>
              <TableCell>{row.version}</TableCell>
              <TableCell>{row.filter}</TableCell>
              <TableCell>{row.nShot}</TableCell>
              <TableCell>{row.metric} {row.better}</TableCell>
              <TableCell align="right">{row.value}</TableCell>
              <TableCell align="right">±{row.stderr}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const AutoEvaluation = ({ myModels, publicModels, datasets }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [evaluationStarted, setEvaluationStarted] = useState(false);

  const handleNext = () => {
    if (activeStep === 2) {
      setEvaluationStarted(true);
    }
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleModelSelect = (model, source) => {
    setSelectedModel({ ...model, source });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Box sx={{ 
              maxHeight: '50vh',
              overflowY: 'auto',
              pr: 2,
            }}>
              <Grid container spacing={3}>
                {/* My Models Section */}
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>My Models</Typography>
                  <Grid container spacing={2}>
                    {myModels.map(model => (
                      <Grid item xs={4} key={`my-${model.id}`}>
                        <ModelCard
                          model={model}
                          selected={selectedModel?.id === model.id && selectedModel?.source === 'my'}
                          onSelect={(model) => handleModelSelect(model, 'my')}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>

                {/* Public Models Section */}
                <Grid item xs={12} sx={{ mt: 4 }}>
                  <Typography variant="subtitle1" gutterBottom>Public Models</Typography>
                  <Grid container spacing={2}>
                    {publicModels.map(model => (
                      <Grid item xs={4} key={`public-${model.id}`}>
                        <ModelCard
                          model={model}
                          selected={selectedModel?.id === model.id && selectedModel?.source === 'public'}
                          onSelect={(model) => handleModelSelect(model, 'public')}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        );
      case 1:
        return (
          <Grid container spacing={3}>
            {datasets.map((dataset) => (
              <Grid item xs={12} sm={6} md={4} key={dataset.id}>
                <DatasetCard
                  dataset={dataset}
                  selected={selectedDataset?.id === dataset.id}
                  onSelect={setSelectedDataset}
                />
              </Grid>
            ))}
          </Grid>
        );
      case 2:
        return (
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Evaluation Summary</Typography>
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1">Selected Model</Typography>
                <Typography>{selectedModel?.name}</Typography>
                <Typography variant="subtitle1" sx={{ mt: 2 }}>Selected Dataset</Typography>
                <Typography>{selectedDataset?.name}</Typography>
              </Box>
              <Button 
                variant="contained" 
                onClick={handleNext}
                sx={{ mt: 3 }}
              >
                Start Auto Evaluation
              </Button>
            </CardContent>
          </Card>
        );
      case 3:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>Evaluation Results</Typography>
            <Typography variant="subtitle1" gutterBottom>
              Model: {selectedModel?.name}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Dataset: {selectedDataset?.name}
            </Typography>
            <ResultsTable results={mockResults} />
          </Box>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Box sx={{ }}>      
      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box sx={{ mt: 2 }}>
        {getStepContent(activeStep)}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 2 }}>
        <Button
          disabled={activeStep === 0 || activeStep === 3}
          onClick={handleBack}
        >
          Back
        </Button>
        {activeStep < 2 && (
          <Button
            variant="contained"
            onClick={handleNext}
            disabled={
              (activeStep === 0 && !selectedModel) ||
              (activeStep === 1 && !selectedDataset)
            }
          >
            Next
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AutoEvaluation;
