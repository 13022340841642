import React, { lazy, Suspense, useState } from 'react';
import { Toolbar, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, Box, IconButton, CircularProgress } from '@mui/material';
import { menuItems, bottomMenuItems, appHighlightItems } from './MenuItems';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useAuth } from './authentication/AuthContext';

// Lazy load the No-Code AI App

export default function DrawerContent({ isCollapsed, onToggleCollapse }) {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const location = useLocation();

  const handleItemClick = (item) => {
    if (item.action === 'logout') {
      logout();
      navigate('/');
    } else if (item.action && typeof item.action === 'function') {
      // Execute the action function directly
      item.action();
    } else if (item.path) {
      navigate(item.path);
    }
  };

  const renderMenuItems = (items) => (
    items.map((item) => {
      if (item.type === 'section') {
        return (
          <Box key={item.label} sx={{ px: 2, py: 1 }}>
            {!isCollapsed && (
              <Typography 
                variant="caption" 
                color="text.secondary"
                sx={{ 
                  textTransform: 'uppercase',
                  fontWeight: 500,
                  letterSpacing: '0.1em'
                }}
              >
                {item.label}
              </Typography>
            )}
          </Box>
        );
      }

      return (
        <ListItem key={item.label} disablePadding sx={{ mb: 1 }}>
          <ListItemButton
            onClick={() => handleItemClick(item)}
            sx={{
              borderRadius: 2,
              backgroundColor: location.pathname === item.path ? 'grey.800' : 'transparent',
            }}
          >
            <ListItemIcon sx={{ color: '#50C878' }}>
              <item.icon />
            </ListItemIcon>
            {!isCollapsed && <ListItemText 
              primary={item.label} 
              sx={{
                ...(item.label === 'No-Code AI' && {
                  color: '#50C878',
                  fontWeight: 'normal',
                })
              }}
            />}
          </ListItemButton>
        </ListItem>
      );
    })
  );

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      height: '100%',
      overflowX: 'hidden'
    }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {!isCollapsed && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src="../../images/pv_white.png" alt="Piosphere Logo" style={{ height: '40px' }} />
            <Typography variant="h6" component="div" sx={{ ml: 1 }}>
              Piosphere
            </Typography>
          </Box>
        )}
        <IconButton 
          onClick={onToggleCollapse} 
          sx={{ 
            ...(isCollapsed ? {
              ml: 0,
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)'
            } : {
              ml: 'auto',
              transform: 'translateX(+10%)'
            })
          }}
        >
          {isCollapsed ? <MenuIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </Toolbar>
      <Divider />
      <List sx={{ flexGrow: 1, overflow: 'auto', overflowX: 'hidden' }}>
        {renderMenuItems(menuItems)}
        <Box sx={{ 
          position: 'relative', 
          pt: 3, // Reduced vertical padding to reduce margin between divider and app highlight items
          my: 1 // Reduced vertical margin to reduce margin between divider and app highlight items
        }}>
          {!isCollapsed && (
            <Divider sx={{
              '&::before, &::after': {
                borderColor: 'primary.main',
              },
            }}>
              <Typography
                variant="caption"
                sx={{
                  color: 'primary.main',
                  backgroundColor: 'background.paper',
                  px: 1,
                  textTransform: 'uppercase',
                  fontWeight: 600,
                  letterSpacing: '0.1em',
                  fontSize: '0.8rem' // Increased font size
                }}
              >
                Highlights
              </Typography>
            </Divider>
          )}
        </Box>
        {renderMenuItems(appHighlightItems.filter(item => item.type !== 'section'))}
      </List>
      <Divider />
      <List sx={{ overflowX: 'hidden' }}>
        {renderMenuItems(bottomMenuItems)}
      </List>
    </Box>
  );
}
