import React, { useState, useRef, useEffect } from 'react';
import { Box, TextField, Button, Typography, Paper, List, ListItem, ListItemText, ThemeProvider, createTheme, FormControl, Select, MenuItem } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import StorageIcon from '@mui/icons-material/Storage';
import MemoryIcon from '@mui/icons-material/Memory';
import CircularProgress from '@mui/material/CircularProgress';
import { pioChatBot } from '../api/services/vcai/Vcai';
import { useAuth } from '../authentication/AuthContext';
import { theme } from '../theme';
import { getUserInventory } from '../api/services/userService/Inventory';

const suggestions = [
  'Can you explain me the concept of what PioCloud and No-Code AI are?',
  'Write a poem about the changing seasons, incorporating sensory details and metaphors',
  'Explain the principles of quantum physics in simple terms, using analogies and examples to illustrate key concepts',
];

export default function PioChat() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [hasTyped, setHasTyped] = useState(false);
  const [selectedServer, setSelectedServer] = useState('');
  const { keycloak } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const [servers, setServers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchServers = async () => {
      try {
        await keycloak.updateToken(30);
        setLoading(true);
        const response = await getUserInventory(keycloak.subject, keycloak.token);
        
        if (response.success) {
          const transformedServers = response.data.map(server => ({
            id: server.id,
            name: server.hostname,
            status: server.status === "active" ? "Online" : "Offline",
            gpuVendor: server.gpuType.toUpperCase(),
            gpuType: server.gpu,
            vram: server.mem
          }));
          
          setServers(transformedServers);
        } else {
          console.error('Failed to fetch servers:', response.error);
        }
      } catch (error) {
        console.error('Error fetching servers:', error);
        if (error.message?.includes('token')) {
          await keycloak.updateToken(30);
        }
      } finally {
        setLoading(false);
      }
    };

    if (keycloak.token) {
      fetchServers();
    }
  }, [keycloak.token, keycloak.subject]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSuggestionClick = (suggestion) => {
    setInput(suggestion);
    setHasTyped(true);
  };

  const handleSend = async () => {
    if (!selectedServer || input.trim() === '') return;
    
    const userMessage = input.trim();
    setMessages([...messages, { text: userMessage, sender: 'user' }]);
    setInput('');
    setIsLoading(true);

    try {
      await keycloak.updateToken(30);
      const token = keycloak.token;
      const response = await pioChatBot(userMessage, token);
      
      if (response.success) {
        setMessages(prevMessages => [...prevMessages, { 
          text: response.answer, 
          sender: 'ai' 
        }]);
      } else {
        setMessages(prevMessages => [...prevMessages, { 
          text: 'Sorry, I encountered an error processing your request.', 
          sender: 'ai' 
        }]);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prevMessages => [...prevMessages, { 
        text: 'Sorry, I encountered an error processing your request.', 
        sender: 'ai' 
      }]);
    } finally {
      setIsLoading(false);
      setTimeout(scrollToBottom, 100);
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    if (!hasTyped && e.target.value !== '') {
      setHasTyped(true);
    }
  };

  const handleServerChange = (event) => {
    setSelectedServer(event.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: 'background.default', height: '97vh', display: 'flex', flexDirection: 'column' }}>
        {!selectedServer ? (
          // Centered server selection screen
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center', 
            alignItems: 'center',
            height: '100%',
            gap: 3
          }}>
            <Typography variant="h3" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
              PioChat
            </Typography>
            <StorageIcon sx={{ fontSize: 48, color: theme.palette.primary.main }} />
            <Typography variant="h6" sx={{ color: 'text.secondary', mb: 2 }}>
              Select a server to start chatting
            </Typography>
            <FormControl sx={{ minWidth: 300 }}>
              <Select
                value={selectedServer}
                onChange={handleServerChange}
                displayEmpty
                sx={{ 
                  color: 'text.primary',
                  '.MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.primary.main },
                  '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.primary.main },
                }}
              >
                <MenuItem value="" disabled>Choose a server</MenuItem>
                {servers.map((server) => (
                  <MenuItem key={server.id} value={server.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <StorageIcon sx={{ fontSize: 20 }} />
                      <Box>
                        <Typography variant="body2">{server.name}</Typography>
                        <Typography variant="caption" color="text.secondary">
                          {server.gpuVendor} {server.gpuType} • {server.vram}GB VRAM
                        </Typography>
                      </Box>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ) : (
          // Chat interface when server is selected
          <>
            <Box sx={{ textAlign: 'center', py: 2, borderBottom: 1, borderColor: 'divider' }}>
              <Typography variant="h3" sx={{ color: 'primary.main', fontWeight: 'bold', mb: 1 }}>
                PioChat
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                <StorageIcon sx={{ color: theme.palette.primary.main }} />
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Connected to {servers.find(s => s.id === selectedServer)?.name}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ 
              flexGrow: 1, 
              overflow: 'auto', 
              p: 2, 
              maxWidth: '800px', 
              mx: 'auto', 
              width: '100%',
              scrollBehavior: 'smooth'
            }}>
              <List>
                {messages.map((message, index) => (
                  <ListItem 
                    key={index} 
                    sx={{ 
                      justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
                      mb: 2
                    }}
                  >
                    <Paper 
                      sx={{ 
                        p: 2, 
                        maxWidth: '70%', 
                        bgcolor: message.sender === 'user' ? theme.palette.primary.main : 'background.paper',
                        borderRadius: message.sender === 'user' ? '20px 20px 5px 20px' : '20px 20px 20px 5px',
                        boxShadow: 2,
                      }}
                    >
                      <ListItemText 
                        primary={message.text} 
                        sx={{ 
                          color: message.sender === 'user' ? '#000000' : 'text.primary',
                          '& .MuiListItemText-primary': { 
                            fontWeight: 400,
                            lineHeight: 1.6,
                          },
                        }} 
                      />
                    </Paper>
                  </ListItem>
                ))}
                {isLoading && (
                  <ListItem sx={{ justifyContent: 'flex-start' }}>
                    <Paper 
                      sx={{ 
                        p: 2, 
                        bgcolor: 'background.paper',
                        borderRadius: '20px 20px 20px 5px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                      }}
                    >
                      <CircularProgress size={20} />
                      <Typography variant="body2">PioChat is thinking...</Typography>
                    </Paper>
                  </ListItem>
                )}
                <div ref={messagesEndRef} />
              </List>
            </Box>
            <Box sx={{ p: 2, maxWidth: '800px', mx: 'auto', width: '100%' }}>
              {!hasTyped && selectedServer && (
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  mb: 2, 
                  flexWrap: 'wrap',
                  gap: 1
                }}>
                  {suggestions.map((suggestion, index) => (
                    <Button 
                      key={index} 
                      variant="outlined" 
                      size="small" 
                      onClick={() => handleSuggestionClick(suggestion)}
                      sx={{ 
                        m: 0.5, 
                        borderColor: theme.palette.primary.main, 
                        color: theme.palette.primary.main,
                        borderRadius: '20px',
                        px: 2,
                        py: 1,
                        '&:hover': {
                          borderColor: theme.palette.primary.main,
                          color: theme.palette.primary.main,
                          backgroundColor: `${theme.palette.primary.main}1A`,
                        }
                      }}
                    >
                      {suggestion}
                    </Button>
                  ))}
                </Box>
              )}
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                bgcolor: 'background.paper', 
                borderRadius: '12px', 
                p: 1,
                border: '1px solid rgba(255, 255, 255, 0.1)',
                '&:focus-within': {
                  border: `1px solid ${theme.palette.primary.main}`,
                }
              }}>
                <TextField
                  fullWidth
                  variant="standard"
                  placeholder={selectedServer ? "Message PioChat..." : "Select a server to start chatting"}
                  value={input}
                  onChange={handleInputChange}
                  onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                  disabled={!selectedServer || isLoading}
                  sx={{
                    '& .MuiInput-root': {
                      color: 'text.primary',
                      '&:before, &:after': { display: 'none' },
                    },
                    '& .MuiInput-input': {
                      p: 1,
                    },
                  }}
                />
                <Button
                  sx={{
                    minWidth: 'auto',
                    p: 1,
                    color: theme.palette.primary.main,
                    '&:hover': {
                      backgroundColor: `${theme.palette.primary.main}1A`,
                    },
                  }}
                  disabled={!selectedServer || isLoading}
                >
                </Button>
                <Button
                  onClick={handleSend}
                  disabled={!selectedServer || isLoading || !input.trim()}
                  sx={{
                    minWidth: 'auto',
                    p: 1,
                    color: input.trim() ? theme.palette.primary.main : 'text.primary',
                    '&:hover': {
                      backgroundColor: `${theme.palette.primary.main}1A`,
                    },
                  }}
                >
                  <SendIcon />
                </Button>
              </Box>
            </Box>
            <Typography variant="caption" sx={{ textAlign: 'center', py: 1, color: 'text.secondary' }}>
              PioChat can make mistakes. Always verify important information.
            </Typography>
            {isLoading && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
              </Box>
            )}
          </>
        )}
      </Box>
    </ThemeProvider>
  );
}
