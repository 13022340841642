import React, { useState, useRef } from 'react';
import { Box, TextField, Typography, Paper } from '@mui/material';

function VShell() {
    const [input, setInput] = useState('');
    const [output, setOutput] = useState([]);
    const inputRef = useRef(null);

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setOutput((prev) => [...prev, `user@machine:~$ ${input}`]);
            setInput('');
        }
    };

    const handleTerminalClick = () => {
        inputRef.current?.focus();
    };

    return (
        <Box 
            onClick={handleTerminalClick}
            sx={{
                backgroundColor: 'black',
                color: 'green',
                padding: 2,
                border: '1px solid #333',
                fontFamily: 'Courier New, Courier, monospace',
                height: '90%',
                minHeight: '500px',
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
                cursor: 'text'
            }}
        >
            <Paper 
                sx={{
                    flex: 1,
                    padding: 1,
                    backgroundColor: 'black',
                    overflowY: 'auto',
                }}
            >
                {output.map((line, index) => (
                    <Typography key={index} variant="body2">
                        {line}
                    </Typography>
                ))}
            </Paper>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" sx={{ marginRight: 1 }}>
                    user@machine:~$
                </Typography>
                <TextField
                    inputRef={inputRef}
                    variant="outlined"
                    size="small"
                    value={input}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    sx={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'green',
                        flex: 1,
                    }}
                    InputProps={{
                        sx: {
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                            '&:focus': {
                                border: 'none',
                            },
                            color: 'green',
                        },
                    }}
                />
            </Box>
        </Box>
    );
}

export default VShell;
