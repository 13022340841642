import React, { useEffect, useState } from 'react';
import {
  Typography, Card, CardContent, Grid, Box, CircularProgress, Alert,
  Divider, useTheme, Tabs, Tab
} from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  ResponsiveContainer, Area, AreaChart
} from 'recharts';
import {
  Timeline, Speed, Schedule, Update, 
  ShowChart, Psychology, TrendingDown, Analytics
} from '@mui/icons-material';

const TrainingJobMonitoring = () => {
  const theme = useTheme();
  const { jobId } = useParams();
  const [jobData, setJobData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [metricsHistory, setMetricsHistory] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    // Simulate fetching job data and historical metrics
    const fetchJobData = async () => {
      try {
        // Simulate historical data points
        const historicalData = Array.from({ length: 100 }, (_, i) => ({
          epoch: i,
          trainingLoss: 2.5 * Math.exp(-i / 20) + Math.random() * 0.2,
          validationLoss: 2.3 * Math.exp(-i / 22) + Math.random() * 0.2,
          learningRate: 0.0001 * Math.exp(-i / 40),
          perplexity: 100 * Math.exp(-i / 25) + Math.random() * 5,
          gpuUtilization: 75 + Math.random() * 20,
          gpuMemory: 85 + Math.random() * 10,
          gpuTemp: 70 + Math.random() * 15,
          tokensPerSecond: 12000 + Math.random() * 1000,
          gradientNorm: 1.2 + Math.random() * 0.3,
        }));

        setMetricsHistory(historicalData);

        // Current job status
        const data = {
          currentEpoch: 7,
          totalEpochs: 10,
          currentStep: 175000,
          totalSteps: 250000,
          elapsedTime: '16h 28m 33s',
          eta: '7h 03m',
          metrics: {
            trainingLoss: 2.847,
            validationLoss: 2.912,
            perplexity: 17.241,
            learningRate: 5.0e-5,
            gradientNorm: 1.873
          },
          resourceUtilization: {
            gpu: 96,
            memory: 15.4,
            temperature: 76,
            tokensPerSecond: 8432
          },
          status: 'Training in progress',
          lastUpdate: new Date().toISOString(),
          epochMetrics: [
            {
              epoch: 0,
              trainLoss: 4.892,
              valLoss: 4.927,
              perplexity: 133.72,
              learningRate: 1.0e-4,
              gradNorm: 4.231
            },
            {
              epoch: 1,
              trainLoss: 4.213,
              valLoss: 4.287,
              perplexity: 72.84,
              learningRate: 9.5e-5,
              gradNorm: 3.847
            },
            {
              epoch: 2, 
              trainLoss: 3.892,
              valLoss: 3.947,
              perplexity: 51.93,
              learningRate: 8.5e-5,
              gradNorm: 3.291
            },
            {
              epoch: 3,
              trainLoss: 3.584,
              valLoss: 3.642,
              perplexity: 38.21,
              learningRate: 7.5e-5,
              gradNorm: 2.873
            },
            {
              epoch: 4,
              trainLoss: 3.291,
              valLoss: 3.347,
              perplexity: 28.47,
              learningRate: 6.8e-5,
              gradNorm: 2.542
            },
            {
              epoch: 5,
              trainLoss: 3.102,
              valLoss: 3.184,
              perplexity: 24.12,
              learningRate: 6.0e-5,
              gradNorm: 2.231
            },
            {
              epoch: 6,
              trainLoss: 2.947,
              valLoss: 3.021,
              perplexity: 20.53,
              learningRate: 5.5e-5,
              gradNorm: 2.012
            },
            {
              epoch: 7,
              trainLoss: 2.847,
              valLoss: 2.912,
              perplexity: 17.24,
              learningRate: 5.0e-5,
              gradNorm: 1.873
            }
          ]
        };

        setJobData(data);
      } catch (err) {
        setError('Failed to fetch job data');
      } finally {
        setLoading(false);
      }
    };

    fetchJobData();
    // Set up polling every 30 seconds
    const interval = setInterval(fetchJobData, 30000);
    return () => clearInterval(interval);
  }, [jobId]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  const MetricsChart = ({ data, metrics, title, yAxisLabel, height = 300 }) => {
    const theme = useTheme();
    
    return (
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center',
          mb: 0,
          gap: 1,
          flexWrap: 'wrap'
        }}>
          {metrics.map((metric) => (
            <Box
              key={metric.key}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: metric.color,
                backgroundColor: `${metric.color}15`,
                px: 0,
                py: 0.5,
                borderRadius: 2,
              }}
            >
              <Box sx={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: metric.color }} />
              <Typography variant="body2">
                {metric.name} {metric.unit ? `(${metric.unit})` : ''}
              </Typography>
            </Box>
          ))}
        </Box>
        <ResponsiveContainer width="100%" height={height}>
          <LineChart 
            data={data} 
            margin={{ top: 10, right: 30, left: 70, bottom: 40 }}
          >
            <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.1} />
            <XAxis
              dataKey="epoch"
              label={{ 
                value: 'Epoch', 
                position: 'bottom', 
                offset: 20,
                style: { 
                  fill: theme.palette.text.primary,
                  fontSize: 14,
                  fontWeight: 500
                }
              }}
              tick={{ 
                fill: theme.palette.text.secondary,
                fontSize: 12
              }}
            />
            <YAxis 
              label={{ 
                value: yAxisLabel, 
                angle: -90, 
                position: 'insideLeft',
                offset: -60,
                style: { 
                  fill: theme.palette.text.primary,
                  fontSize: 14,
                  fontWeight: 500
                }
              }}
              tick={{ 
                fill: theme.palette.text.secondary,
                fontSize: 12
              }}
            />
            <Tooltip
              labelFormatter={(_, index) => `Epoch ${data[index]?.epoch}`}
              formatter={(value, name) => [
                `${parseFloat(value).toFixed(4)} ${metrics.find(m => m.name === name)?.unit || ''}`,
                name
              ]}
              contentStyle={{
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 8,
              }}
            />
            {metrics.map((metric) => (
              <Line
                key={metric.key}
                type="monotone"
                dataKey={metric.key}
                stroke={metric.color}
                name={metric.name}
                dot={false}
                strokeWidth={2}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </Box>
    );
  };

  const MetricBox = ({ icon, label, value, secondaryValue }) => (
    <Box sx={{ 
      textAlign: 'center',
      p: 2,
      backgroundColor: theme.palette.background.paper,
      borderRadius: 2,
      minWidth: 160,
      height: 100,
    }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        color: theme.palette.primary.main,
        mb: 1
      }}>
        {icon}
        <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.8rem' }}>
          {label}
        </Typography>
      </Box>
      <Typography variant="h4" sx={{ fontWeight: 'medium', mb: 0.5, fontSize: '1.2rem' }}>
        {value}
      </Typography>
      {secondaryValue && (
        <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.8rem' }}>
          of {secondaryValue}
        </Typography>
      )}
    </Box>
  );

  return (
    <Box sx={{ p: 3 }}>
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Dashboard Tabs">
        <Tab label="Dashboard" />
        <Tab label="Raw" />
      </Tabs>
      {tabIndex === 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box sx={{ 
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  justifyContent: 'space-between',
                  gap: 2
                }}>
                  <Box>
                    <Typography variant="h5" sx={{ mb: 1, color: theme.palette.primary.main }}>
                      Training Job Monitor
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      ID: {jobId}
                    </Typography>
                  </Box>
                  <Box sx={{ 
                    display: 'flex',
                    gap: 2,
                    flexWrap: 'wrap',
                    justifyContent: 'center'
                  }}>
                    <MetricBox 
                      icon={<Timeline />}
                      label="Epoch"
                      value={jobData?.currentEpoch}
                      secondaryValue={jobData?.totalEpochs}
                    />
                    <MetricBox 
                      icon={<ShowChart />}
                      label="Step"
                      value={jobData?.currentStep?.toLocaleString()}
                      secondaryValue={jobData?.totalSteps?.toLocaleString()}
                    />
                    <MetricBox 
                      icon={<Speed />}
                      label="Speed"
                      value={`${Math.round(jobData?.resourceUtilization?.tokensPerSecond / 1000)}k`}
                      secondaryValue="tokens/sec"
                    />
                    <MetricBox 
                      icon={<Schedule />}
                      label="Elapsed"
                      value={jobData?.elapsedTime}
                    />
                    <MetricBox 
                      icon={<Update />}
                      label="ETA"
                      value={jobData?.eta}
                    />
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card sx={{ height: 350 }}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
                  <TrendingDown sx={{ color: theme.palette.primary.main }} />
                  <Typography variant="h6">Loss Metrics</Typography>
                </Box>
                <MetricsChart
                  data={metricsHistory}
                  metrics={[
                    { key: 'trainingLoss', name: 'Training Loss', color: theme.palette.primary.main, unit: 'nats' },
                    { key: 'validationLoss', name: 'Validation Loss', color: theme.palette.secondary.main, unit: 'nats' },
                  ]}
                  yAxisLabel="Loss (nats)"
                  height={250}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card sx={{ height: 350 }}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
                  <Psychology sx={{ color: theme.palette.primary.main }} />
                  <Typography variant="h6">Perplexity</Typography>
                </Box>
                <MetricsChart
                  data={metricsHistory}
                  metrics={[
                    { key: 'validationLoss', name: 'Validation Loss', color: theme.palette.primary.main, unit: 'nats' },
                    { key: 'trainingLoss', name: 'Training Loss', color: theme.palette.secondary.main, unit: 'nats' },
                  ]}
                  yAxisLabel="Loss (nats)"
                  height={250}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card sx={{ height: 350 }}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
                  <ShowChart sx={{ color: theme.palette.primary.main }} />
                  <Typography variant="h6">Learning Rate</Typography>
                </Box>
                <MetricsChart
                  data={metricsHistory}
                  metrics={[
                    { key: 'learningRate', name: 'Learning Rate', color: theme.palette.primary.main },
                  ]}
                  yAxisLabel="Learning Rate"
                  height={250}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card sx={{ height: 350 }}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
                  <Analytics sx={{ color: theme.palette.primary.main }} />
                  <Typography variant="h6">Gradient Norm</Typography>
                </Box>
                <MetricsChart
                  data={metricsHistory}
                  metrics={[
                    { key: 'gradientNorm', name: 'Gradient Norm', color: theme.palette.primary.main, unit: '||g||' },
                  ]}
                  yAxisLabel="Gradient Norm"
                  height={250}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      {tabIndex === 1 && (
        <Box sx={{ 
          p: 2, 
          backgroundColor: theme.palette.background.paper, 
          borderRadius: 2, 
          height: 800, 
          overflow: 'auto', 
          fontFamily: 'monospace', 
          color: theme.palette.text.primary 
        }}>
          <pre>
            {JSON.stringify(jobData, null, 2)}
          </pre>
        </Box>
      )}
    </Box>
  );
};

export default TrainingJobMonitoring;
