import React, { useState } from 'react';
import { Box, Typography, Button, Grid, Paper, Chip, Divider } from '@mui/material';
import AddInstance from '../paas/AddInstance';
import VShell from '../paas/VShell';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

const PaaS = () => {
    // Initialize with mock platforms including configuration details
    const [platforms, setPlatforms] = useState([
        {
            name: 'PioShell-8C-32GB-MI250x2',
            type: 'Container',
            ui: 'JupyterLab',
            config: {
                cpuCores: '8',
                ram: '32',
                storage: '2048',
                gpuType: 'MI250',
                gpuCount: '2'
            },
            status: 'running'
        },
        {
            name: 'PioShell-16C-64GB-H100x4',
            type: 'Container',
            ui: 'SSH',
            config: {
                cpuCores: '16',
                ram: '64',
                storage: '4096',
                gpuType: 'H100',
                gpuCount: '4'
            },
            status: 'stopped'
        },
        {
            name: 'PioShell-4C-16GB-H200',
            type: 'VM',
            ui: 'SSH',
            config: {
                cpuCores: '4',
                ram: '16',
                storage: '256',
                gpuType: 'H200',
                gpuCount: '0'
            },
            status: 'running'
        }
    ]);

    const [showAddInstance, setShowAddInstance] = useState(false);
    const [selectedPlatform, setSelectedPlatform] = useState(null);
    const [showShell, setShowShell] = useState(false);
    const [expandedPlatform, setExpandedPlatform] = useState(null);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const handleAddPlatform = (newPlatform) => {
        setPlatforms((prev) => [...prev, newPlatform]);
        setShowAddInstance(false);
    };

    const handlePlatformClick = (platform) => {
        setSelectedPlatform(platform);
    };

    const getStatusColor = (status) => {
        return status === 'running' ? '#4caf50' : '#ff9800';
    };

    const handleExpandClick = (platform) => {
        setExpandedPlatform(expandedPlatform === platform ? null : platform);
    };

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };

    return (
        <Box sx={{ padding: 3 }}>
            {/* Connection Status Message */}
            {selectedPlatform && (
                <Typography variant="h6" sx={{ mb: 2, color: '#4caf50' }}>
                    Connected to: {selectedPlatform.name}
                </Typography>
            )}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                        <Typography variant="h4" gutterBottom>
                            Platform Dashboard
                        </Typography>
                        <Button 
                            variant="contained" 
                            onClick={() => setShowAddInstance(true)}
                            sx={{ borderRadius: 2 }}
                        >
                            Add Platform
                        </Button>
                    </Box>
                </Grid>
                {/* VShell Section */}
                {showShell && selectedPlatform && (
                    <Grid item xs={isFullScreen ? 12 : 6} sx={{ 
                        transition: 'all 0.3s ease',
                        position: 'sticky',
                        top: '0px',
                        height: isFullScreen ? '80vh' : '650px',
                    }}>
                        <Paper sx={{ 
                            height: '100%',
                            bgcolor: 'background.paper',
                            padding: 2
                        }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                    <Button 
                                        size="small"
                                        variant="outlined"
                                        onClick={toggleFullScreen}
                                        sx={{
                                            minWidth: '40px',
                                            width: '40px',
                                            height: '40px',
                                            padding: 0
                                        }}
                                    >
                                        {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                                    </Button>
                                    <Typography variant="h6">Terminal - {selectedPlatform.name}</Typography>
                                </Box>
                                <Button 
                                    size="small" 
                                    onClick={() => setShowShell(false)}
                                >
                                    Close
                                </Button>
                            </Box>
                            <VShell platform={selectedPlatform} />
                        </Paper>
                    </Grid>
                )}
                {/* Platform Cards */}
                <Grid item xs={isFullScreen ? 12 : showShell ? 6 : 12}>
                    <Grid container spacing={2}>
                        {platforms.map((platform, index) => (
                            <Grid item xs={12} md={showShell ? 12 : 6} lg={showShell ? 12 : 4} key={index}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        transition: 'transform 0.2s',
                                        // Add conditional border color based on selected platform
                                        border: selectedPlatform === platform ? '2px solid #4caf50' : 'none',
                                        '&:hover': {
                                            transform: 'translateY(-2px)',
                                            boxShadow: 3
                                        }
                                    }}
                                >
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                        <Typography variant="h6">{platform.name}</Typography>
                                        <Chip 
                                            label={platform.status}
                                            size="small"
                                            sx={{ 
                                                backgroundColor: getStatusColor(platform.status),
                                                color: 'white'
                                            }}
                                        />
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                    <Grid container spacing={1} sx={{ flexGrow: 1 }}>
                                        <Grid item xs={12}>
                                            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                                <Chip label={`Type: ${platform.type}`} size="small" />
                                                <Chip label={`UI: ${platform.ui}`} size="small" />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" color="text.secondary">
                                                CPU: {platform.config.cpuCores} cores | RAM: {platform.config.ram}GB | Storage: {platform.config.storage}GB
                                            </Typography>
                                            {platform.config.gpuType && (
                                                <Typography variant="body2" color="text.secondary">
                                                    GPU: {platform.config.gpuCount}x {platform.config.gpuType}
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() => {
                                                        setSelectedPlatform(platform);
                                                        setShowShell(true);
                                                    }}
                                                >
                                                    Connect to Shell
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={() => handleExpandClick(platform)}
                                                >
                                                    {expandedPlatform === platform ? 'Hide SSH Keys' : 'Show SSH Keys'}
                                                    <ExpandMoreIcon sx={{ ml: 1 }} />
                                                </Button>
                                            </Box>
                                        </Grid>
                                        {expandedPlatform === platform && (
                                            <Grid item xs={12}>
                                                <Paper sx={{ p: 2, mt: 1, bgcolor: '#1a1a1a' }}>
                                                    <Typography variant="subtitle2" gutterBottom sx={{ color: '#fff' }}>
                                                        SSH Connection Details
                                                    </Typography>
                                                    <Typography variant="body2" component="pre" 
                                                        sx={{ 
                                                            bgcolor: '#333', // Darker background for better contrast
                                                            color: '#fff', // White text for readability
                                                            p: 1, 
                                                            borderRadius: 1,
                                                            overflowX: 'auto'
                                                        }}
                                                    >
                                                        ssh user@{platform.name.toLowerCase().replace(/\s+/g, '-')}.example.com
                                                    </Typography>
                                                    <Typography variant="subtitle2" gutterBottom sx={{ mt: 2, color: '#fff' }}>
                                                        SSH Key
                                                    </Typography>
                                                    <Typography variant="body2" component="pre" 
                                                        sx={{ 
                                                            bgcolor: '#333', // Darker background for better contrast
                                                            color: '#fff', // White text for readability
                                                            p: 1, 
                                                            borderRadius: 1,
                                                            overflowX: 'auto'
                                                        }}
                                                    >
                                                        ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAABAQ... user@platform
                                                    </Typography>
                                                </Paper>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>

            {showAddInstance && (
                <Box sx={{ 
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    width: '800px',
                    height: '100vh',
                    borderRadius: 2,
                    overflow: 'hidden'
                }}>
                    <AddInstance onAddPlatform={handleAddPlatform} onClose={() => setShowAddInstance(false)} />
                </Box>
            )}
        </Box>
    );
};

export default PaaS;
