// source: monitoring_service/monitoring_messages.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var common_main_pb = require('../common/main_pb.js');
goog.object.extend(proto, common_main_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
goog.exportSymbol('proto.protos.AcknowledgeResponse', null, global);
goog.exportSymbol('proto.protos.Anomaly', null, global);
goog.exportSymbol('proto.protos.AnomalyDetectionRequest', null, global);
goog.exportSymbol('proto.protos.AnomalyDetectionResponse', null, global);
goog.exportSymbol('proto.protos.CapacityForecast', null, global);
goog.exportSymbol('proto.protos.CapacityForecastRequest', null, global);
goog.exportSymbol('proto.protos.CapacityForecastResponse', null, global);
goog.exportSymbol('proto.protos.GetAllServersMetricsRequest', null, global);
goog.exportSymbol('proto.protos.GetAllServersMetricsResponse', null, global);
goog.exportSymbol('proto.protos.GetServerMetricsRequest', null, global);
goog.exportSymbol('proto.protos.GetServerMetricsResponse', null, global);
goog.exportSymbol('proto.protos.HealthCheckRequest', null, global);
goog.exportSymbol('proto.protos.HealthCheckResponse', null, global);
goog.exportSymbol('proto.protos.HistoricalMetricsRequest', null, global);
goog.exportSymbol('proto.protos.HistoricalMetricsResponse', null, global);
goog.exportSymbol('proto.protos.Metric', null, global);
goog.exportSymbol('proto.protos.MetricsRequest', null, global);
goog.exportSymbol('proto.protos.MetricsResponse', null, global);
goog.exportSymbol('proto.protos.ProcessDataRequest', null, global);
goog.exportSymbol('proto.protos.ProcessDataResponse', null, global);
goog.exportSymbol('proto.protos.ServerMetrics', null, global);
goog.exportSymbol('proto.protos.StartMonitoringRequest', null, global);
goog.exportSymbol('proto.protos.ThresholdPredictionRequest', null, global);
goog.exportSymbol('proto.protos.ThresholdPredictionResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.GetServerMetricsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.GetServerMetricsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.GetServerMetricsRequest.displayName = 'proto.protos.GetServerMetricsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.GetServerMetricsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.GetServerMetricsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.GetServerMetricsResponse.displayName = 'proto.protos.GetServerMetricsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.GetAllServersMetricsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.GetAllServersMetricsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.GetAllServersMetricsRequest.displayName = 'proto.protos.GetAllServersMetricsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.ServerMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.ServerMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.ServerMetrics.displayName = 'proto.protos.ServerMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.GetAllServersMetricsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protos.GetAllServersMetricsResponse.repeatedFields_, null);
};
goog.inherits(proto.protos.GetAllServersMetricsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.GetAllServersMetricsResponse.displayName = 'proto.protos.GetAllServersMetricsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.MetricsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.MetricsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.MetricsRequest.displayName = 'proto.protos.MetricsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.MetricsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.MetricsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.MetricsResponse.displayName = 'proto.protos.MetricsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.ProcessDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.ProcessDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.ProcessDataRequest.displayName = 'proto.protos.ProcessDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.ProcessDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.ProcessDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.ProcessDataResponse.displayName = 'proto.protos.ProcessDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.HealthCheckRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protos.HealthCheckRequest.repeatedFields_, null);
};
goog.inherits(proto.protos.HealthCheckRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.HealthCheckRequest.displayName = 'proto.protos.HealthCheckRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.HealthCheckResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protos.HealthCheckResponse.repeatedFields_, null);
};
goog.inherits(proto.protos.HealthCheckResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.HealthCheckResponse.displayName = 'proto.protos.HealthCheckResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.HistoricalMetricsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.HistoricalMetricsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.HistoricalMetricsRequest.displayName = 'proto.protos.HistoricalMetricsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.HistoricalMetricsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protos.HistoricalMetricsResponse.repeatedFields_, null);
};
goog.inherits(proto.protos.HistoricalMetricsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.HistoricalMetricsResponse.displayName = 'proto.protos.HistoricalMetricsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.AnomalyDetectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.AnomalyDetectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.AnomalyDetectionRequest.displayName = 'proto.protos.AnomalyDetectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.AnomalyDetectionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protos.AnomalyDetectionResponse.repeatedFields_, null);
};
goog.inherits(proto.protos.AnomalyDetectionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.AnomalyDetectionResponse.displayName = 'proto.protos.AnomalyDetectionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.ThresholdPredictionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.ThresholdPredictionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.ThresholdPredictionRequest.displayName = 'proto.protos.ThresholdPredictionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.ThresholdPredictionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.ThresholdPredictionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.ThresholdPredictionResponse.displayName = 'proto.protos.ThresholdPredictionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.CapacityForecastRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.CapacityForecastRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.CapacityForecastRequest.displayName = 'proto.protos.CapacityForecastRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.CapacityForecastResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.CapacityForecastResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.CapacityForecastResponse.displayName = 'proto.protos.CapacityForecastResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.Metric = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.Metric, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.Metric.displayName = 'proto.protos.Metric';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.Anomaly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.Anomaly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.Anomaly.displayName = 'proto.protos.Anomaly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.CapacityForecast = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.CapacityForecast, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.CapacityForecast.displayName = 'proto.protos.CapacityForecast';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.StartMonitoringRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.StartMonitoringRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.StartMonitoringRequest.displayName = 'proto.protos.StartMonitoringRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.AcknowledgeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.AcknowledgeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.AcknowledgeResponse.displayName = 'proto.protos.AcknowledgeResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.GetServerMetricsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.GetServerMetricsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.GetServerMetricsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.GetServerMetricsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serverAddress: (f = msg.getServerAddress()) && common_main_pb.ServerAddress.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.GetServerMetricsRequest}
 */
proto.protos.GetServerMetricsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.GetServerMetricsRequest;
  return proto.protos.GetServerMetricsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.GetServerMetricsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.GetServerMetricsRequest}
 */
proto.protos.GetServerMetricsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_main_pb.ServerAddress;
      reader.readMessage(value,common_main_pb.ServerAddress.deserializeBinaryFromReader);
      msg.setServerAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.GetServerMetricsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.GetServerMetricsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.GetServerMetricsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.GetServerMetricsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServerAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_main_pb.ServerAddress.serializeBinaryToWriter
    );
  }
};


/**
 * optional ServerAddress server_address = 1;
 * @return {?proto.protos.ServerAddress}
 */
proto.protos.GetServerMetricsRequest.prototype.getServerAddress = function() {
  return /** @type{?proto.protos.ServerAddress} */ (
    jspb.Message.getWrapperField(this, common_main_pb.ServerAddress, 1));
};


/**
 * @param {?proto.protos.ServerAddress|undefined} value
 * @return {!proto.protos.GetServerMetricsRequest} returns this
*/
proto.protos.GetServerMetricsRequest.prototype.setServerAddress = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.GetServerMetricsRequest} returns this
 */
proto.protos.GetServerMetricsRequest.prototype.clearServerAddress = function() {
  return this.setServerAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.GetServerMetricsRequest.prototype.hasServerAddress = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.GetServerMetricsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.GetServerMetricsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.GetServerMetricsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.GetServerMetricsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && common_main_pb.Status.toObject(includeInstance, f),
    cpu: (f = msg.getCpu()) && common_main_pb.CpuMetrics.toObject(includeInstance, f),
    gpu: (f = msg.getGpu()) && common_main_pb.GpuMetrics.toObject(includeInstance, f),
    memory: (f = msg.getMemory()) && common_main_pb.MemoryMetrics.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.GetServerMetricsResponse}
 */
proto.protos.GetServerMetricsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.GetServerMetricsResponse;
  return proto.protos.GetServerMetricsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.GetServerMetricsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.GetServerMetricsResponse}
 */
proto.protos.GetServerMetricsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_main_pb.Status;
      reader.readMessage(value,common_main_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 2:
      var value = new common_main_pb.CpuMetrics;
      reader.readMessage(value,common_main_pb.CpuMetrics.deserializeBinaryFromReader);
      msg.setCpu(value);
      break;
    case 3:
      var value = new common_main_pb.GpuMetrics;
      reader.readMessage(value,common_main_pb.GpuMetrics.deserializeBinaryFromReader);
      msg.setGpu(value);
      break;
    case 4:
      var value = new common_main_pb.MemoryMetrics;
      reader.readMessage(value,common_main_pb.MemoryMetrics.deserializeBinaryFromReader);
      msg.setMemory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.GetServerMetricsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.GetServerMetricsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.GetServerMetricsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.GetServerMetricsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_main_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getCpu();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_main_pb.CpuMetrics.serializeBinaryToWriter
    );
  }
  f = message.getGpu();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_main_pb.GpuMetrics.serializeBinaryToWriter
    );
  }
  f = message.getMemory();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_main_pb.MemoryMetrics.serializeBinaryToWriter
    );
  }
};


/**
 * optional Status status = 1;
 * @return {?proto.protos.Status}
 */
proto.protos.GetServerMetricsResponse.prototype.getStatus = function() {
  return /** @type{?proto.protos.Status} */ (
    jspb.Message.getWrapperField(this, common_main_pb.Status, 1));
};


/**
 * @param {?proto.protos.Status|undefined} value
 * @return {!proto.protos.GetServerMetricsResponse} returns this
*/
proto.protos.GetServerMetricsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.GetServerMetricsResponse} returns this
 */
proto.protos.GetServerMetricsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.GetServerMetricsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CpuMetrics cpu = 2;
 * @return {?proto.protos.CpuMetrics}
 */
proto.protos.GetServerMetricsResponse.prototype.getCpu = function() {
  return /** @type{?proto.protos.CpuMetrics} */ (
    jspb.Message.getWrapperField(this, common_main_pb.CpuMetrics, 2));
};


/**
 * @param {?proto.protos.CpuMetrics|undefined} value
 * @return {!proto.protos.GetServerMetricsResponse} returns this
*/
proto.protos.GetServerMetricsResponse.prototype.setCpu = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.GetServerMetricsResponse} returns this
 */
proto.protos.GetServerMetricsResponse.prototype.clearCpu = function() {
  return this.setCpu(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.GetServerMetricsResponse.prototype.hasCpu = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GpuMetrics gpu = 3;
 * @return {?proto.protos.GpuMetrics}
 */
proto.protos.GetServerMetricsResponse.prototype.getGpu = function() {
  return /** @type{?proto.protos.GpuMetrics} */ (
    jspb.Message.getWrapperField(this, common_main_pb.GpuMetrics, 3));
};


/**
 * @param {?proto.protos.GpuMetrics|undefined} value
 * @return {!proto.protos.GetServerMetricsResponse} returns this
*/
proto.protos.GetServerMetricsResponse.prototype.setGpu = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.GetServerMetricsResponse} returns this
 */
proto.protos.GetServerMetricsResponse.prototype.clearGpu = function() {
  return this.setGpu(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.GetServerMetricsResponse.prototype.hasGpu = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional MemoryMetrics memory = 4;
 * @return {?proto.protos.MemoryMetrics}
 */
proto.protos.GetServerMetricsResponse.prototype.getMemory = function() {
  return /** @type{?proto.protos.MemoryMetrics} */ (
    jspb.Message.getWrapperField(this, common_main_pb.MemoryMetrics, 4));
};


/**
 * @param {?proto.protos.MemoryMetrics|undefined} value
 * @return {!proto.protos.GetServerMetricsResponse} returns this
*/
proto.protos.GetServerMetricsResponse.prototype.setMemory = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.GetServerMetricsResponse} returns this
 */
proto.protos.GetServerMetricsResponse.prototype.clearMemory = function() {
  return this.setMemory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.GetServerMetricsResponse.prototype.hasMemory = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.GetAllServersMetricsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.GetAllServersMetricsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.GetAllServersMetricsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.GetAllServersMetricsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.GetAllServersMetricsRequest}
 */
proto.protos.GetAllServersMetricsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.GetAllServersMetricsRequest;
  return proto.protos.GetAllServersMetricsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.GetAllServersMetricsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.GetAllServersMetricsRequest}
 */
proto.protos.GetAllServersMetricsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.GetAllServersMetricsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.GetAllServersMetricsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.GetAllServersMetricsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.GetAllServersMetricsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.ServerMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.ServerMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.ServerMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.ServerMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    serverAddress: (f = msg.getServerAddress()) && common_main_pb.ServerAddress.toObject(includeInstance, f),
    metrics: (f = msg.getMetrics()) && proto.protos.MetricsResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.ServerMetrics}
 */
proto.protos.ServerMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.ServerMetrics;
  return proto.protos.ServerMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.ServerMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.ServerMetrics}
 */
proto.protos.ServerMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_main_pb.ServerAddress;
      reader.readMessage(value,common_main_pb.ServerAddress.deserializeBinaryFromReader);
      msg.setServerAddress(value);
      break;
    case 2:
      var value = new proto.protos.MetricsResponse;
      reader.readMessage(value,proto.protos.MetricsResponse.deserializeBinaryFromReader);
      msg.setMetrics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.ServerMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.ServerMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.ServerMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.ServerMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServerAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_main_pb.ServerAddress.serializeBinaryToWriter
    );
  }
  f = message.getMetrics();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protos.MetricsResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ServerAddress server_address = 1;
 * @return {?proto.protos.ServerAddress}
 */
proto.protos.ServerMetrics.prototype.getServerAddress = function() {
  return /** @type{?proto.protos.ServerAddress} */ (
    jspb.Message.getWrapperField(this, common_main_pb.ServerAddress, 1));
};


/**
 * @param {?proto.protos.ServerAddress|undefined} value
 * @return {!proto.protos.ServerMetrics} returns this
*/
proto.protos.ServerMetrics.prototype.setServerAddress = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.ServerMetrics} returns this
 */
proto.protos.ServerMetrics.prototype.clearServerAddress = function() {
  return this.setServerAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.ServerMetrics.prototype.hasServerAddress = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MetricsResponse metrics = 2;
 * @return {?proto.protos.MetricsResponse}
 */
proto.protos.ServerMetrics.prototype.getMetrics = function() {
  return /** @type{?proto.protos.MetricsResponse} */ (
    jspb.Message.getWrapperField(this, proto.protos.MetricsResponse, 2));
};


/**
 * @param {?proto.protos.MetricsResponse|undefined} value
 * @return {!proto.protos.ServerMetrics} returns this
*/
proto.protos.ServerMetrics.prototype.setMetrics = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.ServerMetrics} returns this
 */
proto.protos.ServerMetrics.prototype.clearMetrics = function() {
  return this.setMetrics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.ServerMetrics.prototype.hasMetrics = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protos.GetAllServersMetricsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.GetAllServersMetricsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.GetAllServersMetricsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.GetAllServersMetricsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.GetAllServersMetricsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    serversMetricsList: jspb.Message.toObjectList(msg.getServersMetricsList(),
    proto.protos.ServerMetrics.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.GetAllServersMetricsResponse}
 */
proto.protos.GetAllServersMetricsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.GetAllServersMetricsResponse;
  return proto.protos.GetAllServersMetricsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.GetAllServersMetricsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.GetAllServersMetricsResponse}
 */
proto.protos.GetAllServersMetricsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protos.ServerMetrics;
      reader.readMessage(value,proto.protos.ServerMetrics.deserializeBinaryFromReader);
      msg.addServersMetrics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.GetAllServersMetricsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.GetAllServersMetricsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.GetAllServersMetricsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.GetAllServersMetricsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServersMetricsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protos.ServerMetrics.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ServerMetrics servers_metrics = 1;
 * @return {!Array<!proto.protos.ServerMetrics>}
 */
proto.protos.GetAllServersMetricsResponse.prototype.getServersMetricsList = function() {
  return /** @type{!Array<!proto.protos.ServerMetrics>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protos.ServerMetrics, 1));
};


/**
 * @param {!Array<!proto.protos.ServerMetrics>} value
 * @return {!proto.protos.GetAllServersMetricsResponse} returns this
*/
proto.protos.GetAllServersMetricsResponse.prototype.setServersMetricsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protos.ServerMetrics=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protos.ServerMetrics}
 */
proto.protos.GetAllServersMetricsResponse.prototype.addServersMetrics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protos.ServerMetrics, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protos.GetAllServersMetricsResponse} returns this
 */
proto.protos.GetAllServersMetricsResponse.prototype.clearServersMetricsList = function() {
  return this.setServersMetricsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.MetricsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.MetricsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.MetricsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.MetricsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serverAddress: (f = msg.getServerAddress()) && common_main_pb.ServerAddress.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.MetricsRequest}
 */
proto.protos.MetricsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.MetricsRequest;
  return proto.protos.MetricsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.MetricsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.MetricsRequest}
 */
proto.protos.MetricsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_main_pb.ServerAddress;
      reader.readMessage(value,common_main_pb.ServerAddress.deserializeBinaryFromReader);
      msg.setServerAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.MetricsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.MetricsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.MetricsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.MetricsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServerAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_main_pb.ServerAddress.serializeBinaryToWriter
    );
  }
};


/**
 * optional ServerAddress server_address = 1;
 * @return {?proto.protos.ServerAddress}
 */
proto.protos.MetricsRequest.prototype.getServerAddress = function() {
  return /** @type{?proto.protos.ServerAddress} */ (
    jspb.Message.getWrapperField(this, common_main_pb.ServerAddress, 1));
};


/**
 * @param {?proto.protos.ServerAddress|undefined} value
 * @return {!proto.protos.MetricsRequest} returns this
*/
proto.protos.MetricsRequest.prototype.setServerAddress = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.MetricsRequest} returns this
 */
proto.protos.MetricsRequest.prototype.clearServerAddress = function() {
  return this.setServerAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.MetricsRequest.prototype.hasServerAddress = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.MetricsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.MetricsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.MetricsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.MetricsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && common_main_pb.Status.toObject(includeInstance, f),
    cpu: (f = msg.getCpu()) && common_main_pb.CpuMetrics.toObject(includeInstance, f),
    gpu: (f = msg.getGpu()) && common_main_pb.GpuMetrics.toObject(includeInstance, f),
    memory: (f = msg.getMemory()) && common_main_pb.MemoryMetrics.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.MetricsResponse}
 */
proto.protos.MetricsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.MetricsResponse;
  return proto.protos.MetricsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.MetricsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.MetricsResponse}
 */
proto.protos.MetricsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_main_pb.Status;
      reader.readMessage(value,common_main_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 2:
      var value = new common_main_pb.CpuMetrics;
      reader.readMessage(value,common_main_pb.CpuMetrics.deserializeBinaryFromReader);
      msg.setCpu(value);
      break;
    case 3:
      var value = new common_main_pb.GpuMetrics;
      reader.readMessage(value,common_main_pb.GpuMetrics.deserializeBinaryFromReader);
      msg.setGpu(value);
      break;
    case 4:
      var value = new common_main_pb.MemoryMetrics;
      reader.readMessage(value,common_main_pb.MemoryMetrics.deserializeBinaryFromReader);
      msg.setMemory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.MetricsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.MetricsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.MetricsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.MetricsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_main_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getCpu();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_main_pb.CpuMetrics.serializeBinaryToWriter
    );
  }
  f = message.getGpu();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_main_pb.GpuMetrics.serializeBinaryToWriter
    );
  }
  f = message.getMemory();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_main_pb.MemoryMetrics.serializeBinaryToWriter
    );
  }
};


/**
 * optional Status status = 1;
 * @return {?proto.protos.Status}
 */
proto.protos.MetricsResponse.prototype.getStatus = function() {
  return /** @type{?proto.protos.Status} */ (
    jspb.Message.getWrapperField(this, common_main_pb.Status, 1));
};


/**
 * @param {?proto.protos.Status|undefined} value
 * @return {!proto.protos.MetricsResponse} returns this
*/
proto.protos.MetricsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.MetricsResponse} returns this
 */
proto.protos.MetricsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.MetricsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CpuMetrics cpu = 2;
 * @return {?proto.protos.CpuMetrics}
 */
proto.protos.MetricsResponse.prototype.getCpu = function() {
  return /** @type{?proto.protos.CpuMetrics} */ (
    jspb.Message.getWrapperField(this, common_main_pb.CpuMetrics, 2));
};


/**
 * @param {?proto.protos.CpuMetrics|undefined} value
 * @return {!proto.protos.MetricsResponse} returns this
*/
proto.protos.MetricsResponse.prototype.setCpu = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.MetricsResponse} returns this
 */
proto.protos.MetricsResponse.prototype.clearCpu = function() {
  return this.setCpu(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.MetricsResponse.prototype.hasCpu = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GpuMetrics gpu = 3;
 * @return {?proto.protos.GpuMetrics}
 */
proto.protos.MetricsResponse.prototype.getGpu = function() {
  return /** @type{?proto.protos.GpuMetrics} */ (
    jspb.Message.getWrapperField(this, common_main_pb.GpuMetrics, 3));
};


/**
 * @param {?proto.protos.GpuMetrics|undefined} value
 * @return {!proto.protos.MetricsResponse} returns this
*/
proto.protos.MetricsResponse.prototype.setGpu = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.MetricsResponse} returns this
 */
proto.protos.MetricsResponse.prototype.clearGpu = function() {
  return this.setGpu(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.MetricsResponse.prototype.hasGpu = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional MemoryMetrics memory = 4;
 * @return {?proto.protos.MemoryMetrics}
 */
proto.protos.MetricsResponse.prototype.getMemory = function() {
  return /** @type{?proto.protos.MemoryMetrics} */ (
    jspb.Message.getWrapperField(this, common_main_pb.MemoryMetrics, 4));
};


/**
 * @param {?proto.protos.MemoryMetrics|undefined} value
 * @return {!proto.protos.MetricsResponse} returns this
*/
proto.protos.MetricsResponse.prototype.setMemory = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.MetricsResponse} returns this
 */
proto.protos.MetricsResponse.prototype.clearMemory = function() {
  return this.setMemory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.MetricsResponse.prototype.hasMemory = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.ProcessDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.ProcessDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.ProcessDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.ProcessDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userCredential: (f = msg.getUserCredential()) && common_main_pb.UserCredential.toObject(includeInstance, f),
    serverAddress: (f = msg.getServerAddress()) && common_main_pb.ServerAddress.toObject(includeInstance, f),
    processInfo: (f = msg.getProcessInfo()) && common_main_pb.ProcessInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.ProcessDataRequest}
 */
proto.protos.ProcessDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.ProcessDataRequest;
  return proto.protos.ProcessDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.ProcessDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.ProcessDataRequest}
 */
proto.protos.ProcessDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_main_pb.UserCredential;
      reader.readMessage(value,common_main_pb.UserCredential.deserializeBinaryFromReader);
      msg.setUserCredential(value);
      break;
    case 2:
      var value = new common_main_pb.ServerAddress;
      reader.readMessage(value,common_main_pb.ServerAddress.deserializeBinaryFromReader);
      msg.setServerAddress(value);
      break;
    case 3:
      var value = new common_main_pb.ProcessInfo;
      reader.readMessage(value,common_main_pb.ProcessInfo.deserializeBinaryFromReader);
      msg.setProcessInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.ProcessDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.ProcessDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.ProcessDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.ProcessDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserCredential();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_main_pb.UserCredential.serializeBinaryToWriter
    );
  }
  f = message.getServerAddress();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_main_pb.ServerAddress.serializeBinaryToWriter
    );
  }
  f = message.getProcessInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_main_pb.ProcessInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserCredential user_credential = 1;
 * @return {?proto.protos.UserCredential}
 */
proto.protos.ProcessDataRequest.prototype.getUserCredential = function() {
  return /** @type{?proto.protos.UserCredential} */ (
    jspb.Message.getWrapperField(this, common_main_pb.UserCredential, 1));
};


/**
 * @param {?proto.protos.UserCredential|undefined} value
 * @return {!proto.protos.ProcessDataRequest} returns this
*/
proto.protos.ProcessDataRequest.prototype.setUserCredential = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.ProcessDataRequest} returns this
 */
proto.protos.ProcessDataRequest.prototype.clearUserCredential = function() {
  return this.setUserCredential(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.ProcessDataRequest.prototype.hasUserCredential = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ServerAddress server_address = 2;
 * @return {?proto.protos.ServerAddress}
 */
proto.protos.ProcessDataRequest.prototype.getServerAddress = function() {
  return /** @type{?proto.protos.ServerAddress} */ (
    jspb.Message.getWrapperField(this, common_main_pb.ServerAddress, 2));
};


/**
 * @param {?proto.protos.ServerAddress|undefined} value
 * @return {!proto.protos.ProcessDataRequest} returns this
*/
proto.protos.ProcessDataRequest.prototype.setServerAddress = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.ProcessDataRequest} returns this
 */
proto.protos.ProcessDataRequest.prototype.clearServerAddress = function() {
  return this.setServerAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.ProcessDataRequest.prototype.hasServerAddress = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProcessInfo process_info = 3;
 * @return {?proto.protos.ProcessInfo}
 */
proto.protos.ProcessDataRequest.prototype.getProcessInfo = function() {
  return /** @type{?proto.protos.ProcessInfo} */ (
    jspb.Message.getWrapperField(this, common_main_pb.ProcessInfo, 3));
};


/**
 * @param {?proto.protos.ProcessInfo|undefined} value
 * @return {!proto.protos.ProcessDataRequest} returns this
*/
proto.protos.ProcessDataRequest.prototype.setProcessInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.ProcessDataRequest} returns this
 */
proto.protos.ProcessDataRequest.prototype.clearProcessInfo = function() {
  return this.setProcessInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.ProcessDataRequest.prototype.hasProcessInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.ProcessDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.ProcessDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.ProcessDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.ProcessDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && common_main_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.ProcessDataResponse}
 */
proto.protos.ProcessDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.ProcessDataResponse;
  return proto.protos.ProcessDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.ProcessDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.ProcessDataResponse}
 */
proto.protos.ProcessDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_main_pb.Status;
      reader.readMessage(value,common_main_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.ProcessDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.ProcessDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.ProcessDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.ProcessDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_main_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional Status status = 1;
 * @return {?proto.protos.Status}
 */
proto.protos.ProcessDataResponse.prototype.getStatus = function() {
  return /** @type{?proto.protos.Status} */ (
    jspb.Message.getWrapperField(this, common_main_pb.Status, 1));
};


/**
 * @param {?proto.protos.Status|undefined} value
 * @return {!proto.protos.ProcessDataResponse} returns this
*/
proto.protos.ProcessDataResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.ProcessDataResponse} returns this
 */
proto.protos.ProcessDataResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.ProcessDataResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protos.HealthCheckRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.HealthCheckRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.HealthCheckRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.HealthCheckRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.HealthCheckRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userCredential: (f = msg.getUserCredential()) && common_main_pb.UserCredential.toObject(includeInstance, f),
    serverAddress: (f = msg.getServerAddress()) && common_main_pb.ServerAddress.toObject(includeInstance, f),
    requestedMetricsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.HealthCheckRequest}
 */
proto.protos.HealthCheckRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.HealthCheckRequest;
  return proto.protos.HealthCheckRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.HealthCheckRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.HealthCheckRequest}
 */
proto.protos.HealthCheckRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_main_pb.UserCredential;
      reader.readMessage(value,common_main_pb.UserCredential.deserializeBinaryFromReader);
      msg.setUserCredential(value);
      break;
    case 2:
      var value = new common_main_pb.ServerAddress;
      reader.readMessage(value,common_main_pb.ServerAddress.deserializeBinaryFromReader);
      msg.setServerAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addRequestedMetrics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.HealthCheckRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.HealthCheckRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.HealthCheckRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.HealthCheckRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserCredential();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_main_pb.UserCredential.serializeBinaryToWriter
    );
  }
  f = message.getServerAddress();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_main_pb.ServerAddress.serializeBinaryToWriter
    );
  }
  f = message.getRequestedMetricsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional UserCredential user_credential = 1;
 * @return {?proto.protos.UserCredential}
 */
proto.protos.HealthCheckRequest.prototype.getUserCredential = function() {
  return /** @type{?proto.protos.UserCredential} */ (
    jspb.Message.getWrapperField(this, common_main_pb.UserCredential, 1));
};


/**
 * @param {?proto.protos.UserCredential|undefined} value
 * @return {!proto.protos.HealthCheckRequest} returns this
*/
proto.protos.HealthCheckRequest.prototype.setUserCredential = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.HealthCheckRequest} returns this
 */
proto.protos.HealthCheckRequest.prototype.clearUserCredential = function() {
  return this.setUserCredential(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.HealthCheckRequest.prototype.hasUserCredential = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ServerAddress server_address = 2;
 * @return {?proto.protos.ServerAddress}
 */
proto.protos.HealthCheckRequest.prototype.getServerAddress = function() {
  return /** @type{?proto.protos.ServerAddress} */ (
    jspb.Message.getWrapperField(this, common_main_pb.ServerAddress, 2));
};


/**
 * @param {?proto.protos.ServerAddress|undefined} value
 * @return {!proto.protos.HealthCheckRequest} returns this
*/
proto.protos.HealthCheckRequest.prototype.setServerAddress = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.HealthCheckRequest} returns this
 */
proto.protos.HealthCheckRequest.prototype.clearServerAddress = function() {
  return this.setServerAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.HealthCheckRequest.prototype.hasServerAddress = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated string requested_metrics = 3;
 * @return {!Array<string>}
 */
proto.protos.HealthCheckRequest.prototype.getRequestedMetricsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.protos.HealthCheckRequest} returns this
 */
proto.protos.HealthCheckRequest.prototype.setRequestedMetricsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.protos.HealthCheckRequest} returns this
 */
proto.protos.HealthCheckRequest.prototype.addRequestedMetrics = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protos.HealthCheckRequest} returns this
 */
proto.protos.HealthCheckRequest.prototype.clearRequestedMetricsList = function() {
  return this.setRequestedMetricsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protos.HealthCheckResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.HealthCheckResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.HealthCheckResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.HealthCheckResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.HealthCheckResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userCredential: (f = msg.getUserCredential()) && common_main_pb.UserCredential.toObject(includeInstance, f),
    serverAddress: (f = msg.getServerAddress()) && common_main_pb.ServerAddress.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    alert: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    metricDetailsList: jspb.Message.toObjectList(msg.getMetricDetailsList(),
    common_main_pb.MetricDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.HealthCheckResponse}
 */
proto.protos.HealthCheckResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.HealthCheckResponse;
  return proto.protos.HealthCheckResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.HealthCheckResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.HealthCheckResponse}
 */
proto.protos.HealthCheckResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_main_pb.UserCredential;
      reader.readMessage(value,common_main_pb.UserCredential.deserializeBinaryFromReader);
      msg.setUserCredential(value);
      break;
    case 2:
      var value = new common_main_pb.ServerAddress;
      reader.readMessage(value,common_main_pb.ServerAddress.deserializeBinaryFromReader);
      msg.setServerAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlert(value);
      break;
    case 5:
      var value = new common_main_pb.MetricDetail;
      reader.readMessage(value,common_main_pb.MetricDetail.deserializeBinaryFromReader);
      msg.addMetricDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.HealthCheckResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.HealthCheckResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.HealthCheckResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.HealthCheckResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserCredential();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_main_pb.UserCredential.serializeBinaryToWriter
    );
  }
  f = message.getServerAddress();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_main_pb.ServerAddress.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAlert();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getMetricDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      common_main_pb.MetricDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserCredential user_credential = 1;
 * @return {?proto.protos.UserCredential}
 */
proto.protos.HealthCheckResponse.prototype.getUserCredential = function() {
  return /** @type{?proto.protos.UserCredential} */ (
    jspb.Message.getWrapperField(this, common_main_pb.UserCredential, 1));
};


/**
 * @param {?proto.protos.UserCredential|undefined} value
 * @return {!proto.protos.HealthCheckResponse} returns this
*/
proto.protos.HealthCheckResponse.prototype.setUserCredential = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.HealthCheckResponse} returns this
 */
proto.protos.HealthCheckResponse.prototype.clearUserCredential = function() {
  return this.setUserCredential(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.HealthCheckResponse.prototype.hasUserCredential = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ServerAddress server_address = 2;
 * @return {?proto.protos.ServerAddress}
 */
proto.protos.HealthCheckResponse.prototype.getServerAddress = function() {
  return /** @type{?proto.protos.ServerAddress} */ (
    jspb.Message.getWrapperField(this, common_main_pb.ServerAddress, 2));
};


/**
 * @param {?proto.protos.ServerAddress|undefined} value
 * @return {!proto.protos.HealthCheckResponse} returns this
*/
proto.protos.HealthCheckResponse.prototype.setServerAddress = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.HealthCheckResponse} returns this
 */
proto.protos.HealthCheckResponse.prototype.clearServerAddress = function() {
  return this.setServerAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.HealthCheckResponse.prototype.hasServerAddress = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.protos.HealthCheckResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.HealthCheckResponse} returns this
 */
proto.protos.HealthCheckResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool alert = 4;
 * @return {boolean}
 */
proto.protos.HealthCheckResponse.prototype.getAlert = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protos.HealthCheckResponse} returns this
 */
proto.protos.HealthCheckResponse.prototype.setAlert = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated MetricDetail metric_details = 5;
 * @return {!Array<!proto.protos.MetricDetail>}
 */
proto.protos.HealthCheckResponse.prototype.getMetricDetailsList = function() {
  return /** @type{!Array<!proto.protos.MetricDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_main_pb.MetricDetail, 5));
};


/**
 * @param {!Array<!proto.protos.MetricDetail>} value
 * @return {!proto.protos.HealthCheckResponse} returns this
*/
proto.protos.HealthCheckResponse.prototype.setMetricDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.protos.MetricDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protos.MetricDetail}
 */
proto.protos.HealthCheckResponse.prototype.addMetricDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.protos.MetricDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protos.HealthCheckResponse} returns this
 */
proto.protos.HealthCheckResponse.prototype.clearMetricDetailsList = function() {
  return this.setMetricDetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.HistoricalMetricsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.HistoricalMetricsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.HistoricalMetricsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.HistoricalMetricsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serverAddress: (f = msg.getServerAddress()) && common_main_pb.ServerAddress.toObject(includeInstance, f),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    metricType: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.HistoricalMetricsRequest}
 */
proto.protos.HistoricalMetricsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.HistoricalMetricsRequest;
  return proto.protos.HistoricalMetricsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.HistoricalMetricsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.HistoricalMetricsRequest}
 */
proto.protos.HistoricalMetricsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_main_pb.ServerAddress;
      reader.readMessage(value,common_main_pb.ServerAddress.deserializeBinaryFromReader);
      msg.setServerAddress(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetricType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.HistoricalMetricsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.HistoricalMetricsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.HistoricalMetricsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.HistoricalMetricsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServerAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_main_pb.ServerAddress.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMetricType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional ServerAddress server_address = 1;
 * @return {?proto.protos.ServerAddress}
 */
proto.protos.HistoricalMetricsRequest.prototype.getServerAddress = function() {
  return /** @type{?proto.protos.ServerAddress} */ (
    jspb.Message.getWrapperField(this, common_main_pb.ServerAddress, 1));
};


/**
 * @param {?proto.protos.ServerAddress|undefined} value
 * @return {!proto.protos.HistoricalMetricsRequest} returns this
*/
proto.protos.HistoricalMetricsRequest.prototype.setServerAddress = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.HistoricalMetricsRequest} returns this
 */
proto.protos.HistoricalMetricsRequest.prototype.clearServerAddress = function() {
  return this.setServerAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.HistoricalMetricsRequest.prototype.hasServerAddress = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp start_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protos.HistoricalMetricsRequest.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protos.HistoricalMetricsRequest} returns this
*/
proto.protos.HistoricalMetricsRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.HistoricalMetricsRequest} returns this
 */
proto.protos.HistoricalMetricsRequest.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.HistoricalMetricsRequest.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protos.HistoricalMetricsRequest.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protos.HistoricalMetricsRequest} returns this
*/
proto.protos.HistoricalMetricsRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.HistoricalMetricsRequest} returns this
 */
proto.protos.HistoricalMetricsRequest.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.HistoricalMetricsRequest.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string metric_type = 4;
 * @return {string}
 */
proto.protos.HistoricalMetricsRequest.prototype.getMetricType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.HistoricalMetricsRequest} returns this
 */
proto.protos.HistoricalMetricsRequest.prototype.setMetricType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protos.HistoricalMetricsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.HistoricalMetricsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.HistoricalMetricsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.HistoricalMetricsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.HistoricalMetricsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    metricsList: jspb.Message.toObjectList(msg.getMetricsList(),
    proto.protos.Metric.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.HistoricalMetricsResponse}
 */
proto.protos.HistoricalMetricsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.HistoricalMetricsResponse;
  return proto.protos.HistoricalMetricsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.HistoricalMetricsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.HistoricalMetricsResponse}
 */
proto.protos.HistoricalMetricsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protos.Metric;
      reader.readMessage(value,proto.protos.Metric.deserializeBinaryFromReader);
      msg.addMetrics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.HistoricalMetricsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.HistoricalMetricsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.HistoricalMetricsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.HistoricalMetricsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetricsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protos.Metric.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Metric metrics = 1;
 * @return {!Array<!proto.protos.Metric>}
 */
proto.protos.HistoricalMetricsResponse.prototype.getMetricsList = function() {
  return /** @type{!Array<!proto.protos.Metric>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protos.Metric, 1));
};


/**
 * @param {!Array<!proto.protos.Metric>} value
 * @return {!proto.protos.HistoricalMetricsResponse} returns this
*/
proto.protos.HistoricalMetricsResponse.prototype.setMetricsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protos.Metric=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protos.Metric}
 */
proto.protos.HistoricalMetricsResponse.prototype.addMetrics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protos.Metric, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protos.HistoricalMetricsResponse} returns this
 */
proto.protos.HistoricalMetricsResponse.prototype.clearMetricsList = function() {
  return this.setMetricsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.AnomalyDetectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.AnomalyDetectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.AnomalyDetectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.AnomalyDetectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serverAddress: (f = msg.getServerAddress()) && common_main_pb.ServerAddress.toObject(includeInstance, f),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    metricType: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.AnomalyDetectionRequest}
 */
proto.protos.AnomalyDetectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.AnomalyDetectionRequest;
  return proto.protos.AnomalyDetectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.AnomalyDetectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.AnomalyDetectionRequest}
 */
proto.protos.AnomalyDetectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_main_pb.ServerAddress;
      reader.readMessage(value,common_main_pb.ServerAddress.deserializeBinaryFromReader);
      msg.setServerAddress(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetricType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.AnomalyDetectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.AnomalyDetectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.AnomalyDetectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.AnomalyDetectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServerAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_main_pb.ServerAddress.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMetricType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional ServerAddress server_address = 1;
 * @return {?proto.protos.ServerAddress}
 */
proto.protos.AnomalyDetectionRequest.prototype.getServerAddress = function() {
  return /** @type{?proto.protos.ServerAddress} */ (
    jspb.Message.getWrapperField(this, common_main_pb.ServerAddress, 1));
};


/**
 * @param {?proto.protos.ServerAddress|undefined} value
 * @return {!proto.protos.AnomalyDetectionRequest} returns this
*/
proto.protos.AnomalyDetectionRequest.prototype.setServerAddress = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.AnomalyDetectionRequest} returns this
 */
proto.protos.AnomalyDetectionRequest.prototype.clearServerAddress = function() {
  return this.setServerAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.AnomalyDetectionRequest.prototype.hasServerAddress = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp start_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protos.AnomalyDetectionRequest.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protos.AnomalyDetectionRequest} returns this
*/
proto.protos.AnomalyDetectionRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.AnomalyDetectionRequest} returns this
 */
proto.protos.AnomalyDetectionRequest.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.AnomalyDetectionRequest.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protos.AnomalyDetectionRequest.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protos.AnomalyDetectionRequest} returns this
*/
proto.protos.AnomalyDetectionRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.AnomalyDetectionRequest} returns this
 */
proto.protos.AnomalyDetectionRequest.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.AnomalyDetectionRequest.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string metric_type = 4;
 * @return {string}
 */
proto.protos.AnomalyDetectionRequest.prototype.getMetricType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.AnomalyDetectionRequest} returns this
 */
proto.protos.AnomalyDetectionRequest.prototype.setMetricType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protos.AnomalyDetectionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.AnomalyDetectionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.AnomalyDetectionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.AnomalyDetectionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.AnomalyDetectionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    anomaliesList: jspb.Message.toObjectList(msg.getAnomaliesList(),
    proto.protos.Anomaly.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.AnomalyDetectionResponse}
 */
proto.protos.AnomalyDetectionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.AnomalyDetectionResponse;
  return proto.protos.AnomalyDetectionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.AnomalyDetectionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.AnomalyDetectionResponse}
 */
proto.protos.AnomalyDetectionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protos.Anomaly;
      reader.readMessage(value,proto.protos.Anomaly.deserializeBinaryFromReader);
      msg.addAnomalies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.AnomalyDetectionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.AnomalyDetectionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.AnomalyDetectionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.AnomalyDetectionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnomaliesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.protos.Anomaly.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Anomaly anomalies = 1;
 * @return {!Array<!proto.protos.Anomaly>}
 */
proto.protos.AnomalyDetectionResponse.prototype.getAnomaliesList = function() {
  return /** @type{!Array<!proto.protos.Anomaly>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protos.Anomaly, 1));
};


/**
 * @param {!Array<!proto.protos.Anomaly>} value
 * @return {!proto.protos.AnomalyDetectionResponse} returns this
*/
proto.protos.AnomalyDetectionResponse.prototype.setAnomaliesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.protos.Anomaly=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protos.Anomaly}
 */
proto.protos.AnomalyDetectionResponse.prototype.addAnomalies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.protos.Anomaly, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protos.AnomalyDetectionResponse} returns this
 */
proto.protos.AnomalyDetectionResponse.prototype.clearAnomaliesList = function() {
  return this.setAnomaliesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.ThresholdPredictionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.ThresholdPredictionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.ThresholdPredictionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.ThresholdPredictionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serverAddress: (f = msg.getServerAddress()) && common_main_pb.ServerAddress.toObject(includeInstance, f),
    metricType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    threshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.ThresholdPredictionRequest}
 */
proto.protos.ThresholdPredictionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.ThresholdPredictionRequest;
  return proto.protos.ThresholdPredictionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.ThresholdPredictionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.ThresholdPredictionRequest}
 */
proto.protos.ThresholdPredictionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_main_pb.ServerAddress;
      reader.readMessage(value,common_main_pb.ServerAddress.deserializeBinaryFromReader);
      msg.setServerAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetricType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.ThresholdPredictionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.ThresholdPredictionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.ThresholdPredictionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.ThresholdPredictionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServerAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_main_pb.ServerAddress.serializeBinaryToWriter
    );
  }
  f = message.getMetricType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getThreshold();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional ServerAddress server_address = 1;
 * @return {?proto.protos.ServerAddress}
 */
proto.protos.ThresholdPredictionRequest.prototype.getServerAddress = function() {
  return /** @type{?proto.protos.ServerAddress} */ (
    jspb.Message.getWrapperField(this, common_main_pb.ServerAddress, 1));
};


/**
 * @param {?proto.protos.ServerAddress|undefined} value
 * @return {!proto.protos.ThresholdPredictionRequest} returns this
*/
proto.protos.ThresholdPredictionRequest.prototype.setServerAddress = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.ThresholdPredictionRequest} returns this
 */
proto.protos.ThresholdPredictionRequest.prototype.clearServerAddress = function() {
  return this.setServerAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.ThresholdPredictionRequest.prototype.hasServerAddress = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string metric_type = 2;
 * @return {string}
 */
proto.protos.ThresholdPredictionRequest.prototype.getMetricType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.ThresholdPredictionRequest} returns this
 */
proto.protos.ThresholdPredictionRequest.prototype.setMetricType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double threshold = 3;
 * @return {number}
 */
proto.protos.ThresholdPredictionRequest.prototype.getThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.ThresholdPredictionRequest} returns this
 */
proto.protos.ThresholdPredictionRequest.prototype.setThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.ThresholdPredictionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.ThresholdPredictionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.ThresholdPredictionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.ThresholdPredictionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    predictedBreachTime: (f = msg.getPredictedBreachTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.ThresholdPredictionResponse}
 */
proto.protos.ThresholdPredictionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.ThresholdPredictionResponse;
  return proto.protos.ThresholdPredictionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.ThresholdPredictionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.ThresholdPredictionResponse}
 */
proto.protos.ThresholdPredictionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPredictedBreachTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.ThresholdPredictionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.ThresholdPredictionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.ThresholdPredictionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.ThresholdPredictionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPredictedBreachTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp predicted_breach_time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protos.ThresholdPredictionResponse.prototype.getPredictedBreachTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protos.ThresholdPredictionResponse} returns this
*/
proto.protos.ThresholdPredictionResponse.prototype.setPredictedBreachTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.ThresholdPredictionResponse} returns this
 */
proto.protos.ThresholdPredictionResponse.prototype.clearPredictedBreachTime = function() {
  return this.setPredictedBreachTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.ThresholdPredictionResponse.prototype.hasPredictedBreachTime = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.CapacityForecastRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.CapacityForecastRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.CapacityForecastRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.CapacityForecastRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serverAddress: (f = msg.getServerAddress()) && common_main_pb.ServerAddress.toObject(includeInstance, f),
    forecastPeriod: (f = msg.getForecastPeriod()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.CapacityForecastRequest}
 */
proto.protos.CapacityForecastRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.CapacityForecastRequest;
  return proto.protos.CapacityForecastRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.CapacityForecastRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.CapacityForecastRequest}
 */
proto.protos.CapacityForecastRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_main_pb.ServerAddress;
      reader.readMessage(value,common_main_pb.ServerAddress.deserializeBinaryFromReader);
      msg.setServerAddress(value);
      break;
    case 2:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setForecastPeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.CapacityForecastRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.CapacityForecastRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.CapacityForecastRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.CapacityForecastRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServerAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_main_pb.ServerAddress.serializeBinaryToWriter
    );
  }
  f = message.getForecastPeriod();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
};


/**
 * optional ServerAddress server_address = 1;
 * @return {?proto.protos.ServerAddress}
 */
proto.protos.CapacityForecastRequest.prototype.getServerAddress = function() {
  return /** @type{?proto.protos.ServerAddress} */ (
    jspb.Message.getWrapperField(this, common_main_pb.ServerAddress, 1));
};


/**
 * @param {?proto.protos.ServerAddress|undefined} value
 * @return {!proto.protos.CapacityForecastRequest} returns this
*/
proto.protos.CapacityForecastRequest.prototype.setServerAddress = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.CapacityForecastRequest} returns this
 */
proto.protos.CapacityForecastRequest.prototype.clearServerAddress = function() {
  return this.setServerAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.CapacityForecastRequest.prototype.hasServerAddress = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Duration forecast_period = 2;
 * @return {?proto.google.protobuf.Duration}
 */
proto.protos.CapacityForecastRequest.prototype.getForecastPeriod = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 2));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.protos.CapacityForecastRequest} returns this
*/
proto.protos.CapacityForecastRequest.prototype.setForecastPeriod = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.CapacityForecastRequest} returns this
 */
proto.protos.CapacityForecastRequest.prototype.clearForecastPeriod = function() {
  return this.setForecastPeriod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.CapacityForecastRequest.prototype.hasForecastPeriod = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.CapacityForecastResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.CapacityForecastResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.CapacityForecastResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.CapacityForecastResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    forecast: (f = msg.getForecast()) && proto.protos.CapacityForecast.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.CapacityForecastResponse}
 */
proto.protos.CapacityForecastResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.CapacityForecastResponse;
  return proto.protos.CapacityForecastResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.CapacityForecastResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.CapacityForecastResponse}
 */
proto.protos.CapacityForecastResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protos.CapacityForecast;
      reader.readMessage(value,proto.protos.CapacityForecast.deserializeBinaryFromReader);
      msg.setForecast(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.CapacityForecastResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.CapacityForecastResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.CapacityForecastResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.CapacityForecastResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForecast();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protos.CapacityForecast.serializeBinaryToWriter
    );
  }
};


/**
 * optional CapacityForecast forecast = 1;
 * @return {?proto.protos.CapacityForecast}
 */
proto.protos.CapacityForecastResponse.prototype.getForecast = function() {
  return /** @type{?proto.protos.CapacityForecast} */ (
    jspb.Message.getWrapperField(this, proto.protos.CapacityForecast, 1));
};


/**
 * @param {?proto.protos.CapacityForecast|undefined} value
 * @return {!proto.protos.CapacityForecastResponse} returns this
*/
proto.protos.CapacityForecastResponse.prototype.setForecast = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.CapacityForecastResponse} returns this
 */
proto.protos.CapacityForecastResponse.prototype.clearForecast = function() {
  return this.setForecast(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.CapacityForecastResponse.prototype.hasForecast = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.Metric.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.Metric.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.Metric} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.Metric.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.Metric}
 */
proto.protos.Metric.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.Metric;
  return proto.protos.Metric.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.Metric} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.Metric}
 */
proto.protos.Metric.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.Metric.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.Metric.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.Metric} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.Metric.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp timestamp = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protos.Metric.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protos.Metric} returns this
*/
proto.protos.Metric.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.Metric} returns this
 */
proto.protos.Metric.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.Metric.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.protos.Metric.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.Metric} returns this
 */
proto.protos.Metric.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.Anomaly.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.Anomaly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.Anomaly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.Anomaly.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.Anomaly}
 */
proto.protos.Anomaly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.Anomaly;
  return proto.protos.Anomaly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.Anomaly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.Anomaly}
 */
proto.protos.Anomaly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.Anomaly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.Anomaly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.Anomaly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.Anomaly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp timestamp = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protos.Anomaly.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protos.Anomaly} returns this
*/
proto.protos.Anomaly.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.Anomaly} returns this
 */
proto.protos.Anomaly.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.Anomaly.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.protos.Anomaly.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.Anomaly} returns this
 */
proto.protos.Anomaly.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.CapacityForecast.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.CapacityForecast.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.CapacityForecast} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.CapacityForecast.toObject = function(includeInstance, msg) {
  var f, obj = {
    cpuUsage: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    memoryUsage: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    gpuUsage: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.CapacityForecast}
 */
proto.protos.CapacityForecast.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.CapacityForecast;
  return proto.protos.CapacityForecast.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.CapacityForecast} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.CapacityForecast}
 */
proto.protos.CapacityForecast.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCpuUsage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMemoryUsage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGpuUsage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.CapacityForecast.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.CapacityForecast.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.CapacityForecast} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.CapacityForecast.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCpuUsage();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getMemoryUsage();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getGpuUsage();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double cpu_usage = 1;
 * @return {number}
 */
proto.protos.CapacityForecast.prototype.getCpuUsage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.CapacityForecast} returns this
 */
proto.protos.CapacityForecast.prototype.setCpuUsage = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double memory_usage = 2;
 * @return {number}
 */
proto.protos.CapacityForecast.prototype.getMemoryUsage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.CapacityForecast} returns this
 */
proto.protos.CapacityForecast.prototype.setMemoryUsage = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double gpu_usage = 3;
 * @return {number}
 */
proto.protos.CapacityForecast.prototype.getGpuUsage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.CapacityForecast} returns this
 */
proto.protos.CapacityForecast.prototype.setGpuUsage = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.StartMonitoringRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.StartMonitoringRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.StartMonitoringRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.StartMonitoringRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serverAddress: (f = msg.getServerAddress()) && common_main_pb.ServerAddress.toObject(includeInstance, f),
    processInfo: (f = msg.getProcessInfo()) && common_main_pb.ProcessInfo.toObject(includeInstance, f),
    containerId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.StartMonitoringRequest}
 */
proto.protos.StartMonitoringRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.StartMonitoringRequest;
  return proto.protos.StartMonitoringRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.StartMonitoringRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.StartMonitoringRequest}
 */
proto.protos.StartMonitoringRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new common_main_pb.ServerAddress;
      reader.readMessage(value,common_main_pb.ServerAddress.deserializeBinaryFromReader);
      msg.setServerAddress(value);
      break;
    case 3:
      var value = new common_main_pb.ProcessInfo;
      reader.readMessage(value,common_main_pb.ProcessInfo.deserializeBinaryFromReader);
      msg.setProcessInfo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContainerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.StartMonitoringRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.StartMonitoringRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.StartMonitoringRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.StartMonitoringRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServerAddress();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_main_pb.ServerAddress.serializeBinaryToWriter
    );
  }
  f = message.getProcessInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_main_pb.ProcessInfo.serializeBinaryToWriter
    );
  }
  f = message.getContainerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional ServerAddress server_address = 2;
 * @return {?proto.protos.ServerAddress}
 */
proto.protos.StartMonitoringRequest.prototype.getServerAddress = function() {
  return /** @type{?proto.protos.ServerAddress} */ (
    jspb.Message.getWrapperField(this, common_main_pb.ServerAddress, 2));
};


/**
 * @param {?proto.protos.ServerAddress|undefined} value
 * @return {!proto.protos.StartMonitoringRequest} returns this
*/
proto.protos.StartMonitoringRequest.prototype.setServerAddress = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.StartMonitoringRequest} returns this
 */
proto.protos.StartMonitoringRequest.prototype.clearServerAddress = function() {
  return this.setServerAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.StartMonitoringRequest.prototype.hasServerAddress = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProcessInfo process_info = 3;
 * @return {?proto.protos.ProcessInfo}
 */
proto.protos.StartMonitoringRequest.prototype.getProcessInfo = function() {
  return /** @type{?proto.protos.ProcessInfo} */ (
    jspb.Message.getWrapperField(this, common_main_pb.ProcessInfo, 3));
};


/**
 * @param {?proto.protos.ProcessInfo|undefined} value
 * @return {!proto.protos.StartMonitoringRequest} returns this
*/
proto.protos.StartMonitoringRequest.prototype.setProcessInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.StartMonitoringRequest} returns this
 */
proto.protos.StartMonitoringRequest.prototype.clearProcessInfo = function() {
  return this.setProcessInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.StartMonitoringRequest.prototype.hasProcessInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string container_id = 4;
 * @return {string}
 */
proto.protos.StartMonitoringRequest.prototype.getContainerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.StartMonitoringRequest} returns this
 */
proto.protos.StartMonitoringRequest.prototype.setContainerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.AcknowledgeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.AcknowledgeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.AcknowledgeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.AcknowledgeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && common_main_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.AcknowledgeResponse}
 */
proto.protos.AcknowledgeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.AcknowledgeResponse;
  return proto.protos.AcknowledgeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.AcknowledgeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.AcknowledgeResponse}
 */
proto.protos.AcknowledgeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_main_pb.Status;
      reader.readMessage(value,common_main_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.AcknowledgeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.AcknowledgeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.AcknowledgeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.AcknowledgeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_main_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional Status status = 1;
 * @return {?proto.protos.Status}
 */
proto.protos.AcknowledgeResponse.prototype.getStatus = function() {
  return /** @type{?proto.protos.Status} */ (
    jspb.Message.getWrapperField(this, common_main_pb.Status, 1));
};


/**
 * @param {?proto.protos.Status|undefined} value
 * @return {!proto.protos.AcknowledgeResponse} returns this
*/
proto.protos.AcknowledgeResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.AcknowledgeResponse} returns this
 */
proto.protos.AcknowledgeResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.AcknowledgeResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.protos);
