import React from 'react';
import { Paper, Box, Typography, Chip, Divider, Stack } from '@mui/material';
import {
  ModelTraining as ModelIcon,
  Dataset as DatasetIcon,
  Settings as ConfigIcon,
  Cloud as DeployIcon,
} from '@mui/icons-material';

const StatusOverview = ({ selectedModel, selectedDataset, trainingConfig, deploymentConfig, activeTab }) => {
  const steps = [
    {
      label: "Model",
      icon: <ModelIcon />,
      value: selectedModel?.name,
      active: activeTab >= 1,
      completed: !!selectedModel,
      details: selectedModel?.parameters
    },
    {
      label: "Dataset",
      icon: <DatasetIcon />,
      value: selectedDataset?.name,
      active: activeTab >= 2,
      completed: !!selectedDataset,
      details: selectedDataset?.samples
    },
    {
      label: "Training",
      icon: <ConfigIcon />,
      value: trainingConfig?.basic?.epochs + " epochs",
      active: activeTab >= 3,
      completed: !!trainingConfig,
      details: trainingConfig?.basic?.learningRate
    },
    {
      label: "Deploy",
      icon: <DeployIcon />,
      value: deploymentConfig?.instanceType,
      active: activeTab >= 4,
      completed: !!deploymentConfig,
      details: null
    }
  ];

  return (
    <Paper 
      elevation={1}
      sx={{ 
        py: 2,
        px: 3,
        background: 'linear-gradient(to right, #1a237e05, #0d47a110)',
        borderLeft: '4px solid #1a237e',
        height: '60px',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Stack 
        direction="row" 
        spacing={3}
        divider={
          <Divider orientation="vertical" flexItem sx={{ my: 1 }} />
        }
        sx={{ width: '100%' }}
      >
        {steps.map((step) => (
          <Box 
            key={step.label} 
            sx={{ 
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              gap: 1.5,
              opacity: step.active ? 1 : 0.7,
              transition: 'all 0.2s ease'
            }}
          >
            <Box sx={{ 
              color: step.completed ? 'primary.main' : 'text.secondary',
              display: 'flex',
              '& > svg': { fontSize: '1.2rem' }
            }}>
              {step.icon}
            </Box>
            
            <Box sx={{ minWidth: 0, flex: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <Typography 
                  variant="body2"
                  color="text.secondary"
                >
                  {step.label}:
                </Typography>
                <Typography 
                  variant="body2"
                  sx={{ 
                    fontWeight: step.completed ? 500 : 400,
                    color: step.completed ? 'text.primary' : 'text.disabled',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {step.value || 'Not set'}
                </Typography>
              </Box>
              {step.details && (
                <Typography 
                  variant="caption"
                  color="text.secondary"
                  sx={{
                    display: 'block',
                    fontSize: '0.75rem',
                    lineHeight: 1.2
                  }}
                >
                  {step.details}
                </Typography>
              )}
            </Box>
          </Box>
        ))}
      </Stack>
    </Paper>
  );
};

export default StatusOverview;