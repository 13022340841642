import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import DrawerContent from './DrawerContent';
import RenderContent from './RenderContent';
import { theme } from './theme';
import { useAuth } from './authentication/AuthContext';

const drawerWidth = 200;
const collapsedDrawerWidth = 60;

export default function AppShell() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { user } = useAuth();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', m: 0, p: 0 }}>
        <CssBaseline />
        {user && (
          <Box component="nav" sx={{ width: { sm: isCollapsed ? collapsedDrawerWidth : drawerWidth }, flexShrink: { sm: 0 } }}>
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{ keepMounted: true }}
              sx={{ display: { xs: 'block', sm: 'none' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: isCollapsed ? collapsedDrawerWidth : drawerWidth } }}
            >
              <DrawerContent isCollapsed={isCollapsed} onToggleCollapse={handleDrawerCollapseToggle} />
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{ display: { xs: 'none', sm: 'block' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: isCollapsed ? collapsedDrawerWidth : drawerWidth } }}
              open
            >
              <DrawerContent isCollapsed={isCollapsed} onToggleCollapse={handleDrawerCollapseToggle} />
            </Drawer>
          </Box>
        )}
        <Box 
          component="main" 
          sx={{ 
            flexGrow: 1, 
            p: 3, 
            pt: 0, 
            width: user ? { sm: `calc(100% - ${isCollapsed ? collapsedDrawerWidth : drawerWidth}px)` } : '100%'
          }}
        >
          {user && (
            <Toolbar 
              sx={{ 
                minHeight: { xs: '48px', sm: '0px' },
                display: { xs: 'flex', sm: 'none' }
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <Box sx={{ flexGrow: 1 }} />
            </Toolbar>
          )}
          <RenderContent />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
