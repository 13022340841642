import React, { useState, useEffect } from 'react';
import {
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Box,
  Chip,
  CircularProgress,
  TextField,
  Alert,
} from '@mui/material';
import {
  Storage as StorageIcon,
  Speed as SpeedIcon,
  PrecisionManufacturing as PrecisionManufacturingIcon,
} from '@mui/icons-material';

const ModelSelection = ({ myModels, publicModels, onModelSelect }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const ALLOWED_PROVIDERS = [
    'meta', 'google', 'salesforce', 'mosaicml', 'stability-ai', 'huggingface',
    'nvidia', 'amd', 'microsoft', 'aws', 'amazon', 'intel', 'grammarly',
    'writer', 'alibaba', '01-ai', 'zhipu', 'deepseek', 'baai',
  ].map(provider => provider.toLowerCase());

  const isAllowedProvider = (model) => {
    const provider = model.provider.toLowerCase();
    const modelName = model.name.toLowerCase();
    
    return ALLOWED_PROVIDERS.some(allowedProvider => 
      provider.includes(allowedProvider) || modelName.includes(allowedProvider)
    );
  };

  // Debounce search to avoid too many API calls
  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (searchQuery.trim().length >= 3) {
        setLoading(true);
        setError(null);
        try {
          const response = await fetch(
            `https://huggingface.co/api/models?search=${encodeURIComponent(searchQuery)}&limit=5`
          );
          if (!response.ok) throw new Error('Failed to fetch models');
          
          const data = await response.json();
          const transformedResults = data.map(model => ({
            id: model.id,
            name: model.modelId,
            description: model.description || "No description available",
            provider: model.author || "Unknown",
            downloads: model.downloads || 0,
            likes: model.likes || 0,
            tags: model.tags || [],
            isAllowed: false // Will be set below
          }));

          // Set isAllowed flag for each model
          transformedResults.forEach(model => {
            model.isAllowed = isAllowedProvider(model);
          });

          setSearchResults(transformedResults);
        } catch (error) {
          setError('Failed to fetch model suggestions.');
        } finally {
          setLoading(false);
        }
      } else {
        setSearchResults([]);
      }
    }, 300); // 300ms delay

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  const handleModelSelect = (model) => {
    if (!model.isAllowed) {
      setError(
        `Models from "${model.provider}" are not currently supported. Please contact support to request access to models from this provider.`
      );
      return;
    }
    setError(null);
    onModelSelect(model);
  };

  return (
    <>
      <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
        Browse Hugging Face Models
      </Typography>

      <Box sx={{ mb: 4 }}>
        <TextField
          fullWidth
          label="Search models (e.g., 'llama', 'gpt', 'bert')"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ mb: 2 }}
        />

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
            <CircularProgress size={24} />
          </Box>
        )}
        
        {error && (
          <Alert 
            severity="error" 
            sx={{ mb: 2 }}
            onClose={() => setError(null)}
          >
            {error}
          </Alert>
        )}

        {searchResults.length > 0 && (
          <Card sx={{ mb: 4 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Search Results</Typography>
              <Grid container spacing={2}>
                {searchResults.map((model) => (
                  <Grid item xs={12} key={model.id}>
                    <Card 
                      variant="outlined"
                      sx={!model.isAllowed ? { 
                        opacity: 0.7,
                        backgroundColor: 'action.disabledBackground' 
                      } : {}}
                    >
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                          <Box>
                            <Typography variant="subtitle1" component="div">
                              {model.name}
                              {!model.isAllowed && (
                                <Chip
                                  label="Unavailable"
                                  size="small"
                                  color="default"
                                  sx={{ ml: 1 }}
                                />
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              By {model.provider}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                              {model.description}
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 1 }}>
                              {model.tags.slice(0, 3).map((tag, index) => (
                                <Chip
                                  key={index}
                                  label={tag}
                                  size="small"
                                  variant="outlined"
                                />
                              ))}
                            </Box>
                            <Typography variant="caption" color="text.secondary">
                              {model.downloads.toLocaleString()} downloads
                            </Typography>
                          </Box>
                          <Button 
                            variant="contained"
                            onClick={() => handleModelSelect(model)}
                            sx={{ ml: 2 }}
                          >
                            Select Model
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        )}
      </Box>

      {/* <Typography variant="h6" gutterBottom>My Models</Typography>
      <Grid container spacing={2}>
        {myModels.map((model) => (
          <Grid item xs={12} sm={6} md={4} key={model.id}>
            <Card>
              <CardContent>
                <Typography variant="subtitle1">{model.name}</Typography>
                <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                  <Chip 
                    size="small" 
                    icon={<StorageIcon />} 
                    label={`v${model.version}`}
                    color="primary"
                    variant="outlined"
                  />
                  <Chip 
                    size="small"
                    icon={model.accuracyIcon || <PrecisionManufacturingIcon />}
                    label={model.accuracy}
                    color="success"
                    variant="outlined"
                  />
                </Box>
                <Typography variant="body2" color="text.secondary">
                  Description: {model.description}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Provider: {model.provider} • Parameters: {model.parameters}
                </Typography>
                <Button 
                  variant="contained" 
                  onClick={() => onModelSelect(model)}
                  sx={{ mt: 2 }}
                >
                  Select Model
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid> */}

      <Typography variant="h6" gutterBottom>Public Models</Typography>
      <Grid container spacing={2}>
        {publicModels.map((model) => (
          <Grid item xs={12} sm={6} md={4} key={model.id}>
            <Card>
              <CardContent>
                <Typography variant="subtitle1">{model.name}</Typography>
                <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                  <Chip 
                    size="small"
                    icon={<PrecisionManufacturingIcon />}
                    label={`${75 + Math.floor(Math.random() * 15)}%`}
                    color="success"
                    variant="outlined"
                  />
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {model.description}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Provider: {model.provider} • Parameters: {model.parameters}
                </Typography>
                <Button 
                  variant="contained"
                  onClick={() => onModelSelect(model)}
                  sx={{ mt: 2 }}
                >
                  Select Model
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ModelSelection;
