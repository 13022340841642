/**
 * @fileoverview gRPC-Web generated client stub for protos
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.3
// source: monitoring_service/monitoring_messages.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var common_main_pb = require('../common/main_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js')
const proto = {};
proto.protos = require('./monitoring_messages_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protos.MonitoringServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.protos.MonitoringServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protos.HealthCheckRequest,
 *   !proto.protos.HealthCheckResponse>}
 */
const methodDescriptor_MonitoringService_QueryHealthCheck = new grpc.web.MethodDescriptor(
  '/protos.MonitoringService/QueryHealthCheck',
  grpc.web.MethodType.UNARY,
  proto.protos.HealthCheckRequest,
  proto.protos.HealthCheckResponse,
  /**
   * @param {!proto.protos.HealthCheckRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protos.HealthCheckResponse.deserializeBinary
);


/**
 * @param {!proto.protos.HealthCheckRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protos.HealthCheckResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protos.HealthCheckResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protos.MonitoringServiceClient.prototype.queryHealthCheck =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protos.MonitoringService/QueryHealthCheck',
      request,
      metadata || {},
      methodDescriptor_MonitoringService_QueryHealthCheck,
      callback);
};


/**
 * @param {!proto.protos.HealthCheckRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protos.HealthCheckResponse>}
 *     Promise that resolves to the response
 */
proto.protos.MonitoringServicePromiseClient.prototype.queryHealthCheck =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protos.MonitoringService/QueryHealthCheck',
      request,
      metadata || {},
      methodDescriptor_MonitoringService_QueryHealthCheck);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protos.MetricsRequest,
 *   !proto.protos.MetricsResponse>}
 */
const methodDescriptor_MonitoringService_GetMetrics = new grpc.web.MethodDescriptor(
  '/protos.MonitoringService/GetMetrics',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.protos.MetricsRequest,
  proto.protos.MetricsResponse,
  /**
   * @param {!proto.protos.MetricsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protos.MetricsResponse.deserializeBinary
);


/**
 * @param {!proto.protos.MetricsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.protos.MetricsResponse>}
 *     The XHR Node Readable Stream
 */
proto.protos.MonitoringServiceClient.prototype.getMetrics =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/protos.MonitoringService/GetMetrics',
      request,
      metadata || {},
      methodDescriptor_MonitoringService_GetMetrics);
};


/**
 * @param {!proto.protos.MetricsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.protos.MetricsResponse>}
 *     The XHR Node Readable Stream
 */
proto.protos.MonitoringServicePromiseClient.prototype.getMetrics =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/protos.MonitoringService/GetMetrics',
      request,
      metadata || {},
      methodDescriptor_MonitoringService_GetMetrics);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protos.ProcessDataRequest,
 *   !proto.protos.ProcessDataResponse>}
 */
const methodDescriptor_MonitoringService_GetProcessData = new grpc.web.MethodDescriptor(
  '/protos.MonitoringService/GetProcessData',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.protos.ProcessDataRequest,
  proto.protos.ProcessDataResponse,
  /**
   * @param {!proto.protos.ProcessDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protos.ProcessDataResponse.deserializeBinary
);


/**
 * @param {!proto.protos.ProcessDataRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.protos.ProcessDataResponse>}
 *     The XHR Node Readable Stream
 */
proto.protos.MonitoringServiceClient.prototype.getProcessData =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/protos.MonitoringService/GetProcessData',
      request,
      metadata || {},
      methodDescriptor_MonitoringService_GetProcessData);
};


/**
 * @param {!proto.protos.ProcessDataRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.protos.ProcessDataResponse>}
 *     The XHR Node Readable Stream
 */
proto.protos.MonitoringServicePromiseClient.prototype.getProcessData =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/protos.MonitoringService/GetProcessData',
      request,
      metadata || {},
      methodDescriptor_MonitoringService_GetProcessData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protos.HistoricalMetricsRequest,
 *   !proto.protos.HistoricalMetricsResponse>}
 */
const methodDescriptor_MonitoringService_GetHistoricalMetrics = new grpc.web.MethodDescriptor(
  '/protos.MonitoringService/GetHistoricalMetrics',
  grpc.web.MethodType.UNARY,
  proto.protos.HistoricalMetricsRequest,
  proto.protos.HistoricalMetricsResponse,
  /**
   * @param {!proto.protos.HistoricalMetricsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protos.HistoricalMetricsResponse.deserializeBinary
);


/**
 * @param {!proto.protos.HistoricalMetricsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protos.HistoricalMetricsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protos.HistoricalMetricsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protos.MonitoringServiceClient.prototype.getHistoricalMetrics =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protos.MonitoringService/GetHistoricalMetrics',
      request,
      metadata || {},
      methodDescriptor_MonitoringService_GetHistoricalMetrics,
      callback);
};


/**
 * @param {!proto.protos.HistoricalMetricsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protos.HistoricalMetricsResponse>}
 *     Promise that resolves to the response
 */
proto.protos.MonitoringServicePromiseClient.prototype.getHistoricalMetrics =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protos.MonitoringService/GetHistoricalMetrics',
      request,
      metadata || {},
      methodDescriptor_MonitoringService_GetHistoricalMetrics);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protos.AnomalyDetectionRequest,
 *   !proto.protos.AnomalyDetectionResponse>}
 */
const methodDescriptor_MonitoringService_DetectAnomalies = new grpc.web.MethodDescriptor(
  '/protos.MonitoringService/DetectAnomalies',
  grpc.web.MethodType.UNARY,
  proto.protos.AnomalyDetectionRequest,
  proto.protos.AnomalyDetectionResponse,
  /**
   * @param {!proto.protos.AnomalyDetectionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protos.AnomalyDetectionResponse.deserializeBinary
);


/**
 * @param {!proto.protos.AnomalyDetectionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protos.AnomalyDetectionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protos.AnomalyDetectionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protos.MonitoringServiceClient.prototype.detectAnomalies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protos.MonitoringService/DetectAnomalies',
      request,
      metadata || {},
      methodDescriptor_MonitoringService_DetectAnomalies,
      callback);
};


/**
 * @param {!proto.protos.AnomalyDetectionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protos.AnomalyDetectionResponse>}
 *     Promise that resolves to the response
 */
proto.protos.MonitoringServicePromiseClient.prototype.detectAnomalies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protos.MonitoringService/DetectAnomalies',
      request,
      metadata || {},
      methodDescriptor_MonitoringService_DetectAnomalies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protos.ThresholdPredictionRequest,
 *   !proto.protos.ThresholdPredictionResponse>}
 */
const methodDescriptor_MonitoringService_PredictThresholdBreach = new grpc.web.MethodDescriptor(
  '/protos.MonitoringService/PredictThresholdBreach',
  grpc.web.MethodType.UNARY,
  proto.protos.ThresholdPredictionRequest,
  proto.protos.ThresholdPredictionResponse,
  /**
   * @param {!proto.protos.ThresholdPredictionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protos.ThresholdPredictionResponse.deserializeBinary
);


/**
 * @param {!proto.protos.ThresholdPredictionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protos.ThresholdPredictionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protos.ThresholdPredictionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protos.MonitoringServiceClient.prototype.predictThresholdBreach =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protos.MonitoringService/PredictThresholdBreach',
      request,
      metadata || {},
      methodDescriptor_MonitoringService_PredictThresholdBreach,
      callback);
};


/**
 * @param {!proto.protos.ThresholdPredictionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protos.ThresholdPredictionResponse>}
 *     Promise that resolves to the response
 */
proto.protos.MonitoringServicePromiseClient.prototype.predictThresholdBreach =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protos.MonitoringService/PredictThresholdBreach',
      request,
      metadata || {},
      methodDescriptor_MonitoringService_PredictThresholdBreach);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protos.CapacityForecastRequest,
 *   !proto.protos.CapacityForecastResponse>}
 */
const methodDescriptor_MonitoringService_ForecastCapacity = new grpc.web.MethodDescriptor(
  '/protos.MonitoringService/ForecastCapacity',
  grpc.web.MethodType.UNARY,
  proto.protos.CapacityForecastRequest,
  proto.protos.CapacityForecastResponse,
  /**
   * @param {!proto.protos.CapacityForecastRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protos.CapacityForecastResponse.deserializeBinary
);


/**
 * @param {!proto.protos.CapacityForecastRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protos.CapacityForecastResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protos.CapacityForecastResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protos.MonitoringServiceClient.prototype.forecastCapacity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protos.MonitoringService/ForecastCapacity',
      request,
      metadata || {},
      methodDescriptor_MonitoringService_ForecastCapacity,
      callback);
};


/**
 * @param {!proto.protos.CapacityForecastRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protos.CapacityForecastResponse>}
 *     Promise that resolves to the response
 */
proto.protos.MonitoringServicePromiseClient.prototype.forecastCapacity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protos.MonitoringService/ForecastCapacity',
      request,
      metadata || {},
      methodDescriptor_MonitoringService_ForecastCapacity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protos.StartMonitoringRequest,
 *   !proto.protos.AcknowledgeResponse>}
 */
const methodDescriptor_MonitoringService_StartMonitoringProcess = new grpc.web.MethodDescriptor(
  '/protos.MonitoringService/StartMonitoringProcess',
  grpc.web.MethodType.UNARY,
  proto.protos.StartMonitoringRequest,
  proto.protos.AcknowledgeResponse,
  /**
   * @param {!proto.protos.StartMonitoringRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protos.AcknowledgeResponse.deserializeBinary
);


/**
 * @param {!proto.protos.StartMonitoringRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.protos.AcknowledgeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.protos.AcknowledgeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.protos.MonitoringServiceClient.prototype.startMonitoringProcess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/protos.MonitoringService/StartMonitoringProcess',
      request,
      metadata || {},
      methodDescriptor_MonitoringService_StartMonitoringProcess,
      callback);
};


/**
 * @param {!proto.protos.StartMonitoringRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.protos.AcknowledgeResponse>}
 *     Promise that resolves to the response
 */
proto.protos.MonitoringServicePromiseClient.prototype.startMonitoringProcess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/protos.MonitoringService/StartMonitoringProcess',
      request,
      metadata || {},
      methodDescriptor_MonitoringService_StartMonitoringProcess);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protos.GetAllServersMetricsRequest,
 *   !proto.protos.GetAllServersMetricsResponse>}
 */
const methodDescriptor_MonitoringService_GetAllServersMetrics = new grpc.web.MethodDescriptor(
  '/protos.MonitoringService/GetAllServersMetrics',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.protos.GetAllServersMetricsRequest,
  proto.protos.GetAllServersMetricsResponse,
  /**
   * @param {!proto.protos.GetAllServersMetricsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protos.GetAllServersMetricsResponse.deserializeBinary
);


/**
 * @param {!proto.protos.GetAllServersMetricsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.protos.GetAllServersMetricsResponse>}
 *     The XHR Node Readable Stream
 */
proto.protos.MonitoringServiceClient.prototype.getAllServersMetrics =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/protos.MonitoringService/GetAllServersMetrics',
      request,
      metadata || {},
      methodDescriptor_MonitoringService_GetAllServersMetrics);
};


/**
 * @param {!proto.protos.GetAllServersMetricsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.protos.GetAllServersMetricsResponse>}
 *     The XHR Node Readable Stream
 */
proto.protos.MonitoringServicePromiseClient.prototype.getAllServersMetrics =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/protos.MonitoringService/GetAllServersMetrics',
      request,
      metadata || {},
      methodDescriptor_MonitoringService_GetAllServersMetrics);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.protos.GetServerMetricsRequest,
 *   !proto.protos.GetServerMetricsResponse>}
 */
const methodDescriptor_MonitoringService_GetServerMetrics = new grpc.web.MethodDescriptor(
  '/protos.MonitoringService/GetServerMetrics',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.protos.GetServerMetricsRequest,
  proto.protos.GetServerMetricsResponse,
  /**
   * @param {!proto.protos.GetServerMetricsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.protos.GetServerMetricsResponse.deserializeBinary
);


/**
 * @param {!proto.protos.GetServerMetricsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.protos.GetServerMetricsResponse>}
 *     The XHR Node Readable Stream
 */
proto.protos.MonitoringServiceClient.prototype.getServerMetrics =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/protos.MonitoringService/GetServerMetrics',
      request,
      metadata || {},
      methodDescriptor_MonitoringService_GetServerMetrics);
};


/**
 * @param {!proto.protos.GetServerMetricsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.protos.GetServerMetricsResponse>}
 *     The XHR Node Readable Stream
 */
proto.protos.MonitoringServicePromiseClient.prototype.getServerMetrics =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/protos.MonitoringService/GetServerMetrics',
      request,
      metadata || {},
      methodDescriptor_MonitoringService_GetServerMetrics);
};


module.exports = proto.protos;

