// source: common/main.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.protos.CPUInfo', null, global);
goog.exportSymbol('proto.protos.CpuMetrics', null, global);
goog.exportSymbol('proto.protos.Docker', null, global);
goog.exportSymbol('proto.protos.DockerContainer', null, global);
goog.exportSymbol('proto.protos.DockerCredentials', null, global);
goog.exportSymbol('proto.protos.DockerImage', null, global);
goog.exportSymbol('proto.protos.DockerStatus', null, global);
goog.exportSymbol('proto.protos.GPUInfo', null, global);
goog.exportSymbol('proto.protos.GpuMetrics', null, global);
goog.exportSymbol('proto.protos.Groups', null, global);
goog.exportSymbol('proto.protos.Image', null, global);
goog.exportSymbol('proto.protos.Instance', null, global);
goog.exportSymbol('proto.protos.Job', null, global);
goog.exportSymbol('proto.protos.MemoryMetrics', null, global);
goog.exportSymbol('proto.protos.MetricDetail', null, global);
goog.exportSymbol('proto.protos.NodeTypes', null, global);
goog.exportSymbol('proto.protos.Password', null, global);
goog.exportSymbol('proto.protos.PrivateKey', null, global);
goog.exportSymbol('proto.protos.ProcessInfo', null, global);
goog.exportSymbol('proto.protos.PublicKey', null, global);
goog.exportSymbol('proto.protos.ServerAddress', null, global);
goog.exportSymbol('proto.protos.ServerData', null, global);
goog.exportSymbol('proto.protos.SlurmJob', null, global);
goog.exportSymbol('proto.protos.Status', null, global);
goog.exportSymbol('proto.protos.TrainingConfig', null, global);
goog.exportSymbol('proto.protos.TrainingData', null, global);
goog.exportSymbol('proto.protos.UserCredential', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.TrainingData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.TrainingData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.TrainingData.displayName = 'proto.protos.TrainingData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.TrainingConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.TrainingConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.TrainingConfig.displayName = 'proto.protos.TrainingConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.ProcessInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.ProcessInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.ProcessInfo.displayName = 'proto.protos.ProcessInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.CpuMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.CpuMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.CpuMetrics.displayName = 'proto.protos.CpuMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.GpuMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.GpuMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.GpuMetrics.displayName = 'proto.protos.GpuMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.MemoryMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.MemoryMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.MemoryMetrics.displayName = 'proto.protos.MemoryMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.MetricDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protos.MetricDetail.repeatedFields_, null);
};
goog.inherits(proto.protos.MetricDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.MetricDetail.displayName = 'proto.protos.MetricDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.GPUInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protos.GPUInfo.repeatedFields_, null);
};
goog.inherits(proto.protos.GPUInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.GPUInfo.displayName = 'proto.protos.GPUInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.CPUInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.CPUInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.CPUInfo.displayName = 'proto.protos.CPUInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.SlurmJob = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.SlurmJob, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.SlurmJob.displayName = 'proto.protos.SlurmJob';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.ServerAddress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.ServerAddress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.ServerAddress.displayName = 'proto.protos.ServerAddress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.Status.displayName = 'proto.protos.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.ServerData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.ServerData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.ServerData.displayName = 'proto.protos.ServerData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.Instance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.Instance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.Instance.displayName = 'proto.protos.Instance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.NodeTypes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.NodeTypes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.NodeTypes.displayName = 'proto.protos.NodeTypes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.PrivateKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.PrivateKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.PrivateKey.displayName = 'proto.protos.PrivateKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.PublicKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.PublicKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.PublicKey.displayName = 'proto.protos.PublicKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.UserCredential = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.UserCredential, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.UserCredential.displayName = 'proto.protos.UserCredential';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.Password = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.Password, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.Password.displayName = 'proto.protos.Password';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.Groups = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.Groups, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.Groups.displayName = 'proto.protos.Groups';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.DockerImage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.DockerImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.DockerImage.displayName = 'proto.protos.DockerImage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.DockerContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.DockerContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.DockerContainer.displayName = 'proto.protos.DockerContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.DockerCredentials = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.DockerCredentials, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.DockerCredentials.displayName = 'proto.protos.DockerCredentials';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.DockerStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protos.DockerStatus.repeatedFields_, null);
};
goog.inherits(proto.protos.DockerStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.DockerStatus.displayName = 'proto.protos.DockerStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.Docker = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.Docker, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.Docker.displayName = 'proto.protos.Docker';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.Job = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.Job, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.Job.displayName = 'proto.protos.Job';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protos.Image = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protos.Image, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protos.Image.displayName = 'proto.protos.Image';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.TrainingData.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.TrainingData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.TrainingData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.TrainingData.toObject = function(includeInstance, msg) {
  var f, obj = {
    model: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inputModel: jspb.Message.getFieldWithDefault(msg, 2, ""),
    outputModel: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dataPath: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.TrainingData}
 */
proto.protos.TrainingData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.TrainingData;
  return proto.protos.TrainingData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.TrainingData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.TrainingData}
 */
proto.protos.TrainingData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInputModel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutputModel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDataPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.TrainingData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.TrainingData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.TrainingData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.TrainingData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInputModel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOutputModel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDataPath();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string model = 1;
 * @return {string}
 */
proto.protos.TrainingData.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.TrainingData} returns this
 */
proto.protos.TrainingData.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string input_model = 2;
 * @return {string}
 */
proto.protos.TrainingData.prototype.getInputModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.TrainingData} returns this
 */
proto.protos.TrainingData.prototype.setInputModel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string output_model = 3;
 * @return {string}
 */
proto.protos.TrainingData.prototype.getOutputModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.TrainingData} returns this
 */
proto.protos.TrainingData.prototype.setOutputModel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string data_path = 4;
 * @return {string}
 */
proto.protos.TrainingData.prototype.getDataPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.TrainingData} returns this
 */
proto.protos.TrainingData.prototype.setDataPath = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.TrainingConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.TrainingConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.TrainingConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.TrainingConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    epochs: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accumulationSteps: jspb.Message.getFieldWithDefault(msg, 2, 0),
    saveSteps: jspb.Message.getFieldWithDefault(msg, 3, 0),
    learningRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    dtype: jspb.Message.getFieldWithDefault(msg, 5, ""),
    maxSteps: jspb.Message.getFieldWithDefault(msg, 6, 0),
    groupByLength: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    batchSize: jspb.Message.getFieldWithDefault(msg, 8, 0),
    optim: jspb.Message.getFieldWithDefault(msg, 9, ""),
    loggingSteps: jspb.Message.getFieldWithDefault(msg, 10, 0),
    weightDecay: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    maxGradNorm: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    warmupRatio: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    schedulerType: jspb.Message.getFieldWithDefault(msg, 14, ""),
    taskType: jspb.Message.getFieldWithDefault(msg, 15, ""),
    pushToHub: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    repoId: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.TrainingConfig}
 */
proto.protos.TrainingConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.TrainingConfig;
  return proto.protos.TrainingConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.TrainingConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.TrainingConfig}
 */
proto.protos.TrainingConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEpochs(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccumulationSteps(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSaveSteps(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLearningRate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDtype(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxSteps(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGroupByLength(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatchSize(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptim(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLoggingSteps(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWeightDecay(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxGradNorm(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWarmupRatio(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchedulerType(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskType(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPushToHub(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepoId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.TrainingConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.TrainingConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.TrainingConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.TrainingConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEpochs();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAccumulationSteps();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSaveSteps();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLearningRate();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getDtype();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMaxSteps();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getGroupByLength();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getBatchSize();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getOptim();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLoggingSteps();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getWeightDecay();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getMaxGradNorm();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getWarmupRatio();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getSchedulerType();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getTaskType();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPushToHub();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getRepoId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional int32 epochs = 1;
 * @return {number}
 */
proto.protos.TrainingConfig.prototype.getEpochs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protos.TrainingConfig} returns this
 */
proto.protos.TrainingConfig.prototype.setEpochs = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 accumulation_steps = 2;
 * @return {number}
 */
proto.protos.TrainingConfig.prototype.getAccumulationSteps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protos.TrainingConfig} returns this
 */
proto.protos.TrainingConfig.prototype.setAccumulationSteps = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 save_steps = 3;
 * @return {number}
 */
proto.protos.TrainingConfig.prototype.getSaveSteps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protos.TrainingConfig} returns this
 */
proto.protos.TrainingConfig.prototype.setSaveSteps = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional float learning_rate = 4;
 * @return {number}
 */
proto.protos.TrainingConfig.prototype.getLearningRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.TrainingConfig} returns this
 */
proto.protos.TrainingConfig.prototype.setLearningRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string dtype = 5;
 * @return {string}
 */
proto.protos.TrainingConfig.prototype.getDtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.TrainingConfig} returns this
 */
proto.protos.TrainingConfig.prototype.setDtype = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 max_steps = 6;
 * @return {number}
 */
proto.protos.TrainingConfig.prototype.getMaxSteps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protos.TrainingConfig} returns this
 */
proto.protos.TrainingConfig.prototype.setMaxSteps = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool group_by_length = 7;
 * @return {boolean}
 */
proto.protos.TrainingConfig.prototype.getGroupByLength = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protos.TrainingConfig} returns this
 */
proto.protos.TrainingConfig.prototype.setGroupByLength = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 batch_size = 8;
 * @return {number}
 */
proto.protos.TrainingConfig.prototype.getBatchSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.protos.TrainingConfig} returns this
 */
proto.protos.TrainingConfig.prototype.setBatchSize = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string optim = 9;
 * @return {string}
 */
proto.protos.TrainingConfig.prototype.getOptim = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.TrainingConfig} returns this
 */
proto.protos.TrainingConfig.prototype.setOptim = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 logging_steps = 10;
 * @return {number}
 */
proto.protos.TrainingConfig.prototype.getLoggingSteps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.protos.TrainingConfig} returns this
 */
proto.protos.TrainingConfig.prototype.setLoggingSteps = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional float weight_decay = 11;
 * @return {number}
 */
proto.protos.TrainingConfig.prototype.getWeightDecay = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.TrainingConfig} returns this
 */
proto.protos.TrainingConfig.prototype.setWeightDecay = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float max_grad_norm = 12;
 * @return {number}
 */
proto.protos.TrainingConfig.prototype.getMaxGradNorm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.TrainingConfig} returns this
 */
proto.protos.TrainingConfig.prototype.setMaxGradNorm = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional float warmup_ratio = 13;
 * @return {number}
 */
proto.protos.TrainingConfig.prototype.getWarmupRatio = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.TrainingConfig} returns this
 */
proto.protos.TrainingConfig.prototype.setWarmupRatio = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional string scheduler_type = 14;
 * @return {string}
 */
proto.protos.TrainingConfig.prototype.getSchedulerType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.TrainingConfig} returns this
 */
proto.protos.TrainingConfig.prototype.setSchedulerType = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string task_type = 15;
 * @return {string}
 */
proto.protos.TrainingConfig.prototype.getTaskType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.TrainingConfig} returns this
 */
proto.protos.TrainingConfig.prototype.setTaskType = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional bool push_to_hub = 16;
 * @return {boolean}
 */
proto.protos.TrainingConfig.prototype.getPushToHub = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protos.TrainingConfig} returns this
 */
proto.protos.TrainingConfig.prototype.setPushToHub = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional string repo_id = 17;
 * @return {string}
 */
proto.protos.TrainingConfig.prototype.getRepoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.TrainingConfig} returns this
 */
proto.protos.TrainingConfig.prototype.setRepoId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.ProcessInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.ProcessInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.ProcessInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.ProcessInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    pid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isDocker: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    dockerContainer: (f = msg.getDockerContainer()) && proto.protos.DockerContainer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.ProcessInfo}
 */
proto.protos.ProcessInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.ProcessInfo;
  return proto.protos.ProcessInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.ProcessInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.ProcessInfo}
 */
proto.protos.ProcessInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDocker(value);
      break;
    case 3:
      var value = new proto.protos.DockerContainer;
      reader.readMessage(value,proto.protos.DockerContainer.deserializeBinaryFromReader);
      msg.setDockerContainer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.ProcessInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.ProcessInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.ProcessInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.ProcessInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIsDocker();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDockerContainer();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protos.DockerContainer.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 pid = 1;
 * @return {number}
 */
proto.protos.ProcessInfo.prototype.getPid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protos.ProcessInfo} returns this
 */
proto.protos.ProcessInfo.prototype.setPid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool is_docker = 2;
 * @return {boolean}
 */
proto.protos.ProcessInfo.prototype.getIsDocker = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protos.ProcessInfo} returns this
 */
proto.protos.ProcessInfo.prototype.setIsDocker = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional DockerContainer docker_container = 3;
 * @return {?proto.protos.DockerContainer}
 */
proto.protos.ProcessInfo.prototype.getDockerContainer = function() {
  return /** @type{?proto.protos.DockerContainer} */ (
    jspb.Message.getWrapperField(this, proto.protos.DockerContainer, 3));
};


/**
 * @param {?proto.protos.DockerContainer|undefined} value
 * @return {!proto.protos.ProcessInfo} returns this
*/
proto.protos.ProcessInfo.prototype.setDockerContainer = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.ProcessInfo} returns this
 */
proto.protos.ProcessInfo.prototype.clearDockerContainer = function() {
  return this.setDockerContainer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.ProcessInfo.prototype.hasDockerContainer = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.CpuMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.CpuMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.CpuMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.CpuMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    usage: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    cores: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.CpuMetrics}
 */
proto.protos.CpuMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.CpuMetrics;
  return proto.protos.CpuMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.CpuMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.CpuMetrics}
 */
proto.protos.CpuMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUsage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCores(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.CpuMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.CpuMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.CpuMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.CpuMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsage();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getCores();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional double usage = 1;
 * @return {number}
 */
proto.protos.CpuMetrics.prototype.getUsage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.CpuMetrics} returns this
 */
proto.protos.CpuMetrics.prototype.setUsage = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional int32 cores = 2;
 * @return {number}
 */
proto.protos.CpuMetrics.prototype.getCores = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protos.CpuMetrics} returns this
 */
proto.protos.CpuMetrics.prototype.setCores = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.GpuMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.GpuMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.GpuMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.GpuMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    utilization: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    memoryUsed: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    memoryTotal: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    memoryUtilization: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    temperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.GpuMetrics}
 */
proto.protos.GpuMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.GpuMetrics;
  return proto.protos.GpuMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.GpuMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.GpuMetrics}
 */
proto.protos.GpuMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUtilization(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMemoryUsed(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMemoryTotal(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMemoryUtilization(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTemperature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.GpuMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.GpuMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.GpuMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.GpuMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtilization();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getMemoryUsed();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getMemoryTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getMemoryUtilization();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTemperature();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional double utilization = 1;
 * @return {number}
 */
proto.protos.GpuMetrics.prototype.getUtilization = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.GpuMetrics} returns this
 */
proto.protos.GpuMetrics.prototype.setUtilization = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double memory_used = 2;
 * @return {number}
 */
proto.protos.GpuMetrics.prototype.getMemoryUsed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.GpuMetrics} returns this
 */
proto.protos.GpuMetrics.prototype.setMemoryUsed = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double memory_total = 3;
 * @return {number}
 */
proto.protos.GpuMetrics.prototype.getMemoryTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.GpuMetrics} returns this
 */
proto.protos.GpuMetrics.prototype.setMemoryTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double memory_utilization = 4;
 * @return {number}
 */
proto.protos.GpuMetrics.prototype.getMemoryUtilization = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.GpuMetrics} returns this
 */
proto.protos.GpuMetrics.prototype.setMemoryUtilization = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double temperature = 5;
 * @return {number}
 */
proto.protos.GpuMetrics.prototype.getTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.GpuMetrics} returns this
 */
proto.protos.GpuMetrics.prototype.setTemperature = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.MemoryMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.MemoryMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.MemoryMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.MemoryMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    used: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    total: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    utilization: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.MemoryMetrics}
 */
proto.protos.MemoryMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.MemoryMetrics;
  return proto.protos.MemoryMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.MemoryMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.MemoryMetrics}
 */
proto.protos.MemoryMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUsed(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUtilization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.MemoryMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.MemoryMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.MemoryMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.MemoryMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsed();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getUtilization();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double used = 1;
 * @return {number}
 */
proto.protos.MemoryMetrics.prototype.getUsed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.MemoryMetrics} returns this
 */
proto.protos.MemoryMetrics.prototype.setUsed = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double total = 2;
 * @return {number}
 */
proto.protos.MemoryMetrics.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.MemoryMetrics} returns this
 */
proto.protos.MemoryMetrics.prototype.setTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double utilization = 3;
 * @return {number}
 */
proto.protos.MemoryMetrics.prototype.getUtilization = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.MemoryMetrics} returns this
 */
proto.protos.MemoryMetrics.prototype.setUtilization = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protos.MetricDetail.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.MetricDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.MetricDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.MetricDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.MetricDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    metricName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    averageValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    minValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    maxValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    threshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    thresholdBreached: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    unit: jspb.Message.getFieldWithDefault(msg, 8, ""),
    alertsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.MetricDetail}
 */
proto.protos.MetricDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.MetricDetail;
  return proto.protos.MetricDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.MetricDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.MetricDetail}
 */
proto.protos.MetricDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetricName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAverageValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinValue(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxValue(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setThreshold(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setThresholdBreached(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addAlerts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.MetricDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.MetricDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.MetricDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.MetricDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetricName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAverageValue();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getMinValue();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getMaxValue();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getThreshold();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getThresholdBreached();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAlertsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
};


/**
 * optional string metric_name = 1;
 * @return {string}
 */
proto.protos.MetricDetail.prototype.getMetricName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.MetricDetail} returns this
 */
proto.protos.MetricDetail.prototype.setMetricName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double average_value = 3;
 * @return {number}
 */
proto.protos.MetricDetail.prototype.getAverageValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.MetricDetail} returns this
 */
proto.protos.MetricDetail.prototype.setAverageValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double min_value = 4;
 * @return {number}
 */
proto.protos.MetricDetail.prototype.getMinValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.MetricDetail} returns this
 */
proto.protos.MetricDetail.prototype.setMinValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double max_value = 5;
 * @return {number}
 */
proto.protos.MetricDetail.prototype.getMaxValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.MetricDetail} returns this
 */
proto.protos.MetricDetail.prototype.setMaxValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double threshold = 6;
 * @return {number}
 */
proto.protos.MetricDetail.prototype.getThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.protos.MetricDetail} returns this
 */
proto.protos.MetricDetail.prototype.setThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional bool threshold_breached = 7;
 * @return {boolean}
 */
proto.protos.MetricDetail.prototype.getThresholdBreached = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protos.MetricDetail} returns this
 */
proto.protos.MetricDetail.prototype.setThresholdBreached = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string unit = 8;
 * @return {string}
 */
proto.protos.MetricDetail.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.MetricDetail} returns this
 */
proto.protos.MetricDetail.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated string alerts = 9;
 * @return {!Array<string>}
 */
proto.protos.MetricDetail.prototype.getAlertsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.protos.MetricDetail} returns this
 */
proto.protos.MetricDetail.prototype.setAlertsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.protos.MetricDetail} returns this
 */
proto.protos.MetricDetail.prototype.addAlerts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protos.MetricDetail} returns this
 */
proto.protos.MetricDetail.prototype.clearAlertsList = function() {
  return this.setAlertsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protos.GPUInfo.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.GPUInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.GPUInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.GPUInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.GPUInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    isAmd: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    isNvidia: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    gpuName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    gpuIndicesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.GPUInfo}
 */
proto.protos.GPUInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.GPUInfo;
  return proto.protos.GPUInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.GPUInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.GPUInfo}
 */
proto.protos.GPUInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAmd(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNvidia(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGpuName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addGpuIndices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.GPUInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.GPUInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.GPUInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.GPUInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsAmd();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIsNvidia();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getGpuName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGpuIndicesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional bool is_AMD = 1;
 * @return {boolean}
 */
proto.protos.GPUInfo.prototype.getIsAmd = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protos.GPUInfo} returns this
 */
proto.protos.GPUInfo.prototype.setIsAmd = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool is_NVIDIA = 2;
 * @return {boolean}
 */
proto.protos.GPUInfo.prototype.getIsNvidia = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protos.GPUInfo} returns this
 */
proto.protos.GPUInfo.prototype.setIsNvidia = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string gpu_name = 3;
 * @return {string}
 */
proto.protos.GPUInfo.prototype.getGpuName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.GPUInfo} returns this
 */
proto.protos.GPUInfo.prototype.setGpuName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string gpu_indices = 4;
 * @return {!Array<string>}
 */
proto.protos.GPUInfo.prototype.getGpuIndicesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.protos.GPUInfo} returns this
 */
proto.protos.GPUInfo.prototype.setGpuIndicesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.protos.GPUInfo} returns this
 */
proto.protos.GPUInfo.prototype.addGpuIndices = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protos.GPUInfo} returns this
 */
proto.protos.GPUInfo.prototype.clearGpuIndicesList = function() {
  return this.setGpuIndicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.CPUInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.CPUInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.CPUInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.CPUInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    numCpus: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.CPUInfo}
 */
proto.protos.CPUInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.CPUInfo;
  return proto.protos.CPUInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.CPUInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.CPUInfo}
 */
proto.protos.CPUInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumCpus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.CPUInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.CPUInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.CPUInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.CPUInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumCpus();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 num_cpus = 1;
 * @return {number}
 */
proto.protos.CPUInfo.prototype.getNumCpus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protos.CPUInfo} returns this
 */
proto.protos.CPUInfo.prototype.setNumCpus = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.SlurmJob.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.SlurmJob.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.SlurmJob} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.SlurmJob.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodes: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ntasks: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mem: jspb.Message.getFieldWithDefault(msg, 3, ""),
    time: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cpusPerTask: jspb.Message.getFieldWithDefault(msg, 5, ""),
    partition: jspb.Message.getFieldWithDefault(msg, 6, ""),
    jobName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    output: jspb.Message.getFieldWithDefault(msg, 8, ""),
    error: jspb.Message.getFieldWithDefault(msg, 9, ""),
    qos: jspb.Message.getFieldWithDefault(msg, 10, ""),
    args: jspb.Message.getFieldWithDefault(msg, 11, ""),
    constraint: jspb.Message.getFieldWithDefault(msg, 12, ""),
    options: jspb.Message.getFieldWithDefault(msg, 13, ""),
    account: jspb.Message.getFieldWithDefault(msg, 14, ""),
    mailUser: jspb.Message.getFieldWithDefault(msg, 15, ""),
    mailType: jspb.Message.getFieldWithDefault(msg, 16, ""),
    dependency: jspb.Message.getFieldWithDefault(msg, 17, ""),
    gres: jspb.Message.getFieldWithDefault(msg, 18, ""),
    reservation: jspb.Message.getFieldWithDefault(msg, 19, ""),
    exclude: jspb.Message.getFieldWithDefault(msg, 20, ""),
    licenses: jspb.Message.getFieldWithDefault(msg, 21, ""),
    exclusive: jspb.Message.getFieldWithDefault(msg, 22, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.SlurmJob}
 */
proto.protos.SlurmJob.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.SlurmJob;
  return proto.protos.SlurmJob.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.SlurmJob} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.SlurmJob}
 */
proto.protos.SlurmJob.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodes(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNtasks(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMem(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCpusPerTask(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartition(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutput(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setQos(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setArgs(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setConstraint(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptions(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setMailUser(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setMailType(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setDependency(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setGres(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservation(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setExclude(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenses(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setExclusive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.SlurmJob.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.SlurmJob.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.SlurmJob} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.SlurmJob.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodes();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNtasks();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMem();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCpusPerTask();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPartition();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getJobName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOutput();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getQos();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getArgs();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getConstraint();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getOptions();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getMailUser();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getMailType();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getDependency();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getGres();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getReservation();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getExclude();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getLicenses();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getExclusive();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
};


/**
 * optional string nodes = 1;
 * @return {string}
 */
proto.protos.SlurmJob.prototype.getNodes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.SlurmJob} returns this
 */
proto.protos.SlurmJob.prototype.setNodes = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ntasks = 2;
 * @return {string}
 */
proto.protos.SlurmJob.prototype.getNtasks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.SlurmJob} returns this
 */
proto.protos.SlurmJob.prototype.setNtasks = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string mem = 3;
 * @return {string}
 */
proto.protos.SlurmJob.prototype.getMem = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.SlurmJob} returns this
 */
proto.protos.SlurmJob.prototype.setMem = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string time = 4;
 * @return {string}
 */
proto.protos.SlurmJob.prototype.getTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.SlurmJob} returns this
 */
proto.protos.SlurmJob.prototype.setTime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string cpus_per_task = 5;
 * @return {string}
 */
proto.protos.SlurmJob.prototype.getCpusPerTask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.SlurmJob} returns this
 */
proto.protos.SlurmJob.prototype.setCpusPerTask = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string partition = 6;
 * @return {string}
 */
proto.protos.SlurmJob.prototype.getPartition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.SlurmJob} returns this
 */
proto.protos.SlurmJob.prototype.setPartition = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string job_name = 7;
 * @return {string}
 */
proto.protos.SlurmJob.prototype.getJobName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.SlurmJob} returns this
 */
proto.protos.SlurmJob.prototype.setJobName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string output = 8;
 * @return {string}
 */
proto.protos.SlurmJob.prototype.getOutput = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.SlurmJob} returns this
 */
proto.protos.SlurmJob.prototype.setOutput = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string error = 9;
 * @return {string}
 */
proto.protos.SlurmJob.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.SlurmJob} returns this
 */
proto.protos.SlurmJob.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string qos = 10;
 * @return {string}
 */
proto.protos.SlurmJob.prototype.getQos = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.SlurmJob} returns this
 */
proto.protos.SlurmJob.prototype.setQos = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string args = 11;
 * @return {string}
 */
proto.protos.SlurmJob.prototype.getArgs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.SlurmJob} returns this
 */
proto.protos.SlurmJob.prototype.setArgs = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string constraint = 12;
 * @return {string}
 */
proto.protos.SlurmJob.prototype.getConstraint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.SlurmJob} returns this
 */
proto.protos.SlurmJob.prototype.setConstraint = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string options = 13;
 * @return {string}
 */
proto.protos.SlurmJob.prototype.getOptions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.SlurmJob} returns this
 */
proto.protos.SlurmJob.prototype.setOptions = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string account = 14;
 * @return {string}
 */
proto.protos.SlurmJob.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.SlurmJob} returns this
 */
proto.protos.SlurmJob.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string mail_user = 15;
 * @return {string}
 */
proto.protos.SlurmJob.prototype.getMailUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.SlurmJob} returns this
 */
proto.protos.SlurmJob.prototype.setMailUser = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string mail_type = 16;
 * @return {string}
 */
proto.protos.SlurmJob.prototype.getMailType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.SlurmJob} returns this
 */
proto.protos.SlurmJob.prototype.setMailType = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string dependency = 17;
 * @return {string}
 */
proto.protos.SlurmJob.prototype.getDependency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.SlurmJob} returns this
 */
proto.protos.SlurmJob.prototype.setDependency = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string gres = 18;
 * @return {string}
 */
proto.protos.SlurmJob.prototype.getGres = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.SlurmJob} returns this
 */
proto.protos.SlurmJob.prototype.setGres = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string reservation = 19;
 * @return {string}
 */
proto.protos.SlurmJob.prototype.getReservation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.SlurmJob} returns this
 */
proto.protos.SlurmJob.prototype.setReservation = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string exclude = 20;
 * @return {string}
 */
proto.protos.SlurmJob.prototype.getExclude = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.SlurmJob} returns this
 */
proto.protos.SlurmJob.prototype.setExclude = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string licenses = 21;
 * @return {string}
 */
proto.protos.SlurmJob.prototype.getLicenses = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.SlurmJob} returns this
 */
proto.protos.SlurmJob.prototype.setLicenses = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string exclusive = 22;
 * @return {string}
 */
proto.protos.SlurmJob.prototype.getExclusive = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.SlurmJob} returns this
 */
proto.protos.SlurmJob.prototype.setExclusive = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.ServerAddress.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.ServerAddress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.ServerAddress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.ServerAddress.toObject = function(includeInstance, msg) {
  var f, obj = {
    ip: jspb.Message.getFieldWithDefault(msg, 1, ""),
    port: jspb.Message.getFieldWithDefault(msg, 2, ""),
    host: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.ServerAddress}
 */
proto.protos.ServerAddress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.ServerAddress;
  return proto.protos.ServerAddress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.ServerAddress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.ServerAddress}
 */
proto.protos.ServerAddress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPort(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.ServerAddress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.ServerAddress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.ServerAddress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.ServerAddress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIp();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPort();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string ip = 1;
 * @return {string}
 */
proto.protos.ServerAddress.prototype.getIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.ServerAddress} returns this
 */
proto.protos.ServerAddress.prototype.setIp = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string port = 2;
 * @return {string}
 */
proto.protos.ServerAddress.prototype.getPort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.ServerAddress} returns this
 */
proto.protos.ServerAddress.prototype.setPort = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string host = 3;
 * @return {string}
 */
proto.protos.ServerAddress.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.ServerAddress} returns this
 */
proto.protos.ServerAddress.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    output: jspb.Message.getFieldWithDefault(msg, 3, ""),
    exists: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.Status}
 */
proto.protos.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.Status;
  return proto.protos.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.Status}
 */
proto.protos.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutput(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExists(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOutput();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExists();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional bool error = 1;
 * @return {boolean}
 */
proto.protos.Status.prototype.getError = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protos.Status} returns this
 */
proto.protos.Status.prototype.setError = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.protos.Status.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Status} returns this
 */
proto.protos.Status.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string output = 3;
 * @return {string}
 */
proto.protos.Status.prototype.getOutput = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Status} returns this
 */
proto.protos.Status.prototype.setOutput = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool exists = 4;
 * @return {boolean}
 */
proto.protos.Status.prototype.getExists = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protos.Status} returns this
 */
proto.protos.Status.prototype.setExists = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.ServerData.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.ServerData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.ServerData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.ServerData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    host: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ip: jspb.Message.getFieldWithDefault(msg, 3, ""),
    port: jspb.Message.getFieldWithDefault(msg, 4, ""),
    username: jspb.Message.getFieldWithDefault(msg, 5, ""),
    password: jspb.Message.getFieldWithDefault(msg, 6, ""),
    passwordPassphrase: jspb.Message.getFieldWithDefault(msg, 7, ""),
    passwordNonce: jspb.Message.getFieldWithDefault(msg, 8, ""),
    passwordSalt: jspb.Message.getFieldWithDefault(msg, 9, ""),
    privateKey: jspb.Message.getFieldWithDefault(msg, 10, ""),
    privateKeySalt: jspb.Message.getFieldWithDefault(msg, 11, ""),
    privateKeyNonce: jspb.Message.getFieldWithDefault(msg, 12, ""),
    privateKeyPassphrase: jspb.Message.getFieldWithDefault(msg, 13, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.ServerData}
 */
proto.protos.ServerData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.ServerData;
  return proto.protos.ServerData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.ServerData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.ServerData}
 */
proto.protos.ServerData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPort(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPasswordPassphrase(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPasswordNonce(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPasswordSalt(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivateKey(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivateKeySalt(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivateKeyNonce(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivateKeyPassphrase(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.ServerData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.ServerData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.ServerData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.ServerData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIp();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPort();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPasswordPassphrase();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPasswordNonce();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPasswordSalt();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPrivateKey();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPrivateKeySalt();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPrivateKeyNonce();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPrivateKeyPassphrase();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.protos.ServerData.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protos.ServerData} returns this
 */
proto.protos.ServerData.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string host = 2;
 * @return {string}
 */
proto.protos.ServerData.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.ServerData} returns this
 */
proto.protos.ServerData.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ip = 3;
 * @return {string}
 */
proto.protos.ServerData.prototype.getIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.ServerData} returns this
 */
proto.protos.ServerData.prototype.setIp = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string port = 4;
 * @return {string}
 */
proto.protos.ServerData.prototype.getPort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.ServerData} returns this
 */
proto.protos.ServerData.prototype.setPort = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string username = 5;
 * @return {string}
 */
proto.protos.ServerData.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.ServerData} returns this
 */
proto.protos.ServerData.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string password = 6;
 * @return {string}
 */
proto.protos.ServerData.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.ServerData} returns this
 */
proto.protos.ServerData.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string password_passphrase = 7;
 * @return {string}
 */
proto.protos.ServerData.prototype.getPasswordPassphrase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.ServerData} returns this
 */
proto.protos.ServerData.prototype.setPasswordPassphrase = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string password_nonce = 8;
 * @return {string}
 */
proto.protos.ServerData.prototype.getPasswordNonce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.ServerData} returns this
 */
proto.protos.ServerData.prototype.setPasswordNonce = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string password_salt = 9;
 * @return {string}
 */
proto.protos.ServerData.prototype.getPasswordSalt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.ServerData} returns this
 */
proto.protos.ServerData.prototype.setPasswordSalt = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string private_key = 10;
 * @return {string}
 */
proto.protos.ServerData.prototype.getPrivateKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.ServerData} returns this
 */
proto.protos.ServerData.prototype.setPrivateKey = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string private_key_salt = 11;
 * @return {string}
 */
proto.protos.ServerData.prototype.getPrivateKeySalt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.ServerData} returns this
 */
proto.protos.ServerData.prototype.setPrivateKeySalt = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string private_key_nonce = 12;
 * @return {string}
 */
proto.protos.ServerData.prototype.getPrivateKeyNonce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.ServerData} returns this
 */
proto.protos.ServerData.prototype.setPrivateKeyNonce = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string private_key_passphrase = 13;
 * @return {string}
 */
proto.protos.ServerData.prototype.getPrivateKeyPassphrase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.ServerData} returns this
 */
proto.protos.ServerData.prototype.setPrivateKeyPassphrase = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string created_at = 14;
 * @return {string}
 */
proto.protos.ServerData.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.ServerData} returns this
 */
proto.protos.ServerData.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.Instance.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.Instance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.Instance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.Instance.toObject = function(includeInstance, msg) {
  var f, obj = {
    instanceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timeStart: jspb.Message.getFieldWithDefault(msg, 2, ""),
    timeEnd: jspb.Message.getFieldWithDefault(msg, 3, ""),
    numOfInstances: jspb.Message.getFieldWithDefault(msg, 4, 0),
    numOfGpusPerInstance: jspb.Message.getFieldWithDefault(msg, 5, 0),
    gpuInfo: (f = msg.getGpuInfo()) && proto.protos.GPUInfo.toObject(includeInstance, f),
    cpuInfo: (f = msg.getCpuInfo()) && proto.protos.CPUInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.Instance}
 */
proto.protos.Instance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.Instance;
  return proto.protos.Instance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.Instance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.Instance}
 */
proto.protos.Instance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeStart(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeEnd(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumOfInstances(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumOfGpusPerInstance(value);
      break;
    case 6:
      var value = new proto.protos.GPUInfo;
      reader.readMessage(value,proto.protos.GPUInfo.deserializeBinaryFromReader);
      msg.setGpuInfo(value);
      break;
    case 7:
      var value = new proto.protos.CPUInfo;
      reader.readMessage(value,proto.protos.CPUInfo.deserializeBinaryFromReader);
      msg.setCpuInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.Instance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.Instance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.Instance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.Instance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstanceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimeStart();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTimeEnd();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNumOfInstances();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getNumOfGpusPerInstance();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getGpuInfo();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.protos.GPUInfo.serializeBinaryToWriter
    );
  }
  f = message.getCpuInfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.protos.CPUInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string instance_id = 1;
 * @return {string}
 */
proto.protos.Instance.prototype.getInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Instance} returns this
 */
proto.protos.Instance.prototype.setInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string time_start = 2;
 * @return {string}
 */
proto.protos.Instance.prototype.getTimeStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Instance} returns this
 */
proto.protos.Instance.prototype.setTimeStart = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string time_end = 3;
 * @return {string}
 */
proto.protos.Instance.prototype.getTimeEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Instance} returns this
 */
proto.protos.Instance.prototype.setTimeEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 num_of_instances = 4;
 * @return {number}
 */
proto.protos.Instance.prototype.getNumOfInstances = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protos.Instance} returns this
 */
proto.protos.Instance.prototype.setNumOfInstances = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 num_of_gpus_per_instance = 5;
 * @return {number}
 */
proto.protos.Instance.prototype.getNumOfGpusPerInstance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protos.Instance} returns this
 */
proto.protos.Instance.prototype.setNumOfGpusPerInstance = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional GPUInfo gpu_info = 6;
 * @return {?proto.protos.GPUInfo}
 */
proto.protos.Instance.prototype.getGpuInfo = function() {
  return /** @type{?proto.protos.GPUInfo} */ (
    jspb.Message.getWrapperField(this, proto.protos.GPUInfo, 6));
};


/**
 * @param {?proto.protos.GPUInfo|undefined} value
 * @return {!proto.protos.Instance} returns this
*/
proto.protos.Instance.prototype.setGpuInfo = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.Instance} returns this
 */
proto.protos.Instance.prototype.clearGpuInfo = function() {
  return this.setGpuInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.Instance.prototype.hasGpuInfo = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional CPUInfo cpu_info = 7;
 * @return {?proto.protos.CPUInfo}
 */
proto.protos.Instance.prototype.getCpuInfo = function() {
  return /** @type{?proto.protos.CPUInfo} */ (
    jspb.Message.getWrapperField(this, proto.protos.CPUInfo, 7));
};


/**
 * @param {?proto.protos.CPUInfo|undefined} value
 * @return {!proto.protos.Instance} returns this
*/
proto.protos.Instance.prototype.setCpuInfo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.Instance} returns this
 */
proto.protos.Instance.prototype.clearCpuInfo = function() {
  return this.setCpuInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.Instance.prototype.hasCpuInfo = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.NodeTypes.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.NodeTypes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.NodeTypes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.NodeTypes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSlurmNode: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    isSingleNode: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.NodeTypes}
 */
proto.protos.NodeTypes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.NodeTypes;
  return proto.protos.NodeTypes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.NodeTypes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.NodeTypes}
 */
proto.protos.NodeTypes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSlurmNode(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSingleNode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.NodeTypes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.NodeTypes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.NodeTypes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.NodeTypes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSlurmNode();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIsSingleNode();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool is_slurm_node = 1;
 * @return {boolean}
 */
proto.protos.NodeTypes.prototype.getIsSlurmNode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protos.NodeTypes} returns this
 */
proto.protos.NodeTypes.prototype.setIsSlurmNode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool is_single_node = 2;
 * @return {boolean}
 */
proto.protos.NodeTypes.prototype.getIsSingleNode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protos.NodeTypes} returns this
 */
proto.protos.NodeTypes.prototype.setIsSingleNode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.PrivateKey.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.PrivateKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.PrivateKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.PrivateKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    privateKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    salt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    nonce: jspb.Message.getFieldWithDefault(msg, 3, ""),
    passphrase: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.PrivateKey}
 */
proto.protos.PrivateKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.PrivateKey;
  return proto.protos.PrivateKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.PrivateKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.PrivateKey}
 */
proto.protos.PrivateKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivateKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNonce(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassphrase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.PrivateKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.PrivateKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.PrivateKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.PrivateKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrivateKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSalt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNonce();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPassphrase();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string private_key = 1;
 * @return {string}
 */
proto.protos.PrivateKey.prototype.getPrivateKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.PrivateKey} returns this
 */
proto.protos.PrivateKey.prototype.setPrivateKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string salt = 2;
 * @return {string}
 */
proto.protos.PrivateKey.prototype.getSalt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.PrivateKey} returns this
 */
proto.protos.PrivateKey.prototype.setSalt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string nonce = 3;
 * @return {string}
 */
proto.protos.PrivateKey.prototype.getNonce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.PrivateKey} returns this
 */
proto.protos.PrivateKey.prototype.setNonce = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string passphrase = 4;
 * @return {string}
 */
proto.protos.PrivateKey.prototype.getPassphrase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.PrivateKey} returns this
 */
proto.protos.PrivateKey.prototype.setPassphrase = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.PublicKey.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.PublicKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.PublicKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.PublicKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    publicKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    salt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    nonce: jspb.Message.getFieldWithDefault(msg, 3, ""),
    passphrase: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.PublicKey}
 */
proto.protos.PublicKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.PublicKey;
  return proto.protos.PublicKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.PublicKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.PublicKey}
 */
proto.protos.PublicKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNonce(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassphrase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.PublicKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.PublicKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.PublicKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.PublicKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPublicKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSalt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNonce();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPassphrase();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string public_key = 1;
 * @return {string}
 */
proto.protos.PublicKey.prototype.getPublicKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.PublicKey} returns this
 */
proto.protos.PublicKey.prototype.setPublicKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string salt = 2;
 * @return {string}
 */
proto.protos.PublicKey.prototype.getSalt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.PublicKey} returns this
 */
proto.protos.PublicKey.prototype.setSalt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string nonce = 3;
 * @return {string}
 */
proto.protos.PublicKey.prototype.getNonce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.PublicKey} returns this
 */
proto.protos.PublicKey.prototype.setNonce = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string passphrase = 4;
 * @return {string}
 */
proto.protos.PublicKey.prototype.getPassphrase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.PublicKey} returns this
 */
proto.protos.PublicKey.prototype.setPassphrase = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.UserCredential.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.UserCredential.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.UserCredential} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.UserCredential.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    password: (f = msg.getPassword()) && proto.protos.Password.toObject(includeInstance, f),
    groups: (f = msg.getGroups()) && proto.protos.Groups.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.UserCredential}
 */
proto.protos.UserCredential.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.UserCredential;
  return proto.protos.UserCredential.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.UserCredential} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.UserCredential}
 */
proto.protos.UserCredential.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = new proto.protos.Password;
      reader.readMessage(value,proto.protos.Password.deserializeBinaryFromReader);
      msg.setPassword(value);
      break;
    case 4:
      var value = new proto.protos.Groups;
      reader.readMessage(value,proto.protos.Groups.deserializeBinaryFromReader);
      msg.setGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.UserCredential.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.UserCredential.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.UserCredential} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.UserCredential.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPassword();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protos.Password.serializeBinaryToWriter
    );
  }
  f = message.getGroups();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.protos.Groups.serializeBinaryToWriter
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.protos.UserCredential.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.UserCredential} returns this
 */
proto.protos.UserCredential.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.protos.UserCredential.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.UserCredential} returns this
 */
proto.protos.UserCredential.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Password password = 3;
 * @return {?proto.protos.Password}
 */
proto.protos.UserCredential.prototype.getPassword = function() {
  return /** @type{?proto.protos.Password} */ (
    jspb.Message.getWrapperField(this, proto.protos.Password, 3));
};


/**
 * @param {?proto.protos.Password|undefined} value
 * @return {!proto.protos.UserCredential} returns this
*/
proto.protos.UserCredential.prototype.setPassword = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.UserCredential} returns this
 */
proto.protos.UserCredential.prototype.clearPassword = function() {
  return this.setPassword(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.UserCredential.prototype.hasPassword = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Groups groups = 4;
 * @return {?proto.protos.Groups}
 */
proto.protos.UserCredential.prototype.getGroups = function() {
  return /** @type{?proto.protos.Groups} */ (
    jspb.Message.getWrapperField(this, proto.protos.Groups, 4));
};


/**
 * @param {?proto.protos.Groups|undefined} value
 * @return {!proto.protos.UserCredential} returns this
*/
proto.protos.UserCredential.prototype.setGroups = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.UserCredential} returns this
 */
proto.protos.UserCredential.prototype.clearGroups = function() {
  return this.setGroups(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.UserCredential.prototype.hasGroups = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.Password.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.Password.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.Password} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.Password.toObject = function(includeInstance, msg) {
  var f, obj = {
    password: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nonce: jspb.Message.getFieldWithDefault(msg, 2, ""),
    salt: jspb.Message.getFieldWithDefault(msg, 3, ""),
    passphrase: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.Password}
 */
proto.protos.Password.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.Password;
  return proto.protos.Password.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.Password} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.Password}
 */
proto.protos.Password.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNonce(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassphrase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.Password.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.Password.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.Password} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.Password.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNonce();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSalt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPassphrase();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string password = 1;
 * @return {string}
 */
proto.protos.Password.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Password} returns this
 */
proto.protos.Password.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string nonce = 2;
 * @return {string}
 */
proto.protos.Password.prototype.getNonce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Password} returns this
 */
proto.protos.Password.prototype.setNonce = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string salt = 3;
 * @return {string}
 */
proto.protos.Password.prototype.getSalt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Password} returns this
 */
proto.protos.Password.prototype.setSalt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string passphrase = 4;
 * @return {string}
 */
proto.protos.Password.prototype.getPassphrase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Password} returns this
 */
proto.protos.Password.prototype.setPassphrase = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.Groups.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.Groups.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.Groups} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.Groups.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSlurmGroup: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    isDockerGroup: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    isSudoGroup: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    groupName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.Groups}
 */
proto.protos.Groups.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.Groups;
  return proto.protos.Groups.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.Groups} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.Groups}
 */
proto.protos.Groups.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSlurmGroup(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDockerGroup(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSudoGroup(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.Groups.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.Groups.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.Groups} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.Groups.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSlurmGroup();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIsDockerGroup();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIsSudoGroup();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getGroupName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional bool is_slurm_group = 1;
 * @return {boolean}
 */
proto.protos.Groups.prototype.getIsSlurmGroup = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protos.Groups} returns this
 */
proto.protos.Groups.prototype.setIsSlurmGroup = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool is_docker_group = 2;
 * @return {boolean}
 */
proto.protos.Groups.prototype.getIsDockerGroup = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protos.Groups} returns this
 */
proto.protos.Groups.prototype.setIsDockerGroup = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool is_sudo_group = 3;
 * @return {boolean}
 */
proto.protos.Groups.prototype.getIsSudoGroup = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protos.Groups} returns this
 */
proto.protos.Groups.prototype.setIsSudoGroup = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string group_name = 4;
 * @return {string}
 */
proto.protos.Groups.prototype.getGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Groups} returns this
 */
proto.protos.Groups.prototype.setGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.DockerImage.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.DockerImage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.DockerImage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.DockerImage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dockerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    repository: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tag: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.DockerImage}
 */
proto.protos.DockerImage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.DockerImage;
  return proto.protos.DockerImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.DockerImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.DockerImage}
 */
proto.protos.DockerImage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDockerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepository(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.DockerImage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.DockerImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.DockerImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.DockerImage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDockerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRepository();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.protos.DockerImage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.DockerImage} returns this
 */
proto.protos.DockerImage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string docker_id = 2;
 * @return {string}
 */
proto.protos.DockerImage.prototype.getDockerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.DockerImage} returns this
 */
proto.protos.DockerImage.prototype.setDockerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string repository = 3;
 * @return {string}
 */
proto.protos.DockerImage.prototype.getRepository = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.DockerImage} returns this
 */
proto.protos.DockerImage.prototype.setRepository = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.protos.DockerImage.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.DockerImage} returns this
 */
proto.protos.DockerImage.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string tag = 5;
 * @return {string}
 */
proto.protos.DockerImage.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.DockerImage} returns this
 */
proto.protos.DockerImage.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.DockerContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.DockerContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.DockerContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.DockerContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dockerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imageName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 5, ""),
    portMappings: jspb.Message.getFieldWithDefault(msg, 6, ""),
    environmentVariables: jspb.Message.getFieldWithDefault(msg, 7, ""),
    detached: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.DockerContainer}
 */
proto.protos.DockerContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.DockerContainer;
  return proto.protos.DockerContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.DockerContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.DockerContainer}
 */
proto.protos.DockerContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDockerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPortMappings(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnvironmentVariables(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDetached(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.DockerContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.DockerContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.DockerContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.DockerContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDockerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImageName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPortMappings();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEnvironmentVariables();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDetached();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.protos.DockerContainer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.DockerContainer} returns this
 */
proto.protos.DockerContainer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string docker_id = 2;
 * @return {string}
 */
proto.protos.DockerContainer.prototype.getDockerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.DockerContainer} returns this
 */
proto.protos.DockerContainer.prototype.setDockerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string image_name = 3;
 * @return {string}
 */
proto.protos.DockerContainer.prototype.getImageName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.DockerContainer} returns this
 */
proto.protos.DockerContainer.prototype.setImageName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.protos.DockerContainer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.DockerContainer} returns this
 */
proto.protos.DockerContainer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string status = 5;
 * @return {string}
 */
proto.protos.DockerContainer.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.DockerContainer} returns this
 */
proto.protos.DockerContainer.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string port_mappings = 6;
 * @return {string}
 */
proto.protos.DockerContainer.prototype.getPortMappings = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.DockerContainer} returns this
 */
proto.protos.DockerContainer.prototype.setPortMappings = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string environment_variables = 7;
 * @return {string}
 */
proto.protos.DockerContainer.prototype.getEnvironmentVariables = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.DockerContainer} returns this
 */
proto.protos.DockerContainer.prototype.setEnvironmentVariables = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool detached = 8;
 * @return {boolean}
 */
proto.protos.DockerContainer.prototype.getDetached = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protos.DockerContainer} returns this
 */
proto.protos.DockerContainer.prototype.setDetached = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.DockerCredentials.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.DockerCredentials.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.DockerCredentials} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.DockerCredentials.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.DockerCredentials}
 */
proto.protos.DockerCredentials.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.DockerCredentials;
  return proto.protos.DockerCredentials.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.DockerCredentials} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.DockerCredentials}
 */
proto.protos.DockerCredentials.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.DockerCredentials.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.DockerCredentials.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.DockerCredentials} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.DockerCredentials.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.protos.DockerCredentials.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.DockerCredentials} returns this
 */
proto.protos.DockerCredentials.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.protos.DockerCredentials.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.DockerCredentials} returns this
 */
proto.protos.DockerCredentials.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protos.DockerStatus.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.DockerStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.DockerStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.DockerStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.DockerStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    exists: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    isRunning: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    statusMessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    uptime: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, ""),
    startedAt: jspb.Message.getFieldWithDefault(msg, 6, ""),
    portsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    mountedVolumesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.DockerStatus}
 */
proto.protos.DockerStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.DockerStatus;
  return proto.protos.DockerStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.DockerStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.DockerStatus}
 */
proto.protos.DockerStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExists(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRunning(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusMessage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUptime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartedAt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addPorts(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addMountedVolumes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.DockerStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.DockerStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.DockerStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.DockerStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExists();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIsRunning();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getStatusMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUptime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStartedAt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPortsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getMountedVolumesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
};


/**
 * optional bool exists = 1;
 * @return {boolean}
 */
proto.protos.DockerStatus.prototype.getExists = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protos.DockerStatus} returns this
 */
proto.protos.DockerStatus.prototype.setExists = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool is_running = 2;
 * @return {boolean}
 */
proto.protos.DockerStatus.prototype.getIsRunning = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protos.DockerStatus} returns this
 */
proto.protos.DockerStatus.prototype.setIsRunning = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string status_message = 3;
 * @return {string}
 */
proto.protos.DockerStatus.prototype.getStatusMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.DockerStatus} returns this
 */
proto.protos.DockerStatus.prototype.setStatusMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string uptime = 4;
 * @return {string}
 */
proto.protos.DockerStatus.prototype.getUptime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.DockerStatus} returns this
 */
proto.protos.DockerStatus.prototype.setUptime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string created_at = 5;
 * @return {string}
 */
proto.protos.DockerStatus.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.DockerStatus} returns this
 */
proto.protos.DockerStatus.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string started_at = 6;
 * @return {string}
 */
proto.protos.DockerStatus.prototype.getStartedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.DockerStatus} returns this
 */
proto.protos.DockerStatus.prototype.setStartedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string ports = 7;
 * @return {!Array<string>}
 */
proto.protos.DockerStatus.prototype.getPortsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.protos.DockerStatus} returns this
 */
proto.protos.DockerStatus.prototype.setPortsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.protos.DockerStatus} returns this
 */
proto.protos.DockerStatus.prototype.addPorts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protos.DockerStatus} returns this
 */
proto.protos.DockerStatus.prototype.clearPortsList = function() {
  return this.setPortsList([]);
};


/**
 * repeated string mounted_volumes = 8;
 * @return {!Array<string>}
 */
proto.protos.DockerStatus.prototype.getMountedVolumesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.protos.DockerStatus} returns this
 */
proto.protos.DockerStatus.prototype.setMountedVolumesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.protos.DockerStatus} returns this
 */
proto.protos.DockerStatus.prototype.addMountedVolumes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protos.DockerStatus} returns this
 */
proto.protos.DockerStatus.prototype.clearMountedVolumesList = function() {
  return this.setMountedVolumesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.Docker.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.Docker.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.Docker} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.Docker.toObject = function(includeInstance, msg) {
  var f, obj = {
    dockerCredentials: (f = msg.getDockerCredentials()) && proto.protos.DockerCredentials.toObject(includeInstance, f),
    dockerImage: (f = msg.getDockerImage()) && proto.protos.DockerImage.toObject(includeInstance, f),
    dockerContainer: (f = msg.getDockerContainer()) && proto.protos.DockerContainer.toObject(includeInstance, f),
    dockerStatus: (f = msg.getDockerStatus()) && proto.protos.DockerStatus.toObject(includeInstance, f),
    dockerArguments: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.Docker}
 */
proto.protos.Docker.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.Docker;
  return proto.protos.Docker.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.Docker} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.Docker}
 */
proto.protos.Docker.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.protos.DockerCredentials;
      reader.readMessage(value,proto.protos.DockerCredentials.deserializeBinaryFromReader);
      msg.setDockerCredentials(value);
      break;
    case 2:
      var value = new proto.protos.DockerImage;
      reader.readMessage(value,proto.protos.DockerImage.deserializeBinaryFromReader);
      msg.setDockerImage(value);
      break;
    case 3:
      var value = new proto.protos.DockerContainer;
      reader.readMessage(value,proto.protos.DockerContainer.deserializeBinaryFromReader);
      msg.setDockerContainer(value);
      break;
    case 4:
      var value = new proto.protos.DockerStatus;
      reader.readMessage(value,proto.protos.DockerStatus.deserializeBinaryFromReader);
      msg.setDockerStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDockerArguments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.Docker.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.Docker.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.Docker} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.Docker.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDockerCredentials();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.protos.DockerCredentials.serializeBinaryToWriter
    );
  }
  f = message.getDockerImage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.protos.DockerImage.serializeBinaryToWriter
    );
  }
  f = message.getDockerContainer();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.protos.DockerContainer.serializeBinaryToWriter
    );
  }
  f = message.getDockerStatus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.protos.DockerStatus.serializeBinaryToWriter
    );
  }
  f = message.getDockerArguments();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional DockerCredentials docker_credentials = 1;
 * @return {?proto.protos.DockerCredentials}
 */
proto.protos.Docker.prototype.getDockerCredentials = function() {
  return /** @type{?proto.protos.DockerCredentials} */ (
    jspb.Message.getWrapperField(this, proto.protos.DockerCredentials, 1));
};


/**
 * @param {?proto.protos.DockerCredentials|undefined} value
 * @return {!proto.protos.Docker} returns this
*/
proto.protos.Docker.prototype.setDockerCredentials = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.Docker} returns this
 */
proto.protos.Docker.prototype.clearDockerCredentials = function() {
  return this.setDockerCredentials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.Docker.prototype.hasDockerCredentials = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DockerImage docker_image = 2;
 * @return {?proto.protos.DockerImage}
 */
proto.protos.Docker.prototype.getDockerImage = function() {
  return /** @type{?proto.protos.DockerImage} */ (
    jspb.Message.getWrapperField(this, proto.protos.DockerImage, 2));
};


/**
 * @param {?proto.protos.DockerImage|undefined} value
 * @return {!proto.protos.Docker} returns this
*/
proto.protos.Docker.prototype.setDockerImage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.Docker} returns this
 */
proto.protos.Docker.prototype.clearDockerImage = function() {
  return this.setDockerImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.Docker.prototype.hasDockerImage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DockerContainer docker_container = 3;
 * @return {?proto.protos.DockerContainer}
 */
proto.protos.Docker.prototype.getDockerContainer = function() {
  return /** @type{?proto.protos.DockerContainer} */ (
    jspb.Message.getWrapperField(this, proto.protos.DockerContainer, 3));
};


/**
 * @param {?proto.protos.DockerContainer|undefined} value
 * @return {!proto.protos.Docker} returns this
*/
proto.protos.Docker.prototype.setDockerContainer = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.Docker} returns this
 */
proto.protos.Docker.prototype.clearDockerContainer = function() {
  return this.setDockerContainer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.Docker.prototype.hasDockerContainer = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DockerStatus docker_status = 4;
 * @return {?proto.protos.DockerStatus}
 */
proto.protos.Docker.prototype.getDockerStatus = function() {
  return /** @type{?proto.protos.DockerStatus} */ (
    jspb.Message.getWrapperField(this, proto.protos.DockerStatus, 4));
};


/**
 * @param {?proto.protos.DockerStatus|undefined} value
 * @return {!proto.protos.Docker} returns this
*/
proto.protos.Docker.prototype.setDockerStatus = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protos.Docker} returns this
 */
proto.protos.Docker.prototype.clearDockerStatus = function() {
  return this.setDockerStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protos.Docker.prototype.hasDockerStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string docker_arguments = 5;
 * @return {string}
 */
proto.protos.Docker.prototype.getDockerArguments = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Docker} returns this
 */
proto.protos.Docker.prototype.setDockerArguments = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.Job.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.Job.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.Job} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.Job.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imageId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    instanceId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    jobType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, ""),
    command: jspb.Message.getFieldWithDefault(msg, 7, ""),
    pid: jspb.Message.getFieldWithDefault(msg, 8, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 9, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.Job}
 */
proto.protos.Job.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.Job;
  return proto.protos.Job.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.Job} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.Job}
 */
proto.protos.Job.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommand(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.Job.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.Job.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.Job} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.Job.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImageId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInstanceId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getJobType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCommand();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.protos.Job.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Job} returns this
 */
proto.protos.Job.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.protos.Job.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Job} returns this
 */
proto.protos.Job.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string image_id = 3;
 * @return {string}
 */
proto.protos.Job.prototype.getImageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Job} returns this
 */
proto.protos.Job.prototype.setImageId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string instance_id = 4;
 * @return {string}
 */
proto.protos.Job.prototype.getInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Job} returns this
 */
proto.protos.Job.prototype.setInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string job_type = 5;
 * @return {string}
 */
proto.protos.Job.prototype.getJobType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Job} returns this
 */
proto.protos.Job.prototype.setJobType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string status = 6;
 * @return {string}
 */
proto.protos.Job.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Job} returns this
 */
proto.protos.Job.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string command = 7;
 * @return {string}
 */
proto.protos.Job.prototype.getCommand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Job} returns this
 */
proto.protos.Job.prototype.setCommand = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 pid = 8;
 * @return {number}
 */
proto.protos.Job.prototype.getPid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.protos.Job} returns this
 */
proto.protos.Job.prototype.setPid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string created_at = 9;
 * @return {string}
 */
proto.protos.Job.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Job} returns this
 */
proto.protos.Job.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string updated_at = 10;
 * @return {string}
 */
proto.protos.Job.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Job} returns this
 */
proto.protos.Job.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protos.Image.prototype.toObject = function(opt_includeInstance) {
  return proto.protos.Image.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protos.Image} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.Image.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    repository: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tag: jspb.Message.getFieldWithDefault(msg, 4, ""),
    os: jspb.Message.getFieldWithDefault(msg, 5, ""),
    osVersion: jspb.Message.getFieldWithDefault(msg, 6, ""),
    commands: jspb.Message.getFieldWithDefault(msg, 7, ""),
    description: jspb.Message.getFieldWithDefault(msg, 8, ""),
    username: jspb.Message.getFieldWithDefault(msg, 9, ""),
    password: jspb.Message.getFieldWithDefault(msg, 10, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 11, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protos.Image}
 */
proto.protos.Image.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protos.Image;
  return proto.protos.Image.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protos.Image} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protos.Image}
 */
proto.protos.Image.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepository(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOs(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOsVersion(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommands(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protos.Image.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protos.Image.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protos.Image} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protos.Image.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRepository();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOs();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOsVersion();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCommands();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.protos.Image.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Image} returns this
 */
proto.protos.Image.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string repository = 2;
 * @return {string}
 */
proto.protos.Image.prototype.getRepository = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Image} returns this
 */
proto.protos.Image.prototype.setRepository = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.protos.Image.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Image} returns this
 */
proto.protos.Image.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tag = 4;
 * @return {string}
 */
proto.protos.Image.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Image} returns this
 */
proto.protos.Image.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string os = 5;
 * @return {string}
 */
proto.protos.Image.prototype.getOs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Image} returns this
 */
proto.protos.Image.prototype.setOs = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string os_version = 6;
 * @return {string}
 */
proto.protos.Image.prototype.getOsVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Image} returns this
 */
proto.protos.Image.prototype.setOsVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string commands = 7;
 * @return {string}
 */
proto.protos.Image.prototype.getCommands = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Image} returns this
 */
proto.protos.Image.prototype.setCommands = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.protos.Image.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Image} returns this
 */
proto.protos.Image.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string username = 9;
 * @return {string}
 */
proto.protos.Image.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Image} returns this
 */
proto.protos.Image.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string password = 10;
 * @return {string}
 */
proto.protos.Image.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Image} returns this
 */
proto.protos.Image.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string created_at = 11;
 * @return {string}
 */
proto.protos.Image.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Image} returns this
 */
proto.protos.Image.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string updated_at = 12;
 * @return {string}
 */
proto.protos.Image.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.protos.Image} returns this
 */
proto.protos.Image.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


goog.object.extend(exports, proto.protos);
