import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './authentication/AuthContext';
import LandingPage from './pages/LandingPage';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Models from './pages/Models';
import Training from './pages/Training';
import CloudManagement from './pages/CloudManagement';
import Marketplace from './pages/Marketplace';
import MeetingSummarizer from './marketplace/MeetingSummarizer';
import PioChat from './marketplace/PioChat';
import Monitoring from './cloudmanagement/Monitoring';
import DiscoverInstances from './cloudmanagement/DiscoverInstances';
import TrainingJobMonitoring from './training/TrainingJobMonitoring';
import Register from './authentication/Registration';
import PaaS from './pages/PaaS';
import Settings from './pages/Settings';
import Test from './pages/Test';
import HashCat from './marketplace/HashCat';

import { KeycloakRoute } from './authentication/KeycloakRoute';
import { LoginTwoTone, LogoutOutlined } from '@mui/icons-material';
import Deployment from './pages/Deployment';

export default function RenderContent() {
  const { user } = useAuth();
  const location = useLocation();

  return (
    <Routes>
      {/* Public routes */}
      <Route path="/" element={
        user ? (
          <Navigate 
            to="/dashboard" 
            replace={true}
            state={{ from: location.pathname }}
          />
        ) : (
          <LandingPage />
        )
      } />
      <Route path="/register" element={<Register />} />
      

      {/* Protected routes */}
      <Route path="/home" element={
        <KeycloakRoute>
          <Home />
        </KeycloakRoute>
      } />
      {/* Protected routes */}
      <Route path="/dashboard" element={
        <KeycloakRoute>
          <Dashboard />
        </KeycloakRoute>
      } />
      <Route path="/models" element={
        <KeycloakRoute requiredRoles={['user']}>
          <Models />
        </KeycloakRoute>
      } />
      <Route path="/training" element={
        <KeycloakRoute requiredRoles={['user']}>
          <Training />
        </KeycloakRoute>
      } />
      <Route path="/training/monitor/:jobId" element={
        <KeycloakRoute requiredRoles={['user']}>
          <TrainingJobMonitoring />
        </KeycloakRoute>
      } />
      <Route path="/cloud-management" element={
        <KeycloakRoute requiredRoles={['user']}>
          <CloudManagement title="Cloud Management" />
        </KeycloakRoute>
      } />
      <Route path="/discover-instances" element={
        <KeycloakRoute requiredRoles={['user']}>
          <DiscoverInstances />
        </KeycloakRoute>
      } />
      <Route path="/marketplace" element={
        <KeycloakRoute requiredRoles={['user']}>
          <Marketplace />
        </KeycloakRoute>
      } />
      <Route path="/meeting-summarizer" element={
        <KeycloakRoute requiredRoles={['user']}>
          <MeetingSummarizer />
        </KeycloakRoute>
      } />
      <Route path="/hashcat" element={
        <KeycloakRoute requiredRoles={['user']}>
          <HashCat />
        </KeycloakRoute>
      } />
      <Route path="/piochat" element={
        <KeycloakRoute requiredRoles={['user']}>
          <PioChat />
        </KeycloakRoute>
      } />
      <Route path="/paas" element={
        <KeycloakRoute requiredRoles={['user']}>
          <PaaS />
        </KeycloakRoute>
      } />
      <Route path="/monitoring/:serverIp/:port/:hostname" element={
        <KeycloakRoute requiredRoles={['user']}>
          <Monitoring />
        </KeycloakRoute>
      } />
      <Route path="/test" element={
        <KeycloakRoute requiredRoles={['user']}>
          <Test />
        </KeycloakRoute>
      } />
      
      <Route path="/deployment" element={
        <KeycloakRoute requiredRoles={['user']}>
          <Deployment />
        </KeycloakRoute>
      } />
      <Route path="/settings" element={
        <KeycloakRoute requiredRoles={['user']}>
          <Settings />
        </KeycloakRoute>
      } />
    </Routes>
  );
}