import React, { useState } from 'react';
import { Typography, Card, CardContent, Grid, TextField, InputAdornment, Button, Tabs, Tab, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import StarIcon from '@mui/icons-material/Star';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useNavigate } from 'react-router-dom';

const marketplaceItems = [
  { id: 1, name: "No-Code AI", description: "Build AI solutions without coding", rating: 4.9, downloads: "20,000", category: "Productivity", installed: true },
  { id: 2, name: "Meeting Summarizer", description: "Automatically generate meeting summaries", rating: 4.2, downloads: "5,000", category: "Productivity", installed: true },
  { id: 3, name: "PioChat", description: "AI-powered chatbot for various tasks", rating: 4.8, downloads: "15,000", category: "Productivity", installed: true },
  { id: 4, name: "PaaS", description: "Platform as a Service for AI model deployment", rating: 4.6, downloads: "12,000", category: "Integration", installed: true },
  { id: 5, name: "Document Reader", description: "Extract and analyze text from documents", rating: 4.7, downloads: "8,000", category: "Analysis", installed: false },
  { id: 6, name: "Code Assistant", description: "AI-powered code completion and suggestions", rating: 4.5, downloads: "10,000", category: "Productivity", installed: false },
  { id: 7, name: "HashCat", description: "Crack hashes with a powerful tool", rating: 4.3, downloads: "6,000", category: "Analysis", installed: true },
];

export default function Marketplace() {
  const [tabValue, setTabValue] = useState(0);
  const [filteredItems, setFilteredItems] = useState(marketplaceItems);
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    filterItems(newValue);
  };

  const filterItems = (tabIndex) => {
    const categories = ["All", "Productivity", "Analysis", "Integration"];
    const selectedCategory = categories[tabIndex];
    setFilteredItems(
      selectedCategory === "All"
        ? marketplaceItems
        : marketplaceItems.filter(item => item.category === selectedCategory)
    );
  };

  const handleItemClick = (item) => {
    if (item.name === "Meeting Summarizer" && item.installed) {
      navigate('/meeting-summarizer');
    } else if (item.name === "PioChat" && item.installed) {
      navigate('/piochat');
    } else if (item.name === "PaaS" && item.installed) {
      navigate('/paas');
    } else if (item.name === "No-Code AI" && item.installed) {
      navigate('/no-code-ai');
    } else if (item.name === "HashCat" && item.installed) {
      navigate('/hashcat');
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>Marketplace</Typography>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search apps"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ mb: 2 }}
      />
      <Tabs value={tabValue} onChange={handleTabChange} sx={{ mb: 2 }}>
        <Tab label="All" />
        <Tab label="Productivity" />
        <Tab label="Analysis" />
        <Tab label="Integration" />
      </Tabs>
      <Grid container spacing={3}>
        {filteredItems.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <Card 
              onClick={() => handleItemClick(item)} 
              sx={{ 
                cursor: 'pointer',
                height: '100%',  // Make all cards the same height
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom>{item.name}</Typography>
                {item.installed && (
                  <Typography variant="body2" color="success.main" sx={{ mb: 1 }}>
                    Installed
                  </Typography>
                )}
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  {item.description}
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <StarIcon sx={{ color: 'gold', mr: 0.5 }} />
                    <Typography variant="body2" sx={{ mr: 1 }}>{item.rating}</Typography>
                  </div>
                  <Typography variant="body2" color="text.secondary">{item.downloads} downloads</Typography>
                </div>
              </CardContent>
              <CardContent>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={item.installed ? null : <GetAppIcon />}
                >
                  {item.installed ? 'Launch' : 'Install'}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
